import {classNames, setTableProfile} from "../../../../util/util-helpers";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import {TrashIcon} from "@heroicons/react/24/outline";
import Button from "../../../button";
import React, {useEffect, useRef, useState} from "react";
import FieldText from "../../../fields/field-text";
import {useLocation} from "react-router-dom";
import LocalStorage from "../../../../../util/localStorage";
import PlusIcon from "@heroicons/react/20/solid/PlusIcon";
import {LoaderSmall} from "../../../loader";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import Tooltip from "../../../tooltip";
import ModalConfirm from "../../../modal/modal-confirm";

export default function TableFilterProfiles({translate, isLoading, defaultStoragePath, onStoragePathChange}) {
    const inputRef = useRef();
    const location = useLocation();
    const pagePath = defaultStoragePath ?? location.pathname.slice(1);

    const getDefaultTabs = [
        {id: 0, name: 'Primary profile', current: true, enabled: true},
        {id: 1, name: 'Profile #1', current: false, enabled: false},
        {id: 2, name: 'Profile #2', current: false, enabled: false},
        {id: 3, name: 'Profile #3', current: false, enabled: false},
    ]

    const [tabs, setTabs] = useState(getTabs());
    const [label, setLabel] = useState(tabs.find(it => it.current).name);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [isEditLabelMode, setIsEditLabelMode] = useState(false);
    const [areActionsVisible, setAreActionsVisible] = useState(true);

    const hasDisabledTabs = tabs.findIndex(it => !it.enabled) !== -1


    function getTabs() {

        const tableProfile = LocalStorage.get(pagePath + "_current_table_profile");

        return tableProfile ? tableProfile : getDefaultTabs;
    }

    function handleTabChange(tab) {
        const tabsUpdate = tabs.map(it => {
            it.current = it.id === tab.id;

            return it;
        });

        setTabs(tabsUpdate);
        setLabel(tab.name);
        setIsEditLabelMode(false);
        setTableProfile(pagePath, tabsUpdate);
        onStoragePathChange(tab);
        setAreActionsVisible(false);
        setTimeout(() => {
            setAreActionsVisible(true);
        }, 1000);
    }

    function handleLabelChange() {
        const tabsUpdate = tabs.map(it => {
            if (it.current) {
                it.name = label;
            }

            return it;
        });
        setTabs(tabsUpdate);
        setIsEditLabelMode(false);
        LocalStorage.set(pagePath + "_current_table_profile", tabsUpdate);
    }

    function handleEnableTab() {
        const firstDisabledIndex = tabs.findIndex(it => !it.enabled);
        let selectedTab = tabs[0];

        const tabsUpdate = tabs.map((it, index) => {
            if (index === firstDisabledIndex) {
                it.enabled = true;
                selectedTab = it;
            }

            return it;
        });

        handleTabChange(selectedTab);
        setTabs(tabsUpdate);
        setIsEditLabelMode(true);
    }

    function handleEditLabelClick() {
        setIsEditLabelMode(true);
    }

    function handleDeleteClick(tab) {
        setIsConfirmModalOpen(true);
        setSelectedItem(tab);
    }

    function deleteTab() {
        let deleteID = 0;
        const tabsUpdate = tabs.map(it => {
            if (it.current) {
                it.enabled = false;
                deleteID = it.id
                it.name = `Profile #${it.id}`
            }

            return it;
        });

        tabsUpdate[0].current = true;
        setTabs(tabsUpdate);
        handleTabChange(tabs[0]);
        setIsEditLabelMode(false);
        LocalStorage.remove(pagePath + "_table_profile_" + deleteID + "_state");
        LocalStorage.remove(pagePath + "_table_profile_" + deleteID + "_column_filters");
        LocalStorage.remove(pagePath + "_table_profile_" + deleteID + "_query");
    }

    useEffect(() => {
        if (isEditLabelMode && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditLabelMode]);

    return (
        <div className="rounded-btn px-2">
            <nav aria-label="Tabs" className="isolate rounded-lg flex items-center gap-2">
                {tabs.filter(tab => tab.enabled).map((tab) => {
                    const DynamicTag = tab.current ? "div" : "button";
                    return (
                        <div key={tab.id} className="relative group flex items-center">
                            <DynamicTag
                                onClick={() => tab.current ? null : handleTabChange(tab)}
                                disabled={isLoading}
                                className={
                                    classNames(
                                        "py-1 min-w-[60px] min-h-[30px] flex items-center text-sm w-full text-left rounded-btn",
                                        tab.current ? "bg-inverse text-primary-contrast border-tm-gray-400" : "text-tm-gray-700 hover:bg-tm-gray-100 border-transparent",
                                        isEditLabelMode && tab.current
                                            ? "px-0"
                                            : "px-4 border"
                                    )
                                }
                            >
                                {
                                    isEditLabelMode && tab.current
                                        ? <FieldText
                                            innerRef={inputRef}
                                            value={label}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter') {
                                                    handleLabelChange();
                                                }
                                            }}
                                            onBlur={handleLabelChange}
                                            addClass="max-w-[127px] text-tm-gray-700 h-7 border border-tm-gray-300 placeholder:text-tm-gray-500 bg-field shadow-sm py-1 px-3 focus:outline-none focus:bg-field focus:ring-0 focus:border-primary sm:text-sm disabled:bg-tm-gray-50 disabled:text-tm-gray-500 focus:text-tm-gray-700 rounded-input"
                                            onChange={(_, value) => setLabel(value)}
                                            placeholder={"Label name"}
                                        />
                                        : <span className={tab.current ? "flex flex-nowrap text-tm-gray-900" : "flex flex-nowrap text-tm-gray-600"}>
                                            {tab.name}

                                            {!tab.id && (
                                                <Tooltip
                                                    content="You can create up to three additional profiles. The primary profile cannot be deleted."
                                                >
                                                <span className="ml-2 inline relative z-50 w-5 h-5">
                                                    <InformationCircleIcon
                                                        className={
                                                            classNames(
                                                                "w-5 h-5 text-primary"
                                                            )
                                                        }
                                                    />
                                                 </span>
                                                </Tooltip>
                                            )}
                                    </span>
                                }
                            </DynamicTag>

                            {(tab.current && (!areActionsVisible || isLoading)) && (
                                <LoaderSmall />
                            )}

                            {tab.current && !isEditLabelMode && !isLoading && areActionsVisible && (
                                <div
                                    className="hidden group-hover:flex absolute justify-center py-px right-0 left-0 px-0.5 h-full">
                                    <button
                                        onClick={handleEditLabelClick}
                                        className="p-1 rounded-md text-tm-gray-900 w-full flex items-center justify-center bg-inverse hover:bg-tm-gray-100"
                                    >
                                        <PencilIcon className="w-5 h-5"/>
                                    </button>

                                    {!!tab.id && (
                                        <button
                                            onClick={() => handleDeleteClick(tab)}
                                            className="p-1 text-tm-gray-900  w-full flex items-center justify-center rounded-btn bg-inverse hover:bg-tm-gray-100">
                                            <TrashIcon className="w-5 h-5"/>
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    )
                })}

                {hasDisabledTabs && (
                    <div className="justify-end items-center flex pl-4">
                        <Button
                            onClick={handleEnableTab}
                            className="btn bg-inverse rounded-btn border border-tm-gray-400 p-0 items-center justify-center h-[30px] w-[30px]"
                        >
                            <PlusIcon className="w-5 h-5"/>
                        </Button>
                    </div>
                )}
            </nav>

            <ModalConfirm
                title={'Confirm'}
                show={isConfirmModalOpen}
                width={"2xl"}
                text={translate('text.delete_table_profile_confirm', [selectedItem.name])}
                onClose={() => setIsConfirmModalOpen(false)}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={() => {
                    deleteTab();
                    setIsConfirmModalOpen(false);
                }}
            />
        </div>
    )
}