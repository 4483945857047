import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getUserNotification,
    mileageToErrorMessage,
    minutesToDayHourMinutes,
    openInNewTab,
    returnMileage,
    saveTableColumns,
    updateQueryFields
} from "../../../common/util/util-helpers";
import Resources from "../../../data/services/resources";
import {
    CREATE_PERM,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    LOAD_STATUS_DELIVERED,
    LOAD_STATUS_DISPATCHED,
    LOAD_STATUS_IN_TRANSIT,
    LOAD_STATUS_RESERVED,
    READ_PERM,
    SENSITIVE_LOAD_LIST_FIELDS,
    TABLE_OPTIONS_SUF,
    UPDATE_PERM
} from "../../../util/util-constants";
import {ArchiveBoxIcon, CheckCircleIcon, DocumentIcon, Square2StackIcon, XMarkIcon} from "@heroicons/react/24/outline";

import {BellIcon as BellSolidIcon, DocumentArrowDownIcon, FunnelIcon} from "@heroicons/react/20/solid";

import ResourceTable from "../../../common/components/resource-table";
import React, {useEffect, useRef, useState} from "react";
import LocalStorage from "../../../util/localStorage";
import {Field, FieldsManager} from "../../../data/services/fields";
import CellLink from "../../../common/components/resource-table/table-components/cell-link";
import Tippy from "@tippyjs/react";
import StarSolidIcon from "@heroicons/react/20/solid/StarIcon";
import ArrowsRightLeftIcon from "@heroicons/react/20/solid/ArrowsRightLeftIcon";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import StatusProgressTable from "../../../common/components/resource-table/table-components/status-progress-table";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import {showGlobalModal, showModal} from "../../../data/actions/ui";
import {genericMoneyFormatter} from "../../../common/util/util-vanilla";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import {excludeFields, fillFieldsFromData} from "../../../common/util/util-fields";
import {MapPinIconPulse, NeedsAttentionSolidIcon, RssIconPulse, ShareIconPulse} from "./index";
import {useDispatch} from "react-redux";
import moment from "moment/moment";
import Tooltip from "../../../common/components/tooltip";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import useQuery from "../../../hooks/use-query";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import {download} from "../../../data/actions/download";
import {currentDate, toFrontDate} from "../../../common/util/util-dates";
import SlideOver from "../../../common/components/slideover";
import TableColumnFilters from "../../../common/components/resource-table/table-components/table-column-filters";
import LoadsFileUpload from "./loads-file-upload";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import PopOver from "../../../common/components/popover";
import CalendarIcon from "@heroicons/react/20/solid/CalendarIcon";
import PayDateFilter from "../../accounting/carrier-pay/carrier-pay-date-filter";
import PostLocationUpdate from "../../../common/components/modal/post-location-update";
import TableFilterProfiles from "../../../common/components/resource-table/table-components/table-filter-profiles";

const CheckboxCell = ({value}) => {
    return value ? <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/>
        : <XMarkIcon className="w-5 h-5 text-tm-gray-400 inline"/>
}

const COLUMN_FILTER_FLAG_FIELD = 'ColumnFilters';
const AUTO_REFRESH_TIME = 60000;

export default function LoadsResourceTable({
                                               tableID,
                                               translate,
                                               onGetDiffResource,
                                               onCreateResource,
                                               onUpdateResource,
                                               onDeleteResource,
                                               breakpoint,
                                               isLoading,
                                               isLoadingSilent,
                                               data,
                                               count,
                                               diffData,
                                               defaultPagePath,
                                               filtersClass,
                                               tableDefaults,
                                               tableFiltersCustomContent,
                                               isTableFullScreen,
                                               setIsTableFullScreen,
                                               history
                                           }) {
    const storageProfiles = LocalStorage.get(defaultPagePath + "_current_table_profile", undefined);
    let initialStoragePath = defaultPagePath;

    if (storageProfiles) {
        const currentProfileID = storageProfiles.find(it => it.current).id;
        initialStoragePath = currentProfileID
            ? defaultPagePath + "_table_profile_" + currentProfileID
            : defaultPagePath
    }

    const inputFileRef = useRef();
    const initialLoadRef = useRef(true);
    const reminderIntervalDiff = useRef({});

    const dispatch = useDispatch();

    const canPostStopUpdates = checkPerm(Resources.LoadInfo, UPDATE_PERM);
    const notificationSoundID = getUserNotification();

    const [currentStoragePath, setCurrentStoragePath] = useState(initialStoragePath);

    const [tableOptions, setTableOptions] = useState(getDefaultTableOptions(getFields(), tableDefaults, initialStoragePath, translate));

    const [fields, setFields] = useState(getFields(LocalStorage.get(initialStoragePath + "_column_filters", {})));

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isFilterSlideOverOpen, setIsFilterSlideOverOpen] = useState(false);
    const [queryFields, baseSetQueryFields] = useQuery(getQueryFields(), defaultPagePath);
    const [isColumnsDialogVisible, setIsColumnsDialogVisible] = useState(false);
    const [confirmText, setConfirmText] = useState("");
    const [selectedItem, setSelectedItem] = useState({});
    const [isRestore, setIsRestore] = useState(false);
    const [fileToUpload, setFileToUpload] = useState();
    const [isUpdateStopModalOpen, setIsUpdateStopModalOpen] = useState(false);
    const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(() => LocalStorage.get(defaultPagePath + "_auto_refresh"));
    const query = FieldsManager.getFieldKeyValues(queryFields);

    if (query?.customersLabel) {
        delete query?.customersLabel;
    }

    if (query?.LoadStatusIDLabel) {
        delete query?.LoadStatusIDLabel;
    }

    function setQueryFields(query, updatedKey) {
        if (Array.isArray(query.customers.value)) {
            query.customersLabel.value = query.customers.value.map(it => {
                return {label: it.label, value: it.label}
            });
        }

        if (Array.isArray(query.LoadStatusID.value)) {
            query.LoadStatusIDLabel.value = query.LoadStatusID.value.map(it => {
                return {label: it.label, value: it.label}
            });
        }

        baseSetQueryFields(query, updatedKey);
    }

    function autoFetchData() {
        query.searchFields = getSearchFields();
        delete query.isAutoRefreshEnabled;

        dispatch(onGetDiffResource({
            user: LocalStorage.get('user'),
            resource: Resources.Loads,
            data: {
                deltaKey: "LoadID",
                compareKeys: Object.values(tableOptions?.columns).filter(it => it.show).map(it => it.name)
            },
            query: query
        }))
    }

    function fetchData() {
        query.searchFields = getSearchFields();
        delete query.isAutoRefreshEnabled;

        dispatch(onGetDiffResource({
            user: LocalStorage.get('user'),
            resource: Resources.Loads,
            data: {isInitial: true},
            query: query
        }))
    }

    function downloadExcel() {
        query.searchFields = getSearchFields();

        dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.Loads,
            query: Object.assign(
                {format: 'EXCEL', name: 'Loads_' + currentDate() + '.xlsx'},
                query
            )
        }))
    }

    function handleQueryFieldsChange(name, value) {
        setQueryFields(updateQueryFields(queryFields, name, value), currentStoragePath)
    }

    function handleClearFiltersClick() {
        setQueryFields(getQueryFields(), currentStoragePath);
        handleColumnFiltersClear();
    }

    function handleFilterInputChange(name, value) {
        const queryFieldsUpdate = FieldsManager.updateField(queryFields, name, value);

        queryFieldsUpdate.StartDate.props.maxDate = queryFieldsUpdate.EndDate.value;
        queryFieldsUpdate.EndDate.props.minDate = queryFieldsUpdate.StartDate.value;

        setQueryFields(queryFieldsUpdate, currentStoragePath);

        if ("ColumnFilters" === name && !value) {
            handleColumnFiltersClear();
        }
    }

    function handleColumnFiltersClear() {
        let fieldsUpdate = Object.assign({}, fields);

        fieldsUpdate = Object.values(fieldsUpdate).map(it => {
            it.value = "";
            return it;
        });

        fieldsUpdate = fieldsUpdate.reduce((memo, it) => {
            memo[it.name] = it;
            return memo
        }, [])

        setFields(fieldsUpdate);
        persistColumnFilters(fieldsUpdate);
    }

    function handleFilterChange(name, value) {
        const fieldsUpdate = FieldsManager.updateField(fields, name, value);

        const queryFieldsUpdate = FieldsManager.updateField(queryFields, COLUMN_FILTER_FLAG_FIELD, true);

        setFields(fieldsUpdate);
        setQueryFields(queryFieldsUpdate, currentStoragePath);

        persistColumnFilters(fieldsUpdate);
    }

    function persistColumnFilters(fields) {
        let filters = LocalStorage.get(defaultPagePath + "_column_filters", {});
        let fieldValues = Object.values(fields).reduce((memo, it) => {
            if (it.type === "select-search") {
                memo[it.name + "ID"] = it?.value?.value;
                memo[it.name] = it?.value?.label
            } else {
                memo[it.name] = it.value;
            }
            return memo
        }, {});

        LocalStorage.set(currentStoragePath + "_column_filters", Object.assign(filters, fieldValues));
    }

    async function watchReminderIntervalForChange(id, status) {
        if (reminderIntervalDiff.current[id] && reminderIntervalDiff.current[id] !== status && status !== 'green') {
            const audio = new Audio(`/audio/${notificationSoundID}.mp3`);

            if (audio.paused || audio.ended) {
                const playPromise = audio.play();

                if (playPromise !== undefined) {
                    playPromise.catch((error) => {
                        console.error('Error while trying to play media:', error);
                    });
                }
            }

            reminderIntervalDiff.current[id] = status;
        }
    }

    function handleStoragePathChange(selectedOption) {
        const newStoragePath = !selectedOption.id
            ? defaultPagePath
            : defaultPagePath + "_table_profile_" + selectedOption.id;

        setCurrentStoragePath(newStoragePath);
    }

    function setTableColumnOptions(columns) {
        let tableOptionsUpdate = Object.assign({}, tableOptions);
        let hasSubColumns = false;

        tableOptionsUpdate.columns = columns.reduce((memo, it) => {
            if (it?.subColumns?.length) {
                hasSubColumns = true;
            }
            memo[it.name] = it
            return memo
        }, {});

        if (hasSubColumns) {
            delete tableOptionsUpdate.style.condensed;
        } else {
            tableOptionsUpdate.style.condensed = !!tableOptionsUpdate?.style?.condensed;
        }

        setTableOptions(tableOptionsUpdate);
        saveTableColumns(currentStoragePath, tableOptionsUpdate);
        setIsColumnsDialogVisible(false);
    }

    function handleQueryDateFields(fieldsUpdate) {
        fieldsUpdate.offset.value = 0;
        setQueryFields(Object.assign({}, fieldsUpdate), currentStoragePath);
    }

    function getDateFilterLabel() {
        let defaultText = (!queryFields.StartDate.value || !queryFields.EndDate.value) && translate('text.select_date_range')
        let pickupOrDelivery = ''
        if (queryFields.DateType.value !== '') {
            pickupOrDelivery = ' - ' + (queryFields.DateType.value == 1 ? 'Pickup' : 'Delivery')
        }

        let from = '*'
        let to = '*'

        if (queryFields.StartDate.value) {
            from = toFrontDate(queryFields.StartDate.value);

        }

        if (queryFields.EndDate.value) {
            to = toFrontDate(queryFields.EndDate.value)
        }

        return defaultText ? defaultText : (from + ' - ' + to + pickupOrDelivery)
    }

    function toggleStopUpdateDialog(item) {
        setIsUpdateStopModalOpen(true);
        setSelectedItem(item);
    }

    function getReminderMessage(item) {
        let intervalDiff = moment.utc().diff(moment.utc(item?.LastStopUpdateDateTime), 'minutes')

        let className;
        let message = translate("text.stop_interval_in_minutes", [item.StopUpdateReminderInternal]);
        let content;
        const roundedIntervalDiff = Math.abs(item.StopUpdateReminderInternal - Math.round(intervalDiff));
        const conversion = minutesToDayHourMinutes(Math.abs(roundedIntervalDiff))
        let status = '';

        if (intervalDiff <= item.StopUpdateReminderInternal * 0.8) {
            content = translate('text.lastStopReminderNote', [roundedIntervalDiff]);

            if (conversion.days) {
                content = translate(`text.reminderToNoteDHM`, [conversion.days, conversion.hours, conversion.minutes])
            } else if (conversion.hours) {
                content = translate("text.reminderToNoteHM", [conversion.hours, conversion.minutes])
            }

            className = 'mr-1 w-5 h-5 flex-shrink-0 text-green-600'
            status = 'green'
        } else if (roundedIntervalDiff === 0) {
            content = translate('text.lastStopReminderNowNote');
            className = 'mr-1 w-5 h-5 flex-shrink-0 animate-blink-soft text-yellow-600'
            status = 'yellow'
        } else if (intervalDiff <= item.StopUpdateReminderInternal) {
            content = translate('text.lastStopReminderNote', [roundedIntervalDiff]);
            className = 'mr-1 w-5 h-5 flex-shrink-0 animate-blink-soft text-yellow-600'
            status = 'yellow'
        } else {
            content = translate('text.lastStopReminderDueNote', [roundedIntervalDiff]);

            if (conversion.days) {
                content = translate(`text.reminderDueNoteDHM`, [conversion.days, conversion.hours, conversion.minutes])
            } else if (conversion.hours) {
                content = translate("text.reminderDueNoteHM", [conversion.hours, conversion.minutes])
            }

            className = 'mr-1 w-5 h-5 flex-shrink-0 animate-blink-soft text-red-600'
            status = 'red'
        }

        if (notificationSoundID && item.IsStopUpdateReminderOn) {
            watchReminderIntervalForChange(item.LoadID, status).then();
        }

        if (!!item.LastStopUpdateDateTime && !!item.IsStopUpdateReminderOn && [LOAD_STATUS_DISPATCHED, LOAD_STATUS_RESERVED, LOAD_STATUS_IN_TRANSIT].includes(item.LoadStatusID)) {
            return (
                <CellButton
                    disabled={!canPostStopUpdates}
                    onClick={() => toggleStopUpdateDialog(item)}
                >
                    <Tooltip content={message}>
                        <BellSolidIcon className={className}/>
                    </Tooltip>

                    {content}
                </CellButton>
            )
        } else if (!!item.IsStopUpdateReminderOn && [LOAD_STATUS_DISPATCHED, LOAD_STATUS_RESERVED, LOAD_STATUS_IN_TRANSIT].includes(item.LoadStatusID)) {
            return (
                <CellButton
                    disabled={!canPostStopUpdates}
                    onClick={() => toggleStopUpdateDialog(item)}
                >
                    Reminder is set, first update should be posted
                </CellButton>
            )
        } else if (item.IsStopUpdateReminderOn && item.LoadStatusID !== LOAD_STATUS_DELIVERED) {
            return (
                <div className='flex relative z-0'>
                    When load is covered reminder timer will start
                </div>
            )
        } else {
            return (
                <div className='flex relative z-0'>
                    No reminder
                </div>
            )
        }
    }

    function toggleAutoRefresh() {
        setIsAutoRefreshEnabled(!isAutoRefreshEnabled);
        LocalStorage.set(defaultPagePath + "_auto_refresh", !isAutoRefreshEnabled);
    }

    function getFields(item = null) {
        if (item) {
            item = Object.keys(item).reduce((memo, key) => {
                memo[key] = item[key];

                if (memo[key] === false) {
                    memo[key] = "";
                }

                return memo;
            }, {});
        }

        const fieldTemplates = {
            LoadID: new Field('LoadID', '', [''], false, 'hidden', {hideTable: true}),
            LoadNumber: new Field('LoadNumber', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <CellLink
                            to={checkPerm(Resources.LoadInfo, UPDATE_PERM) && "/loads/info/" + item.LoadID}
                            disabled={!checkPerm(Resources.LoadInfo, UPDATE_PERM)}
                        >
                            {item.InternalNotes
                                ? (
                                    <Tippy content={<div className='whitespace-pre-line'>
                                        {item.InternalNotes}
                                    </div>}
                                    >
                                        <div key={item.LoadNumber} className="flex">
                                            {item.LoadNumber}
                                        </div>
                                    </Tippy>
                                )
                                :
                                item.LoadNumber
                            }

                            {(!!item.Favourite || !!item.Marked) && (
                                <div className="ml-auto flex pl-3">
                                    {!!item.Favourite && (
                                        <Tippy
                                            content={translate('btn.favourite_load')}
                                        >
                                            <StarSolidIcon
                                                className={classNames('h-5 w-5 cursor-pointer text-yellow-400')}
                                            />
                                        </Tippy>
                                    )}

                                    {!!item.Marked && (
                                        <Tippy
                                            content={translate('btn.load_needs_attention')}
                                        >
                                            <span><NeedsAttentionSolidIcon/></span>
                                        </Tippy>
                                    )}
                                </div>
                            )}

                            {!!item.IsTrackingEnabled && (
                                <div className="ml-auto flex pl-3">
                                    <Tippy
                                        content={translate('text.loadAutoTracked')}
                                    >
                                        <span><MapPinIconPulse/></span>
                                    </Tippy>
                                </div>
                            )}

                            {!!item.IsCustomerSyncEnabled && (
                                <div className="ml-auto flex pl-3">
                                    <Tippy
                                        content={translate('text.customerAutoSynced')}
                                    >
                                        <span><RssIconPulse/></span>
                                    </Tippy>
                                </div>
                            )}

                            {!!item.AllowShare && (
                                <div className="ml-auto flex pl-3">
                                    <Tippy
                                        content={translate('text.customerShareEnabled')}
                                    >
                                        <span><ShareIconPulse/></span>
                                    </Tippy>
                                </div>
                            )}

                            {(!!item.TrailerWasChanged || !!item.AssetWasChanged) && (
                                <div className="ml-auto flex pl-3">
                                    <Tippy
                                        content={
                                            !!item.TrailerWasChanged && !!item.AssetWasChanged
                                                ? translate('text.TrailerAndAssetWasChanged')
                                                : item.TrailerWasChanged
                                                    ? translate('text.TrailerWasChanged')
                                                    : translate('text.AssetWasChanged')
                                        }
                                    >
                                        <ArrowsRightLeftIcon className="text-green-600 w-5 h-5"/>
                                    </Tippy>
                                </div>
                            )}

                            {(!!item.TrailerWillChange || !!item.AssetWillChange) && (
                                <div className="ml-auto flex pl-3">
                                    <Tippy
                                        content={
                                            !!item.TrailerWillChange && !!item.AssetWillChange
                                                ? translate('text.TrailerAndAssetWillChange')
                                                : item.TrailerWillChange
                                                    ? translate('text.TrailerWillChange')
                                                    : translate('text.AssetWillChange')
                                        }
                                    >
                                        <ArrowsRightLeftIcon className="text-yellow-600 w-5 h-5"/>
                                    </Tippy>
                                </div>
                            )}
                        </CellLink>
                    )
                },
                colFilter: {
                    name: "LoadID"
                }
            }, {icon: false}),

            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
            }),
            IsBroker: new Field('IsBroker', '', [''], false, 'select', {
                render: (it) => it.IsBroker ?
                    <span
                        className={'w-5 h-5 font-bold text-indigo-500'}>{translate("text.brokerage")}</span> :
                    <span className={'w-5 h-5 font-bold text-green-600'}>{translate("text.carrier")}</span>
            }),
            LoadStatus: new Field('LoadStatus', '', [''], false, 'select', {
                render: (item) => {
                    const IsDropped = item.IsDropped;
                    const IsTailgate = item.ParentLoadID;
                    const IsTonu = item.IsTonu;
                    return (
                        <StatusProgressTable
                            addClass=""
                            htmlAfterStatus={
                                IsDropped ? translate("text.droppedTagAddition") :
                                    IsTailgate ? translate("text.tailgateTagAddition") :
                                        IsTonu ? translate("text.tonuTagAddition") :
                                            undefined
                            }
                            LoadStatusID={item.LoadStatusID}
                            translate={translate}
                        />
                    )
                },
                colFilter: {
                    name: "LoadStatusID",
                    type: "select",
                    select: getLookup("LoadStatus", "LoadStatusID", "LoadStatus")
                }
            }, {all: true, addClass: "min-w-[9rem] form-control", isMulti: true, hasPortal: true}),

            StopUpdateReminder: new Field('StopUpdateReminder', "", [''], false, 'custom', {
                disableColumnFilter: true,
                render: (item) => getReminderMessage(item)
            }),
            LastStopUpdate: new Field('LastStopUpdate', "", [''], false, 'custom', {
                disableColumnFilter: true,
                render: (item) => {
                    if (!item.LastStopUpdate) {
                        return null;
                    }
                    if (item.LastStopUpdateNotes) {
                        return (
                            <Tippy
                                content={<span>{item.LastStopUpdateNotes}</span>}
                            >
                                <span>
                                    {item.LastStopUpdate}
                                </span>
                            </Tippy>
                        )
                    }
                    return (
                        <span>
                            {item.LastStopUpdate}
                        </span>
                    )
                }
            }),
            LoadTypeID: new Field('LoadTypeID', "", [''], false, 'select', {}, {isClearable: true}),
            LoadSubTypeID: new Field('LoadSubTypeID', "", [''], false, 'select', {}, {isClearable: true}),

            Customer: new Field('Customer', "", [''], false, 'select-search', {
                colFilter: {
                    name: "CustomerID",
                    select: {
                        CustomerID: {
                            api: 'api/' + Resources.CustomersQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.LegalName || item.Organization,
                                value: item.CustomerID
                            })
                        }
                    }
                },
                render: (item) => {
                    if (!item.CustomerID) return null;
                    return (
                        <CellButton
                            onClick={() => {
                                dispatch(showModal('ViewCustomerCard', {
                                    CustomerID: item.CustomerID,
                                    OrganizationID: item.OrganizationID
                                }));
                            }}
                        >
                            {item.Customer}
                        </CellButton>
                    )
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            CustomerReferenceNumber: new Field('CustomerReferenceNumber', "", [''], false, 'text', {}),
            Driver: new Field('Driver', '', [''], false, 'select-search', {
                colFilter: {
                    name: "DriverID",
                    select: {
                        DriverID: {
                            api: 'api/' + Resources.DriversQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.DriverID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                },
                render: (item) => {
                    if (!item) return null;

                    if (item.DriverID) {
                        return (
                            <CellButton
                                onClick={() => {
                                    dispatch(showGlobalModal('ViewDriverCard', item.DriverID))
                                }}
                            >
                                {item.Driver}
                            </CellButton>
                        )
                    } else {
                        return (<span>{item.Driver}</span>)
                    }
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            CoDriver: new Field('CoDriver', '', [''], false, 'select-search', {
                colFilter: {
                    name: "CoDriver",
                    select: {
                        CoDriver: {
                            api: 'api/' + Resources.DriversQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.DriverID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                },
                render: (item) => {
                    if (!item) return null;

                    if (item.CoDriverID) {
                        return (
                            <CellButton
                                onClick={() => {
                                    dispatch(showGlobalModal('ViewDriverCard', item.CoDriverID))
                                }}
                            >
                                {item.CoDriver}
                            </CellButton>
                        )
                    } else {
                        return (<span>{item.CoDriver}</span>)
                    }
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            Truck: new Field('Truck', '', [''], false, 'text', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TruckID) {
                        return (
                            <CellButton
                                onClick={() => {
                                    dispatch(showGlobalModal('ViewTruckCard', item.TruckID));
                                }}
                            >
                                {item.Truck}
                            </CellButton>
                        )
                    } else {
                        return (<span>{item.Truck}</span>)
                    }
                }
            }, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            Trailer: new Field('Trailer', '', [''], false, 'text', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TrailerID) {
                        return (
                            <CellButton
                                onClick={() => {
                                    dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID));
                                }}
                            >
                                {item.Trailer}
                            </CellButton>
                        )
                    } else {
                        return item.Trailer;
                    }
                }
            }, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            SecondTrailer: new Field('SecondTrailer', '', ['']),
            TrailerType: new Field('TrailerType', '', [''], false, 'text', {}, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            BookedBy: new Field('BookedBy', '', [''], false, 'multi-select-search', {
                colFilter: {
                    name: "BookedByID",
                    select: {
                        BookedByID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                }
            }, {isClearable: true, multi: true}),
            CoveredBy: new Field('CoveredBy', '', [''], false, 'select-search', {
                colFilter: {
                    name: "CoveredByID",
                    select: {
                        CoveredByID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                }
            }, {isClearable: true}),
            Carrier: new Field('Carrier', '', [''], false, 'text', {}, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true,
                colFilter: true
            }),
            Price: new Field('Price', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.Price)}</div>,
                colFilter: true
            }, {icon: false}),
            AccessorialAmount: new Field('AccessorialAmount', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.AccessorialAmount)}</div>,
                colFilter: true
            }, {icon: false}),
            CalculationPrice: new Field('CalculationPrice', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.CalculationPrice)}</div>,
                colFilter: true
            }, {icon: false}),
            DriverPayAmount: new Field('DriverPayAmount', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.DriverPayAmount)}</div>,
                colFilter: true
            }, {icon: false}),
            AccessorialDriverPayAmount: new Field('AccessorialDriverPayAmount', '', [''], false, "float", {
                render: (it) => <div
                    className={"text-right"}>{genericMoneyFormatter(it.AccessorialDriverPayAmount)}</div>,
                colFilter: true
            }, {icon: false}),
            CarrierAmount: new Field('CarrierAmount', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.CarrierAmount)}</div>,
                colFilter: true
            }, {icon: false}),
            CommisionAmount: new Field('CommisionAmount', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.CommisionAmount)}</div>,
                colFilter: true
            }, {icon: false}),
            PickupDate: new Field('PickupDate', '', [''], false, 'datetime', {disableColumnFilter: true}),
            PickupEndDate: new Field('PickupEndDate', '', [''], false, 'datetime', {disableColumnFilter: true}),
            Pickup: new Field('Pickup', '', [''], false, "text", {colFilter: true}, {icon: false}),
            PickupCityName: new Field('PickupCityName', '', [''], false, "text", {colFilter: true}, {icon: false}),
            PickupState: new Field('PickupState', '', [''], false, "select", {colFilter: true}, {
                icon: false,
                isClearable: true
            }),
            PickupPostalCode: new Field('PickupPostalCode', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationDate: new Field('DestinationDate', '', [''], false, 'datetime', {disableColumnFilter: true}),
            DestinationEndDate: new Field('DestinationEndDate', '', [''], false, 'datetime', {disableColumnFilter: true}),
            Destination: new Field('Destination', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationCityName: new Field('DestinationCityName', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationState: new Field('DestinationState', '', [''], false, "select", {colFilter: true}, {
                icon: false,
                isClearable: true
            }),
            DestinationPostalCode: new Field('DestinationPostalCode', '', [''], false, "text", {colFilter: true}, {icon: false}),
            NumberOfStops: new Field('NumberOfStops', '', [''], false, 'integer', {hideDialog: true}),
            TotalMiles: new Field('TotalMiles', '', [''], false, "float", {
                colFilter: true,
                render: (item) => <div
                    className={"text-right"}>{mileageToErrorMessage(returnMileage(item.TotalMiles), translate)}</div>
            }, {icon: false}),
            EmptyMiles: new Field('EmptyMiles', '', [''], false, "float", {
                colFilter: true,
                render: (item) => <div className={"text-right"}>{returnMileage(item.EmptyMiles)}</div>
            }, {icon: false}),
            TotalWeight: new Field('TotalWeight', '', [''], false, "float", {
                render: (it) => {
                    return <div className="text-right">{it.TotalWeight}</div>
                }
            }),// TODO Add unit suffix, will be return by API in LBS
            TotalVolume: new Field('TotalVolume', '', [''], false, 'float'), // TODO Add unit suffix, will be return by API in inches
            TotalPieces: new Field('TotalPieces', '', [''], false, "float", {colFilter: true}, {icon: false}),
            LastDetectedAddress: new Field('LastDetectedAddress', '', [''], false, "text", {colFilter: true}, {icon: false}),
            LastDetectedAddressEtaTime: new Field('LastDetectedAddressEtaTime', '', [''], false, 'datetimezone', {disableColumnFilter: true}),
            IsTrackingEnabled: new Field('IsTrackingEnabled', '', [''], false, 'select', {
                colFilter: true,
                hideTable: true
            }),
            IsCustomerSyncEnabled: new Field('IsCustomerSyncEnabled', '', [''], false, 'select', {
                colFilter: true,
                showInTable: false,
                hideTable: true
            }),
            IsAutomaticCustomerShareEnabled: new Field('IsAutomaticCustomerShareEnabled', '', [''], false, 'select', {
                colFilter: true,
                showInTable: false,
                hideTable: true
            }),
            TrailerWasChanged: new Field('TrailerWasChanged', '', [''], false, 'select', {
                colFilter: true,
                showInTable: false,
                hideTable: true
            }),
            AssetWasChanged: new Field('AssetWasChanged', '', [''], false, 'select', {
                colFilter: true,
                showInTable: false,
                hideTable: true
            }),
            AssetWillChange: new Field('AssetWillChange', '', [''], false, 'select', {
                colFilter: true,
                showInTable: false,
                hideTable: true
            }),
            TrailerWillChange: new Field('TrailerWillChange', '', [''], false, 'select', {
                colFilter: true,
                showInTable: false,
                hideTable: true
            }),
            IsHazmat: new Field('IsHazmat', '', [''], false, 'select', {
                render: (item) => {
                    return (
                        item.IsHazmat ?
                            <div className="text-center"><ExclamationTriangleIcon className={'w-5 h-5 text-red-600'}/>
                            </div> : null
                    )
                },
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsTonu: new Field('IsTonu', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsTonu}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsInvoiced: new Field('IsInvoiced', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsInvoiced}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsPODReady: new Field('IsPODReady', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsPODReady}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsPODReviewed: new Field('IsPODReviewed', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsPODReviewed}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsSwitchDispatch: new Field('IsSwitchDispatch', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsSwitchDispatch}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsAccessorialHold: new Field('IsAccessorialHold', '', [''], false, 'select', {
                disableColumnFilter: true,
                render: (it) => <CheckboxCell value={it.IsAccessorialHold}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsDropped: new Field('IsDropped', '', [''], false, 'select', {
                showInTable: false, // Prop to hide on table only
                disableColumnFilter: true,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            LastCustomerUpdateDate: new Field('LastCustomerUpdateDate', '', [''], false, 'datetimezone', {disableColumnFilter: true}),
            OfficeName: new Field('OfficeName', '', [''], false, 'select-search', {
                colFilter: {
                    name: "OfficeID",
                    select: {
                        OfficeID: {
                            api: 'api/' + Resources.OfficesQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.OfficeName,
                                value: item.OfficeID
                            })
                        },
                    }
                }
            }, {isClearable: true}),
            ContactGroupName: new Field('ContactGroupName', '', [''], false, 'select-search', {
                colFilter: {
                    name: "ContactGroupID",
                    select: {
                        ContactGroupID: {
                            api: 'api/' + Resources.ContactGroupsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.ContactGroupName,
                                value: item.ContactGroupID
                            })
                        },
                    }
                }
            }, {isClearable: true}),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [''], false, 'datetimezone', {disableColumnFilter: true}),
            CreateDate: new Field('CreateDate', '', [''], false, 'datetimezone', {
                hideDialog: true,
                disableColumnFilter: true
            }),
            CreatedBy: new Field('CreatedBy', '', [''], false, 'select-search', {
                colFilter: {
                    name: "CreatedByID",
                    select: {
                        CreatedByID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                }
            }, {isClearable: true}),
        }

        let filledFields = fillFieldsFromData(fieldTemplates, item)

        if (item) {
            Object.values(filledFields).reduce((memo, field) => {
                if (field.type === 'select-search' && item[[field.name] + "ID"]) {
                    field.value = {label: item[field.name], value: item[[field.name] + "ID"]}
                }

                memo[field.name] = field;
                return memo;
            }, {})
        }

        filledFields = Object.values(filledFields).reduce((memo, it) => {
            if (!checkPerm(Resources.LoadsPricing, READ_PERM)
                &&
                SENSITIVE_LOAD_LIST_FIELDS.includes(it.name)
            ) {
                return memo;
            }
            if (it.type === 'checkbox') {
                it.value = it.value ? it.value.toString() : "";
            }
            memo[it.name] = it;
            return memo;
        }, {});

        return filledFields;
    }

    function getQueryFields(item = {}) {
        // customersLabel and LoadStatusIDLabel fields are workarounds, for now

        const fieldTemplates = {
            query: new Field('query', '', [''], false, 'search', {hideLabel: true}, {key: item?.query}),
            sort: new Field('sort', 'DESC', [''], false, 'hidden'),
            sortBy: new Field('sortBy', 'LoadNumber', [''], false, 'hidden'),
            secondSort: new Field('secondSort', '', [''], false, 'hidden'),
            secondSortBy: new Field('secondSortBy', '', [''], false, 'hidden'),

            offset: new Field('offset', '0', [''], false, 'hidden'),
            limit: new Field('limit', 50, [''], false, 'select', {
                hideLabel: true,
                labelType: 'float'
            }, {menuPlacement: "top", hasPortal: true}),
            LoadStatusID: new Field('LoadStatusID', '', [''], false, 'select', {}, {
                all: true,
                isMulti: true,
                hasPortal: true
            }),
            IsBroker: new Field('IsBroker', '', [''], false, 'select', {}, {
                all: true
            }),

            customers: new Field('customers', '', [''], false, 'multi-select-search', {
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            }, {
                isClearable: true,
                multi: true
            }),
            customersLabel: new Field('customersLabel', '', [''], false, 'hidden'),
            LoadStatusIDLabel: new Field('LoadStatusIDLabel', '', [''], false, 'hidden'),
            favorites: new Field('favorites', '', [''], false, 'checkbox'),
            HaveOpenClaims: new Field('HaveOpenClaims', '', [''], false, 'checkbox'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            Marked: new Field('Marked', '', [''], false, 'checkbox'),
            IsOverpaid: new Field('IsOverpaid', '', [''], false, 'checkbox'),
            IsTailgate: new Field('IsTailgate', '', [''], false, 'checkbox'),
            StartDate: new Field('StartDate', '', [], false, 'date', {}, {}),
            EndDate: new Field('EndDate', '', [], false, 'date', {}, {}),
            DateType: new Field('DateType', 1, [''], false, 'button-group', {
                    data: {1: 'Pickup', 2: 'Delivery'},
                    labelType: 'stack',
                    addClass: 'w-full',
                    addButtonClass: 'uppercase font-semibold',
                    addContainerClass: 'col-span-2',
                }
            ),
            ColumnFilters: new Field(COLUMN_FILTER_FLAG_FIELD, '', [''], false, 'hidden'),
            isAutoRefreshEnabled: new Field('isAutoRefreshEnabled', '', [], false, 'hidden', {}, {}),
        }

        const filledFields = fillFieldsFromData(fieldTemplates, item);

        if (Array.isArray(filledFields.customers.value)) {
            filledFields.customers.value = filledFields.customers.value.map((value, index) => {
                return {value: value, label: filledFields.customersLabel.value[index].label};
            });
        }

        if (Array.isArray(filledFields.LoadStatusID.value)) {
            filledFields.LoadStatusID.value = filledFields.LoadStatusID.value.map((value, index) => {
                return {value: value, label: filledFields.LoadStatusIDLabel.value[index].label};
            });
        }

        return filledFields;
    }

    function saveTableOptions(options) {
        LocalStorage.set(currentStoragePath + TABLE_OPTIONS_SUF, options);
        setTableOptions(options);
    }

    function getSearchFields() {
        const stateList = getLookup("State", "StateID", "State");

        return JSON.stringify(Object.values(fields).filter(it => it.value !== '' && it.value !== false).reduce((memo, it) => {

            let customValue = null;
            let name = it?.metadata?.colFilter?.name ?? it.name
            if (!!it?.value && it.value.length > 1 && (it.type === "float" || it.type === "money") && ["<", ">"].includes(it.value.charAt(0))) {
                customValue = [name, it.value.charAt(0), it.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')];
            }

            if (it.type === "select-search") {
                if (it?.props?.multi) {
                    memo[name] = it.value?.length ? it.value.map(it => it.value) : undefined;
                } else {
                    memo[name] = customValue ?? it.value?.value;
                }
            } else {
                memo[name] = customValue ?? it.value;
            }

            if (it.name === "PickupState" || it.name === "DestinationState") {
                memo[name] = stateList[it.value].substr(0, stateList[it.value].lastIndexOf(" "));
            }

            if (it.name === 'LoadStatus') {
                memo[name] = memo[name]?.reduce((statusMemo, status) => {
                    statusMemo.push(status.value);
                    return statusMemo;
                }, []);
            }

            if (it.name === 'BookedBy') {
                if (it?.value?.length) {
                    memo[name] = it.value.map(it => it.value);
                }
            }

            if (memo[name] === null) {
                memo[name] = undefined;
            }

            return memo;
        }, {}))
    }

    function handleCopyLoad(item) {
        openInNewTab('/loads/copy/' + item.LoadID)
    }

    function handleCreateUpdateResource(item = null) {
        if (item) {
            history.push(`/loads/info/${item.LoadID}`)
        } else {
            history.push(`/loads/create`)
        }
    }

    function handleShowConfirmDialog(item, restore = false) {
        setConfirmText(`${translate(!restore ? 'message.confirm_archive_generic' : 'message.confirm_restore_generic')} Load ${item.LoadNumber}?`);
        setIsConfirmModalOpen(true);
        setIsRestore(restore);
        setSelectedItem(item);
    }

    function archiveResource() {
        setIsConfirmModalOpen(false);
        dispatch(onDeleteResource({
            user: LocalStorage.get('user'),
            query: Object.assign({}, query, {LoadID: selectedItem.LoadID}),
            errorMessage: true, successMessage: `${selectedItem.LoadNumber} archived.`,
            resource: Resources.Load,
            piggyResource: Resources.Loads
        }))
    }

    function restoreFromArchive() {
        setIsConfirmModalOpen(false);
        dispatch(onUpdateResource({
            user: LocalStorage.get('user'),
            params: {
                LoadID: selectedItem.LoadID,
                ArchivedDate: 1
            },
            query: query,
            errorMessage: true, successMessage: `${selectedItem.LoadNumber} restored.`,
            resource: Resources.Load,
            piggyResource: Resources.Loads
        }))
    }

    useEffect(() => {
        sessionStorage.setItem('go_back_path', '/loads-split');
    }, []);

    useEffect(() => {
        if (!initialLoadRef.current) {
            fetchData();
        } else {
            initialLoadRef.current = false;
        }
    }, [queryFields]);

    useEffect(() => {
        let autoRefreshTimeout;

        if (isAutoRefreshEnabled) {
            autoRefreshTimeout = setInterval(autoFetchData, AUTO_REFRESH_TIME)
        }

        return () => {
            if (autoRefreshTimeout) {
                clearInterval(autoRefreshTimeout);
            }
        }
    }, [isAutoRefreshEnabled, queryFields]);

    useEffect(() => {
        if (!initialLoadRef.current) {
            const optionFields = getFields(LocalStorage.get(currentStoragePath + "_column_filters", {}));

            const hasTableOptions = !!LocalStorage.get(currentStoragePath + "_table_options", false);

            setTableOptions(getDefaultTableOptions(optionFields, tableDefaults, hasTableOptions ? currentStoragePath : "", translate));

            setFields(optionFields);

            const storagePathData = LocalStorage.get(currentStoragePath + "_query");

            setQueryFields(getQueryFields(storagePathData), null);
        }
    }, [currentStoragePath]);

    return (
        <>
            <div className={filtersClass ?? 'sm:flex'}>
                <ActiveFilters
                    filterFields={queryFields}
                    excludedFields={['limit', 'DateType', 'offset', 'sortBy', 'sort', 'secondSortBy', 'secondSort', 'isAutoRefreshEnabled', 'customersLabel', 'LoadStatusIDLabel']}
                    onLabelClick={handleFilterInputChange}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />

                <div className="ml-auto flex sm:justify-start justify-end items-center">
                    <Tippy content={translate('text.download_excel')}>
                        <button
                            className="btn-icon"
                            onClick={downloadExcel}
                        >
                            <DocumentArrowDownIcon className="w-5 h-5"/>
                        </button>
                    </Tippy>

                    <Tippy content={translate('text.column_filters')}>
                        <button
                            className="btn-icon"
                            onClick={() => setIsFilterSlideOverOpen(true)}
                        >
                            <FunnelIcon className="w-5 h-5"/>
                        </button>
                    </Tippy>

                    <TableSettingsPopOver
                        options={tableOptions}
                        setOptions={(options) => {
                            saveTableColumns(currentStoragePath, options);
                            setTableOptions(options)
                        }}
                        toggleColumnSettingsDialog={() => setIsColumnsDialogVisible(true)}
                        translate={translate}
                    />
                </div>
            </div>

            <TableCard
                addClass={
                    classNames(
                        isTableFullScreen
                            ? "fixed inset-1 -top-1 z-50"
                            : undefined
                    )}
            >
                <TableFilters
                    className="md:px-5 py-2 flex md:ring-1 ring-black ring-opacity-5 ring-b-none rounded-t-card md:border-b border-tm-gray-300"
                    hideLimit
                    forceDialog={true}
                    filterFields={excludeFields(queryFields, ['StartDate', 'EndDate', 'DateType', COLUMN_FILTER_FLAG_FIELD])}
                    isTableFullScreen={isTableFullScreen}
                    onFullScreenToggle={setIsTableFullScreen ? () => setIsTableFullScreen(!isTableFullScreen) : undefined}
                    handleInputChange={handleQueryFieldsChange}
                    translate={translate}
                    onRefreshTable={fetchData}
                    toggleAutoRefresh={toggleAutoRefresh}
                    isAutoRefreshEnabled={isAutoRefreshEnabled}
                    defaultStoragePath={defaultPagePath}
                    onStoragePathChange={handleStoragePathChange}
                    isLoading={isLoading || isLoadingSilent}
                    selects={{
                        limit: {
                            10: '10',
                            20: '20',
                            30: '30',
                            40: '40',
                            50: '50',
                            100: '100'
                        },
                        IsBroker: {0: 'Carrier', 1: 'Brokerage'},
                        customers: {
                            api: 'api/' + Resources.CustomersQuick,
                            query: queryFields.customers?.metadata?.query ?? DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.LegalName || item.Organization,
                                value: item.CustomerID
                            })
                        },
                        Tags: getLookup('TaskTagType')
                    }}
                    customHeaderHtml={(
                        <PopOver
                            className="relative"
                            btnClass="text-opacity-90 form-control p-0 w-auto text-tm-gray-700 flex items-center flex py-2 px-3"
                            BtnIcon={CalendarIcon}
                            chevronIcon={true}
                            btnLabel={getDateFilterLabel()}
                            btnIconClass={classNames("h-4 w-4 mr-1", queryFields?.StartDate?.value && queryFields?.EndDate?.value ? "hidden 2xl:block" : undefined)}
                            chevronIconClass={
                                classNames(
                                    "ml-2 h-4 w-4 text-tm-gray-700 group-hover:text-opacity-80 transition ease-in-out duration-150",
                                    queryFields?.StartDate?.value && queryFields?.EndDate?.value ? "hidden 2xl:block" : undefined
                                )}
                            widthClass="max-w-2xl"
                            positionClass="absolute translate-x-0 left-0"
                        >
                            <div
                                className="bg-popup border border-tm-gray-300 rounded-lg max-h-[calc(50vh-10rem)] overflow-y-auto">
                                <PayDateFilter
                                    queryFields={queryFields}
                                    translate={translate}
                                    updateQueryFields={handleQueryDateFields}
                                    onQueryChange={handleQueryFieldsChange}
                                    isDateTypeClearable={false}
                                />
                            </div>
                        </PopOver>
                    )}
                    customHtml={
                        <PayDateFilter
                            queryFields={queryFields}
                            translate={translate}
                            updateQueryFields={handleQueryDateFields}
                            onQueryChange={handleQueryFieldsChange}
                            isDateTypeClearable={false}
                        />
                    }
                >
                    {tableFiltersCustomContent}
                </TableFilters>

                <ResourceTable
                    tableID={tableID}
                    tableDataResourceKey={"loads"}
                    tableStoragePath={currentStoragePath}
                    tableKey={"LoadID"}
                    updatedRows={diffData}
                    fields={fields}

                    onRowClick={checkPerm(Resources.LoadInfo, UPDATE_PERM) ? handleCreateUpdateResource : null}
                    onEdit={handleCreateUpdateResource}
                    onRestore={(item) => handleShowConfirmDialog(item, true)}
                    handleColumnFilterInputChange={handleFilterChange}
                    actions={[
                        {
                            action: handleCopyLoad,
                            icon: Square2StackIcon, // make this a function
                            hasPerm: checkPerm(Resources.Load, CREATE_PERM),
                            label: false, // make this a function
                            title: translate("btn.duplicate_load"),
                            order: 22,
                            disabled: false,
                            class: false,
                            iconClass: false
                        },
                        {
                            action: (fileToUpload) => {
                                setFileToUpload(fileToUpload);
                                inputFileRef.current.click();
                            },
                            icon: DocumentIcon, // make this a function
                            hasPerm: checkPerm(Resources.LoadDocuments, CREATE_PERM),
                            label: false, // make this a function
                            title: translate("btn.upload_document"),
                            order: 21,
                            disabled: false,
                            class: false,
                            iconClass: false
                        },
                        {
                            action: (it) => {
                                (it.LoadStatusID < LOAD_STATUS_DISPATCHED) && handleShowConfirmDialog(it, false)
                            },
                            icon: ArchiveBoxIcon,
                            visible: (it) => checkPerm(Resources.Load, DELETE_PERM) && !it.ArchivedDate,
                            label: false,
                            order: 30,
                            disabled: false,
                            title: (it) => {
                                return (it.LoadStatusID < LOAD_STATUS_DISPATCHED) ? translate("btn.archive") : translate("Active asset can't be archived")
                            },
                            class: false,
                            iconClass: (it) => {
                                return (it.LoadStatusID < LOAD_STATUS_DISPATCHED) ? "w-5 h-5" : "w-5 h-5 opacity-30"
                            }

                        }
                    ]}

                    data={data}
                    isLoading={isLoading}
                    queryFields={queryFields}
                    onSecondSortChange={(colName) => handleQueryFieldsChange('secondSortBy', colName)}
                    options={tableOptions}
                    saveTableOptions={saveTableOptions}
                    onSortChange={(colName) => handleQueryFieldsChange('sortBy', colName)}
                    verticalTableIsVisible={breakpoint?.index <= 1}
                    filterSelects={getQuerySelects(translate)}
                    translate={translate}
                />

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    canCreate={checkPerm(Resources.Loads, CREATE_PERM)}
                    title={'No matching records found'}
                    text={'Create a new load'}
                    btnLabel="Create load"
                    onBtnClick={() => {
                        openInNewTab(`/loads/create?q=${queryFields.query.value}`);
                    }}
                    excludeFields={['DateType', 'query', 'limit']}
                    onClearFilterClick={handleClearFiltersClick}
                    clearFilterBtnLabel={translate('text.clear_all_filters')}
                    clearFilterText={translate('text.try_without_filters')}
                    filters={queryFields}
                />

                {/*Table footer*/}
                <div
                    className={
                        classNames(
                            "bg-inverse px-5 py-2 flex items-center justify-between md:h-16 rounded-card md:rounded-t-none mt-5 md:mt-0",
                            (data.length === 0) && !isLoading ? "border-t border-tm-gray-200" : undefined
                        )
                    }
                >
                    <Pagination
                        hideRowsPerPage={breakpoint?.index <= 1}
                        count={count}
                        isLoading={isLoading}
                        handleQueryChange={handleQueryFieldsChange}
                        queryFields={queryFields}
                        limitOptions={{
                            10: 10,
                            20: 20,
                            30: 30,
                            40: 40,
                            50: 50,
                            100: 100
                        }}
                        htmlAfterRowsPerPage={
                            <div className="ml-8">
                                <TableFilterProfiles
                                    defaultStoragePath={defaultPagePath}
                                    isLoading={isLoading}
                                    onStoragePathChange={handleStoragePathChange}
                                    translate={translate}
                                />
                            </div>
                        }
                        translate={translate}
                    />

                </div>
            </TableCard>

            <TableOptionsDialog
                enableMultiColumnsStack={false}
                show={isColumnsDialogVisible}
                pagePath={defaultPagePath}
                columns={tableOptions.columns}
                excludeFields={[
                    "IsTrackingEnabled",
                    "IsCustomerSyncEnabled",
                    "IsAutomaticCustomerShareEnabled",
                    "TrailerWasChanged",
                    "AssetWasChanged",
                    "AssetWillChange",
                    "TrailerWillChange"
                ]}
                setTableColumnOptions={setTableColumnOptions}
                getDefaultTableColumnOptions={() => getDefaultTableOptionsJSON(getFields(), tableDefaults, translate)}
                onClose={() => setIsColumnsDialogVisible(false)}
                translate={translate}
            />

            <SlideOver
                title={translate("dialog_heading.table_column_filters")}
                open={isFilterSlideOverOpen}
                setOpen={() => setIsFilterSlideOverOpen(false)}
                left={true}
                widthClass="max-w-[15rem]"
            >
                <TableColumnFilters
                    columnOptions={tableOptions.columns}
                    fields={excludeFields(fields, ['LoadID', 'CreateUpdateDate', 'PickupDate', 'DestinationDate', 'PickupEndDate', 'DestinationEndDate'])}
                    onFilterChange={handleFilterChange}
                    onClearFiltersClick={() => handleColumnFiltersClear("ColumnFilters")}
                    selects={getQuerySelects(translate)}
                    translate={translate}
                />
            </SlideOver>

            <ModalConfirm
                title={'Confirm'}
                show={isConfirmModalOpen}
                text={confirmText}
                onClose={() => setIsConfirmModalOpen(false)}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={
                    () => isRestore
                        ? restoreFromArchive()
                        : archiveResource()
                }
            />

            <LoadsFileUpload
                inputFileRef={inputFileRef}
                fileToUpload={fileToUpload}

                query={query}
                translate={translate}
            />

            <PostLocationUpdate
                show={isUpdateStopModalOpen}
                onClose={() => {
                    setSelectedItem({});
                    setIsUpdateStopModalOpen(false);
                }}
                onSubmit={(params) => {
                    dispatch(onCreateResource({
                        user: LocalStorage.get('user'),
                        params: params,
                        resource: Resources.LoadStopUpdate,
                        piggyResource: Resources.Loads,
                        query: query,
                        successMessage: translate("text.loads_stop_updated", [selectedItem.LoadID]),
                        errorMessage: true
                    }));

                    setSelectedItem({});
                    setIsUpdateStopModalOpen(false);
                }}
                onPostponeUpdateSubmit={(snoozeFields) => {
                    dispatch(onCreateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            Snooze: snoozeFields.Snooze.value,
                            LoadID: selectedItem.LoadID
                        },
                        resource: Resources.LoadStopUpdate,
                        query: query,
                        piggyResource: Resources.Loads,
                        successMessage: translate("text.loads_stop_snooze_updated", [selectedItem.LoadID, snoozeFields.Snooze.value]),
                        errorMessage: true
                    }))

                    setSelectedItem({});
                    setIsUpdateStopModalOpen(false);
                }}
                selectedItem={selectedItem}
                translate={translate}
            />
        </>
    )
}

function getQuerySelects(translate) {
    return {
        Customer: {
            api: 'api/' + Resources.CustomersQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.LegalName || item.Organization,
                value: item.CustomerID
            }),
        },
        Driver: {
            api: 'api/' + Resources.DriversQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.DriverID,
                label: item.FirstName + " " + item.LastName
            }),
        },
        CoDriver: {
            api: 'api/' + Resources.DriversQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.DriverID,
                label: item.FirstName + " " + item.LastName
            }),
        },
        Truck: {
            api: 'api/' + Resources.TrucksQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.TruckID,
                label: item.SCAC + ' - ' + item.TruckNumber
            })
        },
        Trailer: {
            api: 'api/' + Resources.TrailersQuick,
            query: {},
            searchMap: (item) => ({
                value: item.TrailerID,
                label: item.SCAC + ' - ' + item.TrailerNumber
            })
        },

        PickupState: getLookup("State", "StateID", "State"),
        DestinationState: getLookup("State", "StateID", "State"),
        LoadStatus: getLookup("LoadStatus", "LoadStatusID", "LoadStatus"),
        IsBroker: {"": "All", 0: "Carrier", 1: "Broker"},

        IsTrackingEnabled: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        IsCustomerSyncEnabled: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        IsAutomaticCustomerShareEnabled: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        TrailerWasChanged: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        AssetWasChanged: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        AssetWillChange: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        TrailerWillChange: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        IsHazmat: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        IsTonu: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        IsInvoiced: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        IsPODReady: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        IsPODReviewed: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        IsSwitchDispatch: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        IsAccessorialHold: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        IsDropped: {
            "": translate("text.all"),
            1: translate("text.yes"),
            0: translate("text.no")
        },
        BookedBy: {
            api: 'api/' + Resources.ContactsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.ContactID,
                label: item.FirstName + " " + item.LastName
            }),
        },
        CoveredBy: {
            api: 'api/' + Resources.ContactsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.ContactID,
                label: item.FirstName + " " + item.LastName
            }),
        },
        CreatedBy: {
            api: 'api/' + Resources.ContactsQuick,
            query: {},
            searchMap: (item) => ({
                value: item.ContactID,
                label: item.FirstName + " " + item.LastName
            })
        },
        OfficeName: {
            api: 'api/' + Resources.OfficesQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.OfficeName,
                value: item.OfficeID
            })
        },
        ContactGroupName: {
            api: 'api/' + Resources.ContactGroupsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.ContactGroupName,
                value: item.ContactGroupID
            })
        }
    }
}