import Resources from "../../../../../data/services/resources";
import GetDispatchManagerFields from "../../../dispatch-manager-view/dispatch-manager-fields";
import {Field} from "../../../../../data/services/fields";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY, DRIVER_STATUS_TYPES} from "../../../../../util/util-constants";
import ResourceTableDialogWithFilters from "../../../../../common/components/modal/resource-table-dialog-with-filters";
import React, {useRef} from "react";
import {groupListBySCAC} from "../../../../../common/util/util-helpers";
import {useDispatch, useSelector} from "react-redux";

export default function DispatchManagerDialog({show, onClose, defaultAction, onCustomActions, translate}) {
    const dispatchManagerInitialFocusRef = useRef();
    const dialogResource = useSelector((state) => state.dialogResource);
    const dispatch = useDispatch();

    return <ResourceTableDialogWithFilters
        show={show}
        widthClass={"max-w-[1366px]"}
        dialogResource={dialogResource}
        initialFocusRef={dispatchManagerInitialFocusRef}
        resource={Resources.Units}
        title={translate("text.select_from_dispatch_manager")}
        dispatch={dispatch}
        fields={GetDispatchManagerFields(translate, dispatch)}
        fieldsFilter={{
            query: new Field('query', '', [''], false, 'search', {
                addContainerClass: "col-span-2",
                hideLabel: true,
                labelType: "float"
            }, {autoFocus: true}),
            offset: new Field('offset', 0, [''], false, 'hidden'),
            limit: new Field("limit", 10, [''], false, 'select', {hideLabel: true, labelType: "float"}),
            sort: new Field('sort', "ASC", [''], false, 'hidden'),
            sortBy: new Field('sortBy', "UnitID", [''], false, 'hidden'),
            DriverID: new Field('DriverID', '', [], false, 'select-search', {addContainerClass: "col-span-3 col-start-1"}, {isClearable: true}),
            TruckID: new Field('TruckID', '', [], false, 'select-search', {addContainerClass: "col-span-3"}, {isClearable: true}),

            DispatchContactID: new Field('DispatchContactID', '', [], false, 'select-search', {addContainerClass: "col-span-3"}, {isClearable: true}),
            StatusID: new Field('StatusID', '', [], false, 'select', {addContainerClass: "col-span-3"}, {isClearable: true}),
            IsTeamDriver: new Field('IsTeamDriver', '', [], false, 'checkbox', {
                addContainerClass: "col-span-2",
                hideLabel: true,
                labelType: "float"
            }, {isClearable: true}),
            StatusMismatch: new Field('StatusMismatch', '', [], false, 'checkbox', {
                addContainerClass: "col-span-2",
                hideLabel: true,
                labelType: "float"
            }, {isClearable: true})
        }}
        defaultAction={defaultAction}
        onCustomActions={onCustomActions}
        onClose={onClose}
        selects={{
            DriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {NotOnLoadInAction: 1}),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.FirstName + ' ' + item.LastName
                })
            },
            CoDriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {NotOnLoadInAction: 1}),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.FirstName + ' ' + item.LastName
                })
            },
            TruckID: {
                api: 'api/' + Resources.TrucksQuick,
                query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {NotOnLoadInAction: 1}),
                customizeList: (list) => {
                    return groupListBySCAC(list, 'Truck')
                }
            },
            TrailerID: {
                api: 'api/' + Resources.TrailersQuick,
                query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {NotOnLoadInAction: 1}),
                customizeList: (list) => {
                    return groupListBySCAC(list, 'Trailer')
                }
            },
            DispatchContactID: {
                api: "api/" + Resources.ContactsQuick,
                query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {IsDispatch: 1}),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + " " + item.LastName,
                    Contact: item
                })
            },
            StatusID: DRIVER_STATUS_TYPES
        }}
        translate={translate}
    />
}