import {checkPerm} from "../../../../common/util/util-helpers";
import Resources from "../../../../data/services/resources";
import {
    DEFAULT_DATABASE_DATETIME_FORMAT,
    LOAD_STATUS_DISPATCHED,
    LOAD_STATUS_IN_TRANSIT,
    UPDATE_PERM
} from '../../../../util/util-constants'
import React, {useEffect, useState} from "react";
import {ArchiveBoxArrowDownIcon, ArrowDownTrayIcon, PlayCircleIcon} from "@heroicons/react/24/outline";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {useDispatch, useSelector} from "react-redux";
import LocalStorage from "../../../../util/localStorage";
import {getLoadSelects} from "../load-utils";
import {updateResource} from "../../../../data/actions/resource";
import {
    timePickerValueToServerTime,
    toBackDateTime,
    toFrontDateTime
} from '../../../../common/util/util-dates'
import moment from 'moment/moment'
import Tooltip from "../../../../common/components/tooltip";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import {Loader} from "../../../../common/components/loader";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import Cog6ToothIcon from "@heroicons/react/24/outline/Cog6ToothIcon";
import DispatchManagerDialog from "./dispatch-manager-dialog";

export default function DropLoadButton({
                                           isLoading,
                                           LoadID,
                                           IsDropped,
                                           DriverID,
                                           CoDriverID,
                                           TruckID,
                                           TrailerID,
                                           initialLoadStatusID,
                                           getQuery,
                                           isLoadDirty,
                                           stop,
                                           stopsCombined,
                                           translate
                                       }) {
    const resourceName = Resources.LoadDrop;

    const prevStop = stopsCombined[(stop?.metadata?.StopOrder ?? 0) - 2];
    const canBeCompleted = prevStop?.IsStopCompleted && !stop.IsStopCompleted && (!stopsCombined[stop?.metadata?.StopOrder]?.IsStopCompleted);

    let preStopTimeOut = getPrevStopTimeOut(prevStop);
    let droppedStopTime = stop?.metadata?.ActualDepartureDate;

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isConfirmContinueModalVisible, setIsConfirmContinueModalVisible] = useState(false);
    const [dropTrailerFields, setDropTrailerFields] = useState(getDropLoadFields());
    const [continueLoadFields, setContinueLoadFields] = useState(getContinueLoadFields());
    const [isDispatchManagerDialogVisible, setIsDispatchManagerDialogVisible] = useState(false);

    const isDropAfterPrevStopTime = getIsDropAfterPrevStopTime(preStopTimeOut, dropTrailerFields);
    const isContinuedAfterDropTime = getIsContinuedAfterDropTime(droppedStopTime, continueLoadFields);

    function handleDropLoadClick() {
        const validFields = FieldsManager.validateFields(dropTrailerFields);
        if (FieldsManager.checkFieldsForErrors(validFields)) {
            let params = {
                LoadID: LoadID,
                Dropped: "1",
                KeepTrailer: dropTrailerFields.KeepTrailer.value ? 1 : 0,
                DriverID: DriverID.value,
                CoDriverID: CoDriverID.value,
                TruckID: TruckID.value,
                TrailerID: TrailerID.value,
                LoadStopByID: stop?.metadata?.LoadStopByID
            }
            params.ActualArrivalDateTime = validFields.ActualArrivalDate.value.split(" ")[0] + " " + timePickerValueToServerTime(validFields.ActualArrivalDateTime.value);

            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: params,
                resource: resourceName,
                piggyResource: Resources.LoadInfo,
                query: getQuery(),
                errorMessage: true,
                successMessage: 'Load dropped successfully',
            }))
        } else {
            setDropTrailerFields(Object.assign({}, validFields));
        }
    }

    function handleContinueDroppedLoadClick() {
        const validFields = FieldsManager.validateFields(continueLoadFields);

        if (FieldsManager.checkFieldsForErrors(validFields)) {
            let params = {
                LoadID: LoadID,
                Dropped: "0",
                DriverID: continueLoadFields.DriverID.value?.value,
                CoDriverID: continueLoadFields.CoDriverID.value?.value,
                TruckID: continueLoadFields.TruckID.value?.value,
                TrailerID: continueLoadFields.TrailerID.value?.value,
                LoadStopByID: stop?.metadata?.LoadStopByID
            }
            params.ActualDepartureDateTime = validFields.ActualDepartureDate.value.split(" ")[0] + " " + timePickerValueToServerTime(validFields.ActualDepartureDateTime.value);

            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: params,
                resource: resourceName,
                piggyResource: Resources.LoadInfo,
                query: getQuery(),
                errorMessage: true,
                successMessage: 'Load continued successfully',
            }))
        } else {
            setContinueLoadFields(Object.assign({}, validFields));
        }
    }

    function handleInputChange(name, value) {
        const fieldsUpdate = FieldsManager.updateField(dropTrailerFields, name, value);
        fieldsUpdate[name].errorMessage = "";
        setDropTrailerFields(Object.assign({}, fieldsUpdate));
    }

    function handleContinueDroppedInputChange(name, value) {
        const continueLoadFieldsClone = continueLoadFields;
        continueLoadFieldsClone[name].errorMessage = "";
        setContinueLoadFields(Object.assign({}, FieldsManager.updateField(continueLoadFieldsClone, name, value)));
    }

    function getDropLoadFields() {
        return {
            ActualArrivalDate: new Field('ActualArrivalDate', '', ['empty'], false, 'datetime', {
                addContainerClass: 'col-start-1 w-[calc(100%+1rem+1px)] col-span-4',
                label: "time_in"
            }, {
                isClearable: true,
                addClass: "rounded-r-none",
                minDate: preStopTimeOut,
            }),
            ActualArrivalDateTime: new Field('ActualArrivalDateTime', '', [''], false, 'time-custom', {
                addContainerClass: "col-span-4",
                hideLabel: true
            }, {addClass: "rounded-l-none"}),

            KeepTrailer: new Field('KeepTrailer', "1", [""], false, 'checkbox', {
                note: translate("text.KeepTrailer"),
                addContainerClass: "col-span-full col-start-1"
            })
        }
    }

    function getContinueLoadFields() {
        return {
            ActualDepartureDate: new Field('ActualDepartureDate', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, "date", {
                addContainerClass: "relative text-tm-gray-900 col-start-1 w-[calc(100%+1rem+1px)] col-span-4",
                label: "time_out"
            }, {addClass: "rounded-r-none"}),
            ActualDepartureDateTime: new Field('ActualDepartureDateTime', toFrontDateTime(moment().format(DEFAULT_DATABASE_DATETIME_FORMAT)).split(" ").filter((it, index) => index).join(""), ['empty'], false, "time-custom", {
                addContainerClass: "col-span-4",
                hideLabel: true
            }, {addClass: "rounded-l-none"}),

            DriverID: new Field('DriverID', "", ["empty"], false, 'select-search', {
                addContainerClass: "col-span-6 col-start-1"
            }, {isClearable: true}),
            CoDriverID: new Field('CoDriverID', "", [""], false, 'select-search', {
                addContainerClass: "col-span-6"
            }, {isClearable: true}),
            TruckID: new Field('TruckID', "", ["empty"], false, 'select-search', {
                addContainerClass: "col-span-6"
            }, {isClearable: true}),
            TrailerID: new Field('TrailerID', "", [""], false, 'select-search', {
                addContainerClass: "col-span-6"
            }, {isClearable: true})
        }
    }

    function applySelectedLocationUnit(it) {
        const continueLoadFieldsUpdate = Object.assign({}, continueLoadFields);

        continueLoadFieldsUpdate.DriverID.value = it.DriverID ? {
            value: it.DriverID,
            label: it.Driver,
            metadata: {ContactID: it.DriverContactID}
        } : "";

        continueLoadFieldsUpdate.CoDriverID.value = it?.CoDriverID ? {
            value: it.CoDriverID,
            label: it.CoDriver,
            metadata: {ContactID: it?.CoDriverContactID}
        } : "";

        continueLoadFieldsUpdate.TrailerID.value = it.TrailerID ? {value: it.TrailerID, label: it.Trailer} : null;

        continueLoadFieldsUpdate.TruckID.value = it.TruckID ? {value: it.TruckID, label: it.Truck} : null;

        setContinueLoadFields(continueLoadFieldsUpdate);
    }

    useEffect(() => {
        if (resource.resource === resourceName && resource.update) {
            setIsConfirmModalVisible(false);
            setIsConfirmContinueModalVisible(false);
        }
    }, [resource]);

    return <>
        {
            !isLoading && !IsDropped && checkPerm(resourceName, UPDATE_PERM) && (
                <LoadButtonTooltip
                    isLoadDirty={isLoadDirty}
                    initialLoadStatusID={initialLoadStatusID}
                    stop={stop}
                    canBeCompleted={canBeCompleted}
                    translate={translate}
                >
                    <button
                        onClick={() => setIsConfirmModalVisible(true)}
                        className="btn btn-text text-red-500 hover:text-red-600 gap-2 text-primary hover:bg-tm-gray-50 pl-2 pr-4 bg-inverse border-tm-gray-300"
                        disabled={isLoadDirty || !(initialLoadStatusID === LOAD_STATUS_DISPATCHED || initialLoadStatusID === LOAD_STATUS_IN_TRANSIT) || !canBeCompleted}
                    >
                        <ArchiveBoxArrowDownIcon className="w-5 h-5 text-tm-gray-400"/>
                        Drop load
                    </button>

                </LoadButtonTooltip>
            )
        }


        {
            !isLoading
            && !!IsDropped
            && checkPerm(resourceName, UPDATE_PERM)
            && (initialLoadStatusID === LOAD_STATUS_DISPATCHED || initialLoadStatusID === LOAD_STATUS_IN_TRANSIT)
            && (
                <Tooltip disabled={!isLoadDirty} content={translate("text.save_changes_first")}>
                    <div
                        className="w-full">
                        <button
                            className="btn btn-text hover:text-primary-tint gap-2 text-primary hover:bg-tm-gray-50 pl-2 pr-4 bg-inverse border-tm-gray-300"
                            disabled={isLoading || isLoadDirty}
                            onClick={() => {
                                setIsConfirmContinueModalVisible(true);
                            }}
                        >
                            <PlayCircleIcon className="w-5 h-5 mr-1 text-tm-gray-400"/>
                            {translate('btn.continue_dropped_load')}
                        </button>
                    </div>
                </Tooltip>
            )
        }

        <ModalConfirm
            show={isConfirmModalVisible}
            type={"warning"}
            onClose={() => setIsConfirmModalVisible(false)}
            onConfirm={handleDropLoadClick}
            buttonLabel={translate("text.confirm")}
            closeButtonLabel={translate("text.cancel")}
            title={translate('modal_heading.confirm_drop_load')}
            text={translate('text.drop_load_confirm_msg')}
            buttonDisabled={isLoading || isDropAfterPrevStopTime === false}
            translate={translate}
        >
            <div className="pt-5 grid grid-cols-12 gap-4 relative">
                {isLoading && (
                    <Loader stripesBg={true}/>
                )}

                {isDropAfterPrevStopTime === false && (
                    <div className="col-span-full">
                        <InfoBar type="danger">
                            <div>
                                <p>Time in should be after the completion time of the previous stop.</p>
                                <p>({toFrontDateTime(preStopTimeOut)})</p>
                            </div>

                        </InfoBar>
                    </div>
                )}

                <FieldsToHtml
                    onInputChange={handleInputChange}
                    fieldsState={dropTrailerFields}
                    translate={translate}
                />
            </div>
        </ModalConfirm>

        <ModalConfirm
            show={isConfirmContinueModalVisible}
            type={"warning"}
            onClose={() => setIsConfirmContinueModalVisible(false)}
            onConfirm={handleContinueDroppedLoadClick}
            buttonLabel={translate("text.confirm")}
            buttonDisabled={isLoading || isContinuedAfterDropTime === false}
            closeButtonLabel={translate("text.cancel")}
            title={translate('modal_heading.confirm_continue_dropped_load')}
            text={translate('text.confirm_continue_dropped_load')}
            translate={translate}
        >
            <div className="pt-5 grid grid-cols-12 gap-4 relative">
                {isContinuedAfterDropTime === false && (
                    <div className="col-span-full">
                        <InfoBar type="danger">
                            <div>
                                <p>Continue time in should be after the load drop time.</p>
                                <p>({toFrontDateTime(droppedStopTime)})</p>
                            </div>

                        </InfoBar>
                    </div>
                )}

                <FieldsToHtml
                    onInputChange={handleContinueDroppedInputChange}
                    fieldsState={continueLoadFields}
                    translate={translate}
                    selects={getLoadSelects()}
                />

                <div className="col-span-full border-t border-tm-gray-200 py-2 mt-2">
                    <button
                        className="btn flex gap-2 text-xs py-1 text-tm-gray-700 hover:text-primary"
                        onClick={() => setIsDispatchManagerDialogVisible(true)}
                    >
                        <Cog6ToothIcon className="w-5 h-5"/>
                        {translate("text.select_from_dispatch_manager")}
                    </button>
                </div>

                {isLoading && (
                    <Loader stripesBg={true}/>
                )}
            </div>

            <DispatchManagerDialog
                show={isDispatchManagerDialogVisible}
                defaultAction={(it) => {
                    applySelectedLocationUnit(it);
                    setIsDispatchManagerDialogVisible(false);
                }}
                onCustomActions={[
                    {
                        tooltipText: () => translate('btn.select_unit'),
                        action: (it) => {
                            applySelectedLocationUnit(it);
                            setIsDispatchManagerDialogVisible(false);
                        },
                        icon: ArrowDownTrayIcon
                    }
                ]}
                onClose={() => setIsDispatchManagerDialogVisible(false)}
                translate={translate}
            />
        </ModalConfirm>
    </>
}

function getPrevStopTimeOut(prevStop) {
    if (!prevStop?.metadata) {
        return undefined;
    }

    return prevStop.metadata?.ActualDepartureDateTime
}

function getIsDropAfterPrevStopTime(prevStopTimeOut, dropTrailerFields) {
    if (!prevStopTimeOut || !dropTrailerFields?.ActualArrivalDate?.value) {
        return undefined;
    }

    const dropTime = dropTrailerFields.ActualArrivalDate.value.split(" ")[0] + " " + timePickerValueToServerTime(dropTrailerFields?.ActualArrivalDateTime?.value);

    const momentDropTime = moment(dropTime, "YYYY-MM-DD HH:mm:ss");
    const momentPrevStop = moment(prevStopTimeOut, "YYYY-MM-DD HH:mm:ss");
    return momentDropTime.isAfter(momentPrevStop);
}

function getIsContinuedAfterDropTime(droppedStopTime, continueLoadFields) {
    if (!droppedStopTime || !continueLoadFields?.ActualDepartureDate?.value) {
        return undefined;
    }

    const continueTime = continueLoadFields.ActualDepartureDate.value.split(" ")[0] + " " + timePickerValueToServerTime(continueLoadFields?.ActualDepartureDateTime?.value);

    const momentContinueTime = moment(toBackDateTime(continueTime), "YYYY-MM-DD HH:mm:ss");
    const momentDroppedTime = moment(toBackDateTime(droppedStopTime), "YYYY-MM-DD HH:mm:ss");

    return momentContinueTime.isAfter(momentDroppedTime);
}

function LoadButtonTooltip({children, stop, isLoadDirty, initialLoadStatusID, canBeCompleted, translate}) {
    let message = ""

    if (stop.IsStopCompleted) {
        message = "This stop has already been completed, and you cannot drop a load here."
    } else if (!(initialLoadStatusID === LOAD_STATUS_DISPATCHED || initialLoadStatusID === LOAD_STATUS_IN_TRANSIT)) {
        message = "Load must be dispatched to drop load"
    } else if (isLoadDirty) {
        message = translate("text.save_changes_first")
    } else if (!canBeCompleted) {
        message = "Previous stop must be completed"
    }

    return <Tooltip
        disabled={!isLoadDirty && (initialLoadStatusID === LOAD_STATUS_DISPATCHED || initialLoadStatusID === LOAD_STATUS_IN_TRANSIT) && canBeCompleted}
        content={message}
    >
        <div>
            {children}
        </div>
    </Tooltip>
}