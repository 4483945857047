import {Field, FieldsManager} from "../../../../data/services/fields";
import moment from "moment/moment";
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../../util/util-constants";
import {getUserTimeFormat} from "../../../util/util-dates";
import {fillFieldsFromData} from "../../../util/util-fields";
import {convertReminderFields, getLookup, minutesToDayHourMinutes} from "../../../util/util-helpers";
import React, {useState} from "react";
import MapPinIcon from "@heroicons/react/24/outline/MapPinIcon";
import PopOver from "../../popover";
import {BellSlashIcon} from "@heroicons/react/24/outline";
import FieldsToHtml from "../../fields/fields-to-html";
import LoadsViewStopUpdate from "../../../../views/dispatch/loads-view/loads-view-stop-update";
import EventsTab from "../../../../views/dispatch/load-view/load-sidebar/events-tab";
import ModalSaveResource from "../modal-save-resource";

export default function PostLocationUpdate({selectedItem, show, translate, onSubmit, onPostponeUpdateSubmit, onClose}) {
    const [snoozeFields, setSnoozeFields] = useState(() => getSnoozeFields());
    const [isPingUpdateModalOpen, setIsPingUpdateModalOpen] = useState(false);
    const [postUpdatePingLocation, setPostUpdatePingLocation] = useState({});

    function handlePostponeClick(e) {
        e.preventDefault();

        if (snoozeFields.Snooze.value < 1) {
            let snoozeFieldsClone = Object.assign({}, snoozeFields);

            snoozeFieldsClone.Snooze.errorMessage = [translate("text.snooze_error")];
            setSnoozeFields(snoozeFieldsClone);
        } else {
            onPostponeUpdateSubmit(snoozeFields);
        }
    }

    function handleStopUpdateFieldsChange(fields, name, value) {
        const fieldsClone = FieldsManager.updateField(fields, name, value);

        if ('IsStopUpdateReminderOn' === name) {
            fieldsClone.ReminderIntervalDays.disabled = !value;
            fieldsClone.ReminderIntervalHours.disabled = !value;
            fieldsClone.ReminderIntervalMinutes.disabled = !value;
        }

        return fieldsClone;
    }

    function handleSnoozeInputChange(name, value) {
        const fieldsUpdate = FieldsManager.updateField(snoozeFields, name, value);
        fieldsUpdate[name].errorMessage = "";
        setSnoozeFields(fieldsUpdate);
    }

    function handlePingUpdateClick() {
        setIsPingUpdateModalOpen(true);
        setPostUpdatePingLocation({});
    }

    function getStopUpdateFields(item = {}) {
        const fieldTemplates = {
            LoadID: new Field('LoadID', item, [''], false, 'hidden'),
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date-new', {addContainerClass: 'col-span-3', isUTC: false, withTimeField: "DateTime"}),
            DateTime: new Field('DateTime', moment().format(getUserTimeFormat()), ['empty'], false, 'time-custom-new', {addContainerClass: 'col-span-3', isUTC: false,  withDateField: "Date"}),
            LoadStopStatusID: new Field('LoadStopStatusID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            IsCustomerVisible: new Field('IsCustomerVisible', "", [''], false, 'checkbox',
                {
                    addContainerClass: 'col-span-full',
                    labelType: "float",
                    note: translate("field.visible_on_customer_shared_load")
                }
            ),
            Notes: new Field('Notes', '', [''], false, 'textarea',
                {
                    addContainerClass: 'col-span-full',
                    htmlAfter: () => {
                        return <div className="col-span-full text-lg mt-4 leading-6 font-medium text-tm-gray-900">
                            Stop update reminder
                        </div>
                    }
                }
            ),
            LoadStopID: new Field('LoadStopID', "0", [''], false, 'hidden', 'hidden'),
            IsStopUpdateReminderOn: new Field('IsStopUpdateReminderOn', item?.IsStopUpdateReminderOn ? "1" : "0", [''], false, 'switch', {
                addContainerClass: "px-4 sm:col-span-8 col-span-full flex items-center bg-tm-gray-100 hover:bg-sky-600/10 rounded-xl",
                htmlAfter: () =>
                    <div
                        className="py-3 px-4 min-w-0 flex-1 text-sm text-left">
                        <label htmlFor="person-3"
                               className="font-medium text-tm-gray-900 select-none">
                            {translate("field.IsStopUpdateReminderOn")}
                        </label>

                        <p className="text-tm-gray-700">{translate("text.StopUpdateReminderInfo")}</p>
                    </div>
            }),
            ReminderIntervalDays: new Field('ReminderIntervalDays', '', [], !item.IsStopUpdateReminderOn, 'integer', {
                addContainerClass: "col-span-3 col-start-1 sm:col-start-9 sm:col-span-1 w-[calc(100%+1rem+1px)]",
                addClass: "form-control text-center relative rounded-r-none focus:z-10",
            }),
            ReminderIntervalHours: new Field('ReminderIntervalHours', '', [], !item.IsStopUpdateReminderOn, 'integer', {
                addContainerClass: "col-span-3 sm:col-span-1 w-[calc(100%+1rem+1px)]",
                addClass: "form-control text-center rounded-none focus:z-10 focus:relative",
            }),
            ReminderIntervalMinutes: new Field('ReminderIntervalMinutes', '', [], !item.IsStopUpdateReminderOn, 'integer', {
                addContainerClass: "col-span-6 sm:col-span-2",
                addClass: "form-control text-center rounded-l-none focus:z-10",
            })
        }

        const filledFields = fillFieldsFromData(fieldTemplates, item)
        filledFields.Notes.value = "";

        if (item?.IsStopUpdateReminderOn) {
            const conversion = minutesToDayHourMinutes(item.StopUpdateReminderInternal)

            filledFields.ReminderIntervalDays.value = conversion.days ?? 0;
            filledFields.ReminderIntervalHours.value = conversion.hours ?? 0;
            filledFields.ReminderIntervalMinutes.value = conversion.minutes ?? 0;
        }

        return filledFields;
    }

    function getSnoozeFields() {
        return {
            Snooze: new Field('Snooze', "", ['empty'], false, 'number', {}, {autoFocus: true})
        }
    }

    return (
        <ModalSaveResource
            title={translate('text.post_stop_update') + " #" + selectedItem?.LoadNumber}
            widthClass="max-w-7xl"
            show={show}
            addCenterSectionClass="h-[calc(100vh-12rem)] overflow-auto"
            customButtonsHTML={
                <button
                    className="btn btn-outline"
                    onClick={() => {
                        if (postUpdatePingLocation.address) {
                            setPostUpdatePingLocation({})
                        } else {
                            handlePingUpdateClick();
                        }
                    }}
                >
                    <MapPinIcon className="w-5 h-5 -ml-1 mr-1"/>

                    {postUpdatePingLocation.address ? "Remove ping update" : "Add ping update"}
                </button>
            }

            gridColsClass="grid-cols-12"
            fields={getStopUpdateFields(selectedItem)}
            handleInputChange={handleStopUpdateFieldsChange}
            onClose={onClose}
            options={
                !!onPostponeUpdateSubmit && (
                    <PopOver
                        btnClass="btn btn-icon"
                        btnCustomHtml={
                            <React.Fragment>
                                <BellSlashIcon className='w-6 h-6 mr-1'/>
                                Postpone
                            </React.Fragment>
                        }
                        widthClass="max-w-sm"
                        positionClass="absolute translate-x-0 right-0"
                    >
                        <div className="popover-card px-6 py-4">
                            <p className='text-base mb-4'>{translate('text.post_stop_update_snooze')}</p>
                            <form
                                className="space-y-4"
                                onSubmit={handlePostponeClick}
                            >
                                <FieldsToHtml
                                    fieldsState={snoozeFields}
                                    onInputChange={handleSnoozeInputChange}
                                    translate={translate}

                                />

                                <div className='flex justify-end'>
                                    <button className='btn btn-primary'>
                                        {translate("btn.postpone")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </PopOver>
                )
            }
            onSubmit={(params) => {
                if (params) {
                    delete params.DateTime

                    if (postUpdatePingLocation.LocationID) {
                        params.LocationID = postUpdatePingLocation.LocationID;
                    }

                    if (!!postUpdatePingLocation.lat && !!postUpdatePingLocation.lng) {
                        params.Latitude = postUpdatePingLocation.lat;
                        params.Longitude = postUpdatePingLocation.lng;
                    }

                    params = convertReminderFields(params, 'IsStopUpdateReminderOn', 'StopUpdateReminderInternal');
                    params.LoadStopIndex = selectedItem.LoadStopIndex;
                    onSubmit(params);
                    setPostUpdatePingLocation({});
                }
            }}
            translate={translate}
            metadata={{
                LoadStopStatusID: getLookup('LoadStopStatus', 'LoadStopStatusID', 'LoadStopStatus')
            }}
            htmlAfter={
                <LoadsViewStopUpdate
                    selectedItem={selectedItem}
                    isPingUpdateModalOpen={isPingUpdateModalOpen}
                    setIsPingUpdateModalOpen={setIsPingUpdateModalOpen}
                    postUpdatePingLocation={postUpdatePingLocation}
                    setPostUpdatePingLocation={(postUpdatePingLocation) => {
                        setPostUpdatePingLocation(postUpdatePingLocation);
                    }}
                    translate={translate}
                />
            }
            htmlRight={
                <div className="w-96 border-l border-tm-gray-300 h-[calc(100vh-12rem)] overflow-auto">
                    {selectedItem?.LoadID && (
                        <EventsTab
                            loadID={selectedItem.LoadID}
                            disableCreateEvent={true}
                            translate={translate}
                        />
                    )}
                </div>
            }
        />
    )
}