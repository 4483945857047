import React, {useRef, useState} from "react";
import MinusCircleIcon from "@heroicons/react/20/solid/MinusCircleIcon";
import {classNames, getFullAddressName, getLookup} from '../../../../common/util/util-helpers'
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import CheckCircleIcon from "@heroicons/react/20/solid/CheckCircleIcon";
import ExclamationTriangleIcon from "@heroicons/react/20/solid/ExclamationTriangleIcon";
import PencilIcon from "@heroicons/react/20/solid/PencilIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import ScaleIcon from "@heroicons/react/20/solid/ScaleIcon";
import ViewColumnsIcon from "@heroicons/react/20/solid/ViewColumnsIcon";
import Squares2X2Icon from "@heroicons/react/20/solid/Squares2X2Icon";
import CubeIcon from "@heroicons/react/20/solid/CubeIcon";
import {
    ArrowDownIcon,
    ArrowUpIcon,
    ChevronDownIcon,
    DocumentIcon,
    EllipsisVerticalIcon
} from "@heroicons/react/20/solid";
import Subtitle from "../../../../common/components/layout/layout-components/page/subtitle";
import FieldSelectSearch from "../../../../common/components/fields/field-select-search";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import {Loader, LoaderLarge} from "../../../../common/components/loader";
import Tooltip from "../../../../common/components/tooltip";
import TrashIcon from "@heroicons/react/20/solid/TrashIcon";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";

const freightClassLookup = getLookup("FreightClass", "FreightClassID", "FreightClass")

export default function LoadInfoCommodities({
                                                toggleCommodityFormModal,
                                                totalCommodityWeight,
                                                defaultWeightUnit,
                                                commodityUnitType,
                                                standardLengthMeasure,
                                                totalCommodityVolume,
                                                totalCommodityPieces,
                                                totalCommodityPallets,
                                                innerRef,
                                                toggleExpandSection,
                                                handleDeleteCommodityClick,
                                                isSectionExpanded,
                                                data,
                                                originalData,
                                                stops,
                                                stopsCombined,
                                                translate,
                                                addClass,
                                                disableEdit,
                                                isSelectable,
                                                selectedItem,
                                                onSelectItem,
                                                selectedClass,
                                                onInputChange,
                                                onPrintBOLClick,
                                                isLoading,
                                                isStateDirty,
                                                hidePrintBOL,
                                                updateCommodityOrder,
                                                isCollapseDisabled,
                                                isDelivered,
                                                disableDrag,
                                                onClearCommoditiesClick
                                            }) {
    const currentDragOverIndex = useRef();
    const [expandedList, setExpandedList] = useState({});
    const [canDrag, setCanDrag] = useState(false);
    const [currentlyDragged, setCurrentlyDragged] = useState(null);
    const [dragOverIndex, setDragOverIndex] = useState(null);
    const [isConfirmClearCommoditiesModalOpen, setIsConfirmClearCommoditiesModalOpen] = useState(false);

    function handleClearCommoditiesClick() {
        setIsConfirmClearCommoditiesModalOpen(true);
    }

    const getPickupAndDestinationOrder = (stopsCombined = [], stops, stop) => {
        const destinationStop = stopsCombined.find((it, orderIndex) => {
            stop.destinationOrder = orderIndex + 1;
            return Number(stops[it.index]?.LoadStopID?.value) === Number(stop.LoadCommodityDestinationID);
        });

        if (!destinationStop) {
            stop.destinationOrder = "";
        }

        const pickupStop = stopsCombined.find((it, orderIndex) => {
            stop.pickupOrder = orderIndex + 1;
            return Number(stops[it.index]?.LoadStopID?.value) === Number(stop.LoadCommodityPickupID);
        });

        if (!pickupStop) {
            stop.pickupOrder = "";
        }

        return stop;
    }

    function toggleCollapse(index) {
        if (expandedList[index]) {
            delete expandedList[index];
        } else {
            expandedList[index] = true;
        }

        setExpandedList(Object.assign({}, expandedList));
    }

    function handleDragStart(e, stop) {
        setCurrentlyDragged(stop);
    }

    function handleDragEnd() {
        let temp = originalData[currentDragOverIndex.current];
        if (!temp) {
            setCanDrag(false);
            setCurrentlyDragged(false);
            setDragOverIndex(false);
            return null;
        }

        originalData[currentDragOverIndex.current] = originalData[currentlyDragged.CommodityOrder];
        originalData[currentlyDragged.CommodityOrder] = temp;

        currentDragOverIndex.current = null;

        originalData = originalData.map((it, i) => {
            it.CommodityOrder = i;
            return it;
        });

        updateCommodityOrder(originalData);
        setCanDrag(false);
        setCurrentlyDragged(false);
        setDragOverIndex(false);
    }

    function handleMouseLeave() {
        if (!currentlyDragged) {
            setCanDrag(false);
        }
    }

    function handleDragOverIndex(e, i) {
        e.preventDefault();
        setDragOverIndex(i);
        currentDragOverIndex.current = i;
    }

    return (

        <div ref={innerRef} className={classNames(
            "grid grid-cols-12 pt-3 pb-6",
            addClass
        )}>
            <div className="col-span-full pt-3">
                <div className="flex items-center">
                    {!!toggleExpandSection && !isCollapseDisabled && (
                        <button
                            className="btn btn-icon w-8 h-8 -ml-2 md mr-2"
                            onClick={() => toggleExpandSection("LoadInfoCommodities")}
                        >
                            <ChevronRightIcon
                                className={
                                    classNames(
                                        "w-5 h-5",
                                        isSectionExpanded ? "rotate-90" : undefined
                                    )
                                }
                            />
                        </button>
                    )}

                    <Subtitle subtitle={translate("text.commodities")}/>

                    {isSectionExpanded && !!data.length && !disableEdit && !!toggleCommodityFormModal && (
                        <div className="ml-auto">
                            <button
                                className="btn btn-text gap-2 hover:bg-tm-gray-100 text-primary px-4"
                                onClick={toggleCommodityFormModal}
                            >
                                <PlusCircleIcon className="w-5 h-5"/>
                                {translate("btn.add_commodity")}
                            </button>
                        </div>
                    )}
                </div>

                {isSectionExpanded && (
                    <div className="mt-3 flex space-x-10 mb-4 ml-8">
                        {!!totalCommodityWeight && (
                            <div
                                className="border-0 flex items-center text-tm-gray-600 gap-2"
                            >

                                <ScaleIcon className="w-5 h-5"/>

                                <div className="flex flex-col justify-start text-left leading-5">
                                    <div className="leading-4 text-tm-gray-700">
                                        {translate("text.TotalWeight")}
                                    </div>

                                    <div>
                                        <span
                                            className="font-bold text-tm-gray-900">{totalCommodityWeight + " " + defaultWeightUnit}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!!totalCommodityPallets && (
                            <div
                                className="relative border-0 flex items-center text-tm-gray-600 gap-2"
                            >
                                <ViewColumnsIcon className="w-5 h-5"/>

                                <div className="flex flex-col justify-start text-left leading-5">
                                    <div className="leading-4 text-tm-gray-700">
                                        {translate("text.total_commodity_units", [commodityUnitType])}
                                    </div>

                                    <div>
                                            <span
                                                className="font-bold text-tm-gray-900">{totalCommodityPallets}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!!totalCommodityPieces && (
                            <div
                                className="border-0 flex items-center text-tm-gray-600 gap-2"
                            >

                                <Squares2X2Icon className="w-5 h-5"/>

                                <div className="flex flex-col justify-start text-left leading-5">
                                    <div className="leading-4 text-tm-gray-700">
                                        {translate("text.TotalPieces")}
                                    </div>

                                    <div>
                                        <span
                                            className="font-bold text-tm-gray-900">{totalCommodityPieces}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!!totalCommodityVolume && (
                            <div
                                className="border-0 flex items-center text-tm-gray-600 gap-2"
                            >

                                <CubeIcon className="w-5 h-5"/>

                                <div className="flex flex-col justify-start text-left leading-5">
                                    <div className="leading-4 text-tm-gray-700">
                                        {translate("text.TotalVolume")}
                                    </div>

                                    <div>
                                        <span
                                            className="font-bold text-tm-gray-900">{totalCommodityVolume.toFixed(4)} {standardLengthMeasure}<sup>3</sup></span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {isSectionExpanded && (
                <React.Fragment>
                    {data.sort((a, b) => (a.CommodityOrder > b.CommodityOrder) ? 1 : -1).map((stop, stopIndex) => {

                        const isCollapsed = !isCollapseDisabled && !expandedList[stop.CommodityOrder] && !stop?.LoadCommodityPickupIDError && !stop.LoadCommodityDestinationIDError;
                        const pickupAddressStop = stops?.find(it => it.LoadStopID.value == stop.LoadCommodityPickupID);
                        let PickupCityAddress = "";

                        if (pickupAddressStop) {
                            PickupCityAddress = getFullAddressName(pickupAddressStop.LocationData.value);
                        }

                        const destinationAddressStop = stops?.find(it => it.LoadStopID.value == stop.LoadCommodityDestinationID);
                        let DestinationCityAddress = "";

                        if (destinationAddressStop) {
                            DestinationCityAddress = getFullAddressName(destinationAddressStop.LocationData.value);
                        }

                        stop = getPickupAndDestinationOrder(stopsCombined, stops, stop);

                        const pickupList = stops?.filter(it => it.StopType.value == '1' || it.StopType.value == '4')
                            .reduce((memo, it) => {
                                memo[it.LoadStopID.value] = it.StopID?.value?.label;
                                return memo
                            }, {});

                        const destinationList = stops?.filter(it => it.StopType.value == '5' || it.StopType.value == '4')
                            .reduce((memo, it) => {
                                memo[it.LoadStopID.value] = it.StopID?.value?.label;
                                return memo
                            }, {});


                        const freightInfo = [
                            {name: "FreightClass", value: freightClassLookup?.[stop.FreightClassID]},
                            {name: "ProductNMFC", value: stop?.ProductNMFC}
                        ]

                        const units = stop?.CountPallets ? stop.CountPallets + " " : ""
                        const unitType = units + (stop.UnitType ? stop.UnitType : "")

                        const productInfo = [
                            {name: "Code", value: stop.ProductCode},
                            {name: "ref_no", value: stop.CommodityReferenceNumber},
                            {name: "units", value: unitType},
                            {name: "pieces", value: stop.CountPieces},
                            {
                                name: "Stackable",
                                value: stop.Stackable ? <CheckCircleIcon className="w-5 h-5 text-green-600"/> : ""
                            },
                            {
                                name: "Hazmat",
                                value: stop.Hazmat ? <ExclamationTriangleIcon className="w-5 h-5 text-red-600"/> : ""
                            },
                        ]

                        const tempUnit = stop.Temp
                            ? [1, 2].includes(stop.MeasureUnitID) ? "°F" : "°C"
                            : ""

                        const measurements = [
                            {name: "Length", value: stop.LengthConverted},
                            {name: "Width", value: stop.WidthConverted},
                            {name: "Height", value: stop.HeightConverted},
                            {name: "Weight", value: stop.WeightConverted},
                            {name: "VolumeCalculated", value: stop.VolumeCalculated},
                            {name: "DensityCalculated", value: stop.DensityCalculated},
                            {
                                name: "Temp", value: stop.Temp
                                    ? stop.Temp + tempUnit
                                    : undefined
                            },
                        ]

                        const canPrintBol = !(!stop.LoadCommodityDestinationID || !stop.LoadCommodityPickupID) && !isStateDirty;
                        return (
                            <div key={stop.key} className="col-span-full relative mb-4">
                                {!!currentlyDragged && currentlyDragged.CommodityOrder !== stop.CommodityOrder && (
                                    <div
                                        onDragOver={(e) => handleDragOverIndex(e, stop.CommodityOrder)}
                                        onDragLeave={() => setDragOverIndex(null)}
                                        className={
                                            classNames(
                                                "absolute inset-0 rounded-card z-20",
                                                dragOverIndex === stop.CommodityOrder ? "bg-green-600/30" : "bg-primary-transparent"
                                            )
                                        }
                                    />
                                )}

                                <div
                                    draggable={canDrag}
                                    onDragStart={(e) => handleDragStart(e, stop)}
                                    onDragEnd={handleDragEnd}
                                    onDrop={() => null}
                                    className="border-2 border-primary-tint rounded-lg"
                                >
                                    <dl>
                                        <div
                                            className={
                                                classNames(
                                                    isCollapsed
                                                        ? "rounded-xl grid grid-cols-1 md:grid-cols-4"
                                                        : "border-b border-tm-gray-300 rounded-t-lg flex items-center",
                                                    "bg-primary-transparent pl-3 pr-4 py-2.5 group"
                                                )
                                            }
                                        >
                                            <div className="flex items-center">
                                                {!!data?.length && data.length > 1 && disableDrag !== true && (
                                                    <button
                                                        className="flex invisible text-tm-gray-500 hover:cursor-grab group-hover:visible"
                                                        onMouseEnter={() => setCanDrag(true)}
                                                        onMouseLeave={handleMouseLeave}
                                                    >
                                                        <div className="w-6 h-5 relative">
                                                            <EllipsisVerticalIcon
                                                                className="w-5 h-5 flex-shrink-0 absolute -left-1"/>
                                                            <EllipsisVerticalIcon
                                                                className="w-5 h-5 flex-shrink-0 absolute right-0.5"/>
                                                        </div>
                                                    </button>
                                                )}

                                                {!isCollapseDisabled && (
                                                    <button
                                                        className="btn btn-icon pl-1 text-left hover:bg-primary-transparent"
                                                        onClick={() => toggleCollapse(stop.CommodityOrder)}
                                                    >
                                                        <div className="w-6 h-6 ml-0 mr-2">
                                                            <ChevronDownIcon
                                                                className={isCollapsed ? "-rotate-90" : undefined}/>
                                                        </div>

                                                        <p className="font-bold text-tm-gray-700">{stopIndex + 1}. {stop.ProductName ? stop.ProductName : "Commodity #" + (stopIndex + 1)}</p>
                                                    </button>
                                                )}

                                                {isCollapseDisabled && (
                                                    <p className="font-bold text-tm-gray-700">{stopIndex + 1}. {stop.ProductName ? stop.ProductName : "Commodity #" + (stopIndex + 1)}</p>
                                                )}
                                            </div>

                                            <div className="relative">
                                                {isCollapsed && !isLoading && PickupCityAddress && (
                                                    <React.Fragment>
                                                        <ArrowUpIcon
                                                            className="w-4 h-4 absolute top-2.5 text-tm-gray-600"/>

                                                        <div className="pt-2 flex items-center pl-4 relative">
                                                            <p className="truncate">{PickupCityAddress}</p>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </div>

                                            <div className="relative pl-2">
                                                {isCollapsed && !isLoading && DestinationCityAddress && (
                                                    <React.Fragment>
                                                        <ArrowDownIcon
                                                            className="w-4 h-4 absolute top-2.5 text-tm-gray-600"/>


                                                        <div className="pt-2 flex items-center pl-4 relative">
                                                            <p className="truncate">{DestinationCityAddress}</p>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </div>


                                            {!disableEdit && (
                                                <div className="ml-auto space-x-2">
                                                    {!hidePrintBOL && (
                                                        <Tooltip
                                                            content={canPrintBol ? "Print BOL" : "To print BOL you must save your changes and have Pickup and Delivery locations selected."}
                                                        >
                                                            <span>
                                                                <button
                                                                    title={translate("btn.print_bol")}
                                                                    className="btn btn-icon m-0"
                                                                    onClick={() => onPrintBOLClick(stop.LoadCommodityID)}
                                                                    disabled={!canPrintBol}
                                                                >
                                                                    <DocumentIcon className="h-4 w-4"/>
                                                                </button>
                                                            </span>
                                                        </Tooltip>
                                                    )}

                                                    <button
                                                        title={translate("btn.edit_commodity")}
                                                        className="btn btn-icon"
                                                        onClick={() => toggleCommodityFormModal(stop, stopIndex)}
                                                    >
                                                        <PencilIcon className="h-4 w-4"/>
                                                    </button>

                                                    <button
                                                        title={translate("btn.delete_commodity")}
                                                        className="btn btn-icon"
                                                        onClick={() => handleDeleteCommodityClick(stop, stopIndex)}
                                                    >
                                                        <MinusCircleIcon className="h-4 w-4"/>
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                        {!isCollapsed && (
                                            <React.Fragment>
                                                {!!stops?.length && stops?.[0]?.StopID?.value && stops?.[1]?.StopID?.value && (
                                                    <div
                                                        className="bg-inverse px-4 py-2.5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                                        <dt className="text-sm font-medium text-tm-gray-600 flex items-center">
                                                            Pickup
                                                            /
                                                            Destination
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-tm-gray-900 sm:mt-0 sm:col-span-3">
                                                            <div
                                                                className="grid grid-cols-2 gap-x-4"
                                                            >
                                                                <div
                                                                    className="grow flex divide-x divide-tm-gray-200 border border-tm-gray-200 rounded-md">
                                                                    <React.Fragment>
                                                                        {stop.pickupOrder && (
                                                                            <div
                                                                                className="pl-2.5 pr-2 text-tm-gray-500 font-bold flex items-center">
                                                                                {stop.pickupOrder}.
                                                                            </div>
                                                                        )}

                                                                        <div
                                                                            className="px-4 pt-3 pb-1.5 w-full min-w-0">
                                                                            <div className="w-full">
                                                                                <FieldSelectSearch
                                                                                    name={"LoadCommodityPickupID"}
                                                                                    className="form-control w-full"
                                                                                    onChange={(name, value) => onInputChange(name, value, pickupList[value], stopIndex, stop.pickupOrder)}
                                                                                    placeholder={'Select pickup stop...'}
                                                                                    addClass={'form-control'}
                                                                                    value={stop.LoadCommodityPickupID}
                                                                                    errorMessage={stop.LoadCommodityPickupIDError}
                                                                                    values={pickupList}
                                                                                    disabled={isDelivered}
                                                                                />
                                                                            </div>

                                                                            {!!PickupCityAddress && (
                                                                                <p className="text-tm-gray-500 font-bold ml-3 mt-1"> {PickupCityAddress}</p>
                                                                            )}
                                                                        </div>
                                                                    </React.Fragment>
                                                                </div>

                                                                <div
                                                                    className="grow flex divide-x divide-tm-gray-200 border border-tm-gray-200 rounded-md">
                                                                    <React.Fragment>
                                                                        {stop.destinationOrder && (
                                                                            <div
                                                                                className="pl-2.5 pr-2 text-tm-gray-500 font-bold flex items-center">
                                                                                {stop.destinationOrder}.
                                                                            </div>
                                                                        )}

                                                                        <div
                                                                            className="px-4 pt-3 pb-1.5 w-full min-w-0">
                                                                            <div className="w-full">
                                                                                <FieldSelectSearch
                                                                                    name={"LoadCommodityDestinationID"}
                                                                                    className="form-control w-full"
                                                                                    onChange={(name, value) => onInputChange(name, value, destinationList[value], stopIndex, stop.destinationOrder)}
                                                                                    placeholder={'Select destination stop...'}
                                                                                    addClass={'form-control'}
                                                                                    value={stop.LoadCommodityDestinationID}
                                                                                    errorMessage={stop.LoadCommodityDestinationIDError}
                                                                                    values={destinationList}
                                                                                    disabled={isDelivered}
                                                                                />
                                                                            </div>

                                                                            {!!DestinationCityAddress && (
                                                                                <p className="text-tm-gray-500 font-bold ml-3 mt-1">{DestinationCityAddress}</p>
                                                                            )}
                                                                        </div>
                                                                    </React.Fragment>
                                                                </div>
                                                            </div>
                                                        </dd>
                                                    </div>
                                                )}

                                                {!!stop.IsLTL && (
                                                    <div
                                                        className="bg-tm-gray-100 px-4 py-2.5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                                        <dt className="text-sm font-medium text-tm-gray-600 flex items-center">
                                                            Freight info
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                            <div
                                                                className="border border-tm-gray-200 rounded-md flex divide-x divide-tm-gray-200 overflow-hidden"
                                                            >
                                                                {freightInfo.map(it => renderItem(it, translate))}
                                                            </div>
                                                        </dd>
                                                    </div>
                                                )}

                                                <div className={
                                                    classNames(
                                                        "px-4 py-2.5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6",
                                                        stop.IsLTL ? "bg-inverse" : "bg-tm-gray-100"
                                                    )}
                                                >
                                                    <dt className="text-sm font-medium text-tm-gray-600 flex items-center">
                                                        {translate("text.product_info")}
                                                    </dt>

                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 overflow-x-auto">
                                                        <div
                                                            className="border border-tm-gray-200 rounded-md flex flex-col md:flex-row divide-y md:divide-y-0 md:divide-x divide-tm-gray-200 bg-inverse"
                                                        >
                                                            {
                                                                productInfo.map(it =>
                                                                    renderItem(it, translate, isSelectable,
                                                                        checkSelectedItem(isSelectable, it, selectedItem),
                                                                        onSelectItem, selectedClass)
                                                                )
                                                            }
                                                        </div>
                                                    </dd>
                                                </div>

                                                <div
                                                    className={classNames(
                                                        "px-4 py-2.5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 rounded-b-lg",
                                                        !stop.IsLTL ? "bg-inverse" : "bg-tm-gray-100"
                                                    )}
                                                >
                                                    <dt className="text-sm font-medium text-tm-gray-600 flex items-center">
                                                        Measurements
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 overflow-x-auto">
                                                        <div
                                                            className="border border-tm-gray-200 rounded-md flex flex-col md:flex-row divide-y md:divide-y-0 md:divide-x divide-tm-gray-200 bg-inverse"
                                                        >
                                                            {measurements.map((it) => renderItem(it, translate, isSelectable, checkSelectedItem(isSelectable, it, selectedItem), onSelectItem, selectedClass))}
                                                        </div>
                                                    </dd>
                                                </div>
                                            </React.Fragment>
                                        )}

                                    </dl>
                                </div>
                            </div>
                        )
                    })}

                    <div className="col-span-full">
                        <NoRecords
                            show={!data.length}
                            canCreate={!disableEdit}
                            onBtnClick={toggleCommodityFormModal}
                            addClass="px-4 pb-6"
                            title="No Commodities"
                            text={'You haven’t added any commodity yet.'}
                            btnLabel="Add a commodity"
                        />
                    </div>

                    {isSectionExpanded && !!data.length && onClearCommoditiesClick && (
                        <div className="col-span-full flex-end">
                            <button
                                className="btn btn-text gap-2 hover:bg-tm-gray-100 text-red-600 hover:text-red-700 px-4"
                                onClick={handleClearCommoditiesClick}
                            >
                                <TrashIcon className="w-5 h-5"/>
                                {translate("btn.clear_commodities")}
                            </button>
                        </div>
                    )}

                    <ModalConfirm
                        title={'Confirm clearing commodities'}
                        show={isConfirmClearCommoditiesModalOpen}
                        text={"Are you sure you want to clear all commodities?"}
                        onClose={() => setIsConfirmClearCommoditiesModalOpen(false)}
                        buttonLabel={translate('btn.clear_commodities')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={() => {
                            onClearCommoditiesClick();
                            setIsConfirmClearCommoditiesModalOpen(false);
                        }}
                    />

                </React.Fragment>
            )}

            {isLoading && (
                <React.Fragment>
                    {isSectionExpanded && (
                        <LoaderLarge stripesBg={true}/>
                    )}

                    {!isSectionExpanded && (
                        <Loader stripesBg={true}/>
                    )}
                </React.Fragment>
            )}
        </div>
    )
}

function renderItem(it, translate, isSelectable, isSelected, onSelectItem, selectedClass) {
    return (
        <div key={it.name} className="relative px-4 py-1.5 grow bg-inverse first:rounded-l-md last:rounded-r-md">
            {isSelectable && (
                <button
                    onClick={() => onSelectItem(it)}
                    className={
                        classNames(
                            "cursor-pointer absolute  -inset-2 w-[calc(100%+1rem)] z-10 rounded-md",
                            isSelected ? "border-2" : "bg-transparent border-2 border-transparent",
                            isSelected && !selectedClass ? "bg-sky-600/10 border-sky-600" : undefined,
                            isSelected && !!selectedClass ? selectedClass : undefined,
                        )
                    }
                />
            )}

            <p className="font-medium text-tm-gray-500">{translate("field." + it.name)}</p>
            <div className="truncate text-tm-gray-900">{it.value ? it.value :
                <XMarkIcon className="w-5 h-5 text-tm-gray-500"/>}</div>
        </div>
    )
}

function checkSelectedItem(isSelectable, it, selectedItem) {
    if (!isSelectable) return false;

    return selectedItem.name === it.name;
}
