import {Field, FieldsManager} from "../../../../data/services/fields";
import {
    cnv,
    ConstantTranslate, getFullAddressName,
    getMeasurementUnitName,
    getWeightUnitName,
    groupListBySCAC
} from '../../../../common/util/util-helpers'
import {
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_METADATA_SELECT_LOCATION_LOAD_SEARCH_QUERY,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    MINIMUM_STOP_NUMBER,
    STOP_BY_TYPE_FUEL_STATION,
    STOP_BY_TYPE_HOME,
    STOP_BY_TYPE_PARKING,
    STOP_BY_TYPE_PERSONAL,
    STOP_BY_TYPE_REPAIR_SHOP,
    STOP_BY_TYPE_REST,
    STOP_BY_TYPE_SWITCH_ASSET,
    STOP_BY_TYPE_WEIGHT_STATION,
    STOP_BY_TYPES,
    STOP_TYPE_DELIVERY,
    STOP_TYPE_PICKUP,
    STOP_TYPE_STOP_BY,
    STOP_TYPES,
    WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    WORKING_HOURS_NON_STOP
} from '../../../../util/util-constants'
import Tippy from "@tippyjs/react";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import React from "react";
import moment from "moment";
import {
    getTimeFromServerDate,
    getUserTimeFormat,
    toFrontDate,
    toFrontDateTime
} from "../../../../common/util/util-dates";
import Resources from "../../../../data/services/resources";
import {getLookup} from "../../../../common/util/util-helpers";
import LocalStorage from "../../../../util/localStorage";
import {
    AwesomeBedIcon,
    AwesomeGasStationIcon,
    AwesomeHomeIcon,
    AwesomeParkingIcon,
    AwesomeRepairShopIcon,
    AwesomeRepeatIcon,
    AwesomeStopIcon,
    AwesomeUserIcon,
    AwesomeWeightScaleIcon
} from "../../../../data/themes/icons";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import CopyToClipboardButton from "../../../../common/components/buttons/copy-to-clipboard-button";
import FieldSwitchLabel from "../../../../common/components/fields/field-switch/field-switch-label";
import TextCopy from '../../../../common/components/resource-table/table-components/text-copy'

export function getStopFields(
    location = {},
    translate,
    hideFields = [],
) {
    const IsStopCompleted = !!location.IsStopCompleted;
    const isEndDateTimeDisabled = IsStopCompleted || !location?.StopDate;

    if (location.Multistop) {
        location.Stop = location.Multistop
    }

    let fieldTemplates = ({
        StopID: new Field('StopID', "", ['empty_select_search'], IsStopCompleted, 'select-search',
            {
                addContainerClass: "col-span-full",
            }, {}),

        StopType: new Field('StopType', "", [''], IsStopCompleted, 'select', {addContainerClass: "col-span-4"}),

        ReferenceNumber: new Field('ReferenceNumber', '', [''], false, 'creatable', {
            addContainerClass: 'col-span-8',
            onEnterDown: true,
            fieldOptions: (it) => {
                return (
                    it.value
                        ? <div className="gap-x-3 h-5 relative bottom-0.5 flex">
                            <CopyToClipboardButton
                                clipboardText={(it.value ? it.value : []).reduce((memo, it) => {
                                    memo.push(it.value);
                                    return memo;
                                }, []).join(", ")}
                                addClass="relative -top-2"
                                translate={translate}
                            />
                        </div>
                        : null
                )
            }
        }, {
            isMulti: true,
            components: {DropdownIndicator: null},
            menuIsOpen: false,
        }),
        StopDate: new Field('StopDate', "", [''], IsStopCompleted, 'date', {
            label: "available_from_date_time",
            addContainerClass: "col-start-1 2xl:col-span-3 md:col-span-4 col-span-6 w-[calc(100%+1rem+1px)]",
            formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 whitespace-nowrap"
        }, {isClearable: true, addClass: "rounded-r-none", isCloseFocusable: false}),
        StopTime: new Field('StopTime', "", [''], IsStopCompleted, 'time-custom', {
            hideLabel: true,
            warnings: {},
            addContainerClass: "2xl:col-span-3 md:col-span-4 col-span-6",
            fieldOptions: (it) => getDateWarnings(it, translate),
            formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 h-5"
        }, {addClass: "rounded-l-none"}),

        StopEndDate: new Field('StopEndDate', "", [''], isEndDateTimeDisabled, 'date', {
            label: "available_through_date_time",
            addContainerClass: "col-start-1 md:col-start-1 2xl:col-span-3 md:col-span-4 col-span-6 w-[calc(100%+1rem+1px)]",
            formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 whitespace-nowrap"
        }, {isClearable: true, isCloseFocusable: false, addClass: "rounded-r-none"}),
        StopEndTime: new Field('StopEndTime', "", [''], isEndDateTimeDisabled, 'time-custom', {
            hideLabel: true,
            warnings: {},
            fieldOptions: (it) => getDateWarnings ? getDateWarnings(it, translate) : null,
            formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 h-5",
            addContainerClass: "2xl:col-span-3 md:col-span-4 col-span-6"
        }, {addClass: "rounded-l-none"}),
        RequiresAppointment: new Field('RequiresAppointment', "", [''], IsStopCompleted, "checkbox", {addContainerClass: "col-span-6"}),
        // AptReferenceNumber: new Field('AptReferenceNumber', "", [''], IsStopCompleted, !!location.RequiresAppointment ? "text" : "hidden", {addContainerClass: "3xl:col-start-9 col-span-6 3xl:col-span-4"}),

        AptReferenceNumber: new Field('AptReferenceNumber', '', [''], IsStopCompleted, location.RequiresAppointment ? "creatable" : "hidden", {
            addContainerClass: '3xl:col-start-9 col-span-6 3xl:col-span-4',
            onEnterDown: true,
            fieldOptions: (it) => {
                return (
                    it.value
                        ? <div className="gap-x-3 h-5 relative bottom-0.5 flex">
                            <CopyToClipboardButton
                                clipboardText={(it.value ? it.value : []).reduce((memo, it) => {
                                    memo.push(it.value);
                                    return memo;
                                }, []).join(", ")}
                                addClass="relative -top-2"
                                translate={translate}
                            />
                        </div>
                        : null
                )
            }

        }, {
            isMulti: true,
            components: {DropdownIndicator: null},
            menuIsOpen: false,
        }),
        Notes: new Field('Notes', "", [''], IsStopCompleted, 'textarea', {
            label: "location_notes",
            addContainerClass: "col-span-full"
        }),
        ChangeAppointment: new Field('ChangeAppointment', "", [''], IsStopCompleted, "hidden"),
        StopTimezone: new Field('StopTimezone', 'UTC', ['empty'], false, 'text'),
        LocationData: new Field('LocationData', location),
        LoadStopID: new Field('LoadStopID', '', [''], false, "hidden"),
        IsStopCompleted: new Field('IsStopCompleted', 0, [''], false, "hidden"),

        PalletsLoaded: new Field('PalletsLoaded', '', [''], false, "hidden"),
        PalletsUnLoaded: new Field('PalletsUnLoaded', '', [''], false, "hidden"),
        SignedByName: new Field('SignedByName', '', [''], false, "hidden"),
        SignedByPhone: new Field('SignedByPhone', '', [''], false, "hidden"),
        ActualArrivalDateTime: new Field('ActualArrivalDateTime', '', [''], false, "hidden"),
        ActualDepartureDateTime: new Field('ActualDepartureDateTime', '', [''], false, "hidden"),

        NotOnTime: new Field('NotOnTime', '', [''], false, "hidden"),

        PalletsExchanged: new Field('PalletsExchanged', '', [''], false, "hidden"),
        SealNo: new Field('SealNo', '', [''], false, "hidden"),
        BOLNumber: new Field('BOLNumber', '', [''], false, "hidden"),
        StopOrder: new Field('StopOrder', 0, [], false, "hidden"),
        IncludeInMileage: new Field('IncludeInMileage', 1, [], false, "hidden"),

        IsDroppingTrailer: new Field('IsDroppingTrailer', "", [], IsStopCompleted, "switch", {
            addContainerClass: "-ml-4 text-base col-span-full flex items-center",
            htmlBefore: () =>
                <FieldSwitchLabel
                    label={translate("field.drop_trailer")}
                />
        }),

        IsPickingTrailer: new Field('IsPickingTrailer', "", [], IsStopCompleted, "switch", {
            addContainerClass: "-ml-4 text-base col-span-full flex items-center",
            htmlBefore: () =>
                <FieldSwitchLabel
                    label={translate("field.IsPickingTrailer")}
                />
        }, {isClearable: true}),

        PickingTrailerID: new Field('PickingTrailerID', "", location.IsPickingTrailer ? ['empty'] : [], IsStopCompleted, "select-search", {
            addContainerClass: "col-span-full pb-3"
        }, {
            query: {LocationID: location.StopID ?? ''},
            isClearable: true
        }),

        EstimatedRetrievalDate: new Field('EstimatedRetrievalDate', "", [], IsStopCompleted, "date", {addContainerClass: "col-span-6"}),

        IsSwitchDispatch: new Field('IsSwitchDispatch', "", [], IsStopCompleted, "switch",
            {
                addContainerClass: "-ml-4 text-base col-span-full flex items-center",
                htmlBefore: () =>
                    <FieldSwitchLabel
                        label={translate("field.location_switch_assets_label")}
                    />
            }
        ),
        DriverID: new Field('DriverID', "", location.IsSwitchDispatch ? ['empty'] : [], IsStopCompleted, "select-search", {addContainerClass: "col-span-6 col-start-1"}, {menuPlacement: "top"}),
        CoDriverID: new Field('CoDriverID', "", [''], IsStopCompleted, "select-search", {addContainerClass: "col-span-6"}, {isClearable: true, menuPlacement: "top"}),
        TruckID: new Field('TruckID', "", location.IsSwitchDispatch ? ['empty'] : [], IsStopCompleted, "select-search", {addContainerClass: "col-span-6 pb-3"}, {menuPlacement: "top"}),
        TrailerID: new Field('TrailerID', "", [], IsStopCompleted, "select-search", {addContainerClass: "col-span-6 pb-3"}, {isClearable: true, menuPlacement: "top"}),
    })

    let fields = fillFieldsFromData(fieldTemplates, location);

    // Overrides
    if (fields?.StopID?.value) {
        fields.StopID.value.metadata = location;
    }
    fields.StopTime.value = getTimeFromServerDate(location?.StopTime);
    fields.StopEndTime.value = getTimeFromServerDate(location?.StopEndTime);

    fields.ReferenceNumber.value = fillCreatableField(location.ReferenceNumber);
    fields.AptReferenceNumber.value = fillCreatableField(location.AptReferenceNumber);

    setStopWarnings(fields, location)

    if (hideFields.length) {
        hideFields.forEach(it => {
            if (fields[it]) {
                fields[it].type = 'hidden';
            }
        })
    }

    return fields;
}

const fillCreatableField = (receivedObject = "") => {

    if (!receivedObject) {
        return ""
    }

    switch (typeof (receivedObject)) {
        case "string": {
            let values = receivedObject.split(",");

            if (values.length) {
                return values.map(it => ({
                        label: it,
                        value: it,
                        "manual": true
                    })
                )
            }
            break;
        }
        case "object":
            return receivedObject;

        default:
            return ""
    }
}

export function getStopByFields(item = {}, translate) {

    const IsStopCompleted = !!item.IsStopCompleted || !!item.IsDropType;

    if (item.ActualArrivalDateTime) {
        item.ActualArrivalDate = item.ActualArrivalDateTime;
    }

    if (item.ActualDepartureDateTime) {
        item.ActualDepartureDate = item.ActualDepartureDateTime;
    }

    const fieldTemplates = {
        LoadStopByID: new Field('LoadStopByID', '', [''], false, "hidden"),
        IsStopCompleted: new Field('IsStopCompleted', IsStopCompleted, [''], false, "hidden"),
        StopByTypeID: new Field('StopByTypeID', '', [''], IsStopCompleted, "select", {addContainerClass: "col-span-full 3xl:col-span-4"}, {values: ConstantTranslate(STOP_BY_TYPES, translate)}),
        ActualArrivalDate: new Field('ActualArrivalDate', toFrontDate(moment().format(DEFAULT_DATABASE_DATETIME_FORMAT)), ['empty'], IsStopCompleted, "date", {
            addContainerClass: "col-span-3 3xl:col-span-2 w-[calc(100%+1rem+1px)]",
            label: "time_in"
        }, {addClass: "rounded-r-none"}),
        ActualArrivalDateTime: new Field('ActualArrivalDateTime', '', ['empty'], IsStopCompleted, "time-custom", {
            addContainerClass: "col-span-3 3xl:col-span-2",
            hideLabel: true
        }, {addClass: "rounded-l-none"}),
        ActualDepartureDate: new Field('ActualDepartureDate', toFrontDate(moment().format(DEFAULT_DATABASE_DATETIME_FORMAT)), ['empty'], IsStopCompleted, "date", {
            addContainerClass: "col-span-3 3xl:col-span-2 w-[calc(100%+1rem+1px)]",
            label: "time_out"
        }, {addClass: "rounded-r-none"}),
        ActualDepartureDateTime: new Field('ActualDepartureDateTime', '', ['empty'], IsStopCompleted, "time-custom", {
            addContainerClass: "col-span-3 3xl:col-span-2",
            hideLabel: true
        }, {addClass: "rounded-l-none"}),
        Notes: new Field('Notes', '', [''], IsStopCompleted, "textarea", {addContainerClass: "col-span-full"}),
        StopOrder: new Field('StopOrder', 0, [''], false, "hidden"),

        CountryID: new Field('CountryID', "1", ['empty'], false, "select", {addContainerClass: "col-span-full",}),
        GooglePlaces: new Field('GooglePlaces', '', [''], false, "google-locations", {
            addContainerClass: "col-span-full",
            label: "location_autocomplete"
        }, {}),

        AddressName: new Field('AddressName', '', ['empty'], false, "text", {addContainerClass: "col-span-full"}),
        CityName: new Field('CityName', '', ['empty'], false, "text", {addContainerClass: "col-span-6"}),
        StateID: new Field('StateID', '', ['empty'], false, "select", {addContainerClass: "col-span-3"}),
        PostalCode: new Field('PostalCode', '', ['empty'], false, "text", {addContainerClass: "col-span-3"}),

        Latitude: new Field('Latitude', '', ['empty'], false, "hidden"),
        Longitude: new Field('Longitude', '', ['empty'], false, "hidden"),


        LocationID: new Field('LocationID', '', ['empty'], false, "select-search"),
        IncludeInMileage: new Field('IncludeInMileage', 1, [], false, "hidden"),

        IsDroppingTrailer: new Field('IsDroppingTrailer', "", [], IsStopCompleted, "switch", {
            addContainerClass: "-ml-4 text-base col-span-full flex items-center",
            htmlBefore: () =>
                <FieldSwitchLabel
                    label={translate("field.drop_trailer")}
                />
        }),

        IsPickingTrailer: new Field('IsPickingTrailer', "", [], IsStopCompleted, "switch", {
            addContainerClass: "-ml-4 text-base col-span-full flex items-center",
            htmlBefore: () =>
                <FieldSwitchLabel
                    label={translate("field.IsPickingTrailer")}
                />
        }, {isClearable: true}),

        PickingTrailerID: new Field('PickingTrailerID', "", item.IsPickingTrailer ? ['empty'] : [], IsStopCompleted, "select-search", {
            addContainerClass: "col-span-full pb-3"
        }, {
            query: {LocationID: item.StopID ?? ''},
            isClearable: true
        }),

        EstimatedRetrievalDate: new Field('EstimatedRetrievalDate', "", [], IsStopCompleted, "date", {addContainerClass: "col-span-6"}),

        IsSwitchDispatch: new Field('IsSwitchDispatch', "", [], IsStopCompleted, "switch",
            {
                addContainerClass: "-ml-4 text-base col-span-full flex items-center",
                htmlBefore: () =>
                    <FieldSwitchLabel
                        label={item.IsDropType ? "Load was dropped and continued on this location" : translate("field.location_switch_assets_label")}
                    />
            }
        ),
        DriverID: new Field('DriverID', "", item.IsSwitchDispatch ? ['empty'] : [], IsStopCompleted, "select-search", {addContainerClass: "col-span-6 col-start-1"}, {menuPlacement: "top"}),
        CoDriverID: new Field('CoDriverID', "", [], IsStopCompleted, "select-search", {addContainerClass: "col-span-6"}, {isClearable: true, menuPlacement: "top"}),
        TruckID: new Field('TruckID', "", item.IsSwitchDispatch ? ['empty'] : [], IsStopCompleted, "select-search", {addContainerClass: "col-span-6 pb-3"}, {menuPlacement: "top"}),
        TrailerID: new Field('TrailerID', "", [], IsStopCompleted, "select-search", {addContainerClass: "col-span-6 pb-3"}, {isClearable: true, menuPlacement: "top"}),
    }

    let filledFields = fillFieldsFromData(fieldTemplates, item);

    let currentTime = toFrontDateTime(moment().format(DEFAULT_DATABASE_DATETIME_FORMAT)).split(' ');
    currentTime.shift()
    currentTime = currentTime.join(' ')

    if (!filledFields.ActualArrivalDateTime.value) {
        filledFields.ActualArrivalDateTime.value = currentTime;
    }

    if (!filledFields.ActualDepartureDateTime.value) {
        filledFields.ActualDepartureDateTime.value = currentTime;
    }


    return filledFields;
}

export function getCommodityFields(item = {}) {
    const fieldTemplates = {
        ProductName: new Field('ProductName', '', ['empty'], '', 'text'),
        LoadCommodityPickupID: new Field('LoadCommodityPickupID', '', [''], false, 'custom', {
            render: (it) =>
                <Tippy content={it.LoadCommodityPickup}>
                    <div className="max-w-[8rem] overflow-ellipsis overflow-hidden">
                        {it.LoadCommodityPickup}
                    </div>
                </Tippy>
        }),
        LoadCommodityDestinationID: new Field('LoadCommodityDestinationID', '', [''], false, 'custom', {
            render: (it) =>
                <Tippy content={it.LoadCommodityDestination}>
                    <div className="max-w-[8rem] overflow-ellipsis overflow-hidden">
                        {it.LoadCommodityDestination}
                    </div>
                </Tippy>
        }),
        ProductCode: new Field('ProductCode', '', [], '', 'text'),
        FreightClassID: new Field('FreightClassID', '', [], '', 'text'),
        ProductNMFC: new Field('ProductNMFC', '', [], '', 'text'),
        UnitTypeID: new Field('UnitTypeID', '', [], '', 'text'),
        CountPieces: new Field('CountPieces', '', ['integer_or_empty'], false, 'float_or_empty'),
        CountPallets: new Field('CountPallets', '', ['integer_or_empty'], false, 'float_or_empty'),

        MeasureUnitID: new Field('MeasureUnitID', '', [''], '', 'hidden'),
        Length: new Field('Length', 0, ['float_or_empty'], false, 'hidden'),
        Width: new Field('Width', 0, ['float_or_empty'], false, 'hidden'),
        Height: new Field('Height', 0, ['float_or_empty'], false, 'hidden'),

        LengthConverted: new Field('LengthConverted', 0, ['float_or_empty'], false, 'float_or_empty'),
        WidthConverted: new Field('WidthConverted', 0, ['float_or_empty'], false, 'float_or_empty'),
        HeightConverted: new Field('HeightConverted', 0, ['float_or_empty'], false, 'float_or_empty'),

        Weight: new Field('Weight', 0, ['float_or_empty'], false, 'hidden'),
        WeightConverted: new Field('WeightConverted', 0, ['text'], false, 'text'),

        VolumeCalculated: new Field('VolumeCalculated', 0, ['float_or_empty'], false, 'float_or_empty'),
        DensityCalculated: new Field('DensityCalculated', 0, ['float_or_empty'], false, 'float_or_empty'),

        Temp: new Field('Temp', "", ['float_or_empty'], false, 'text'),

        CommodityReferenceNumber: new Field('CommodityReferenceNumber', '', ['']),
        Stackable: new Field('Stackable', '', [''], false, 'checkbox'),
        Hazmat: new Field('Hazmat', '', [''], false, 'checkbox', {
            altIcon: <ExclamationTriangleIcon className={'w-5 h-5 text-red-600'}/>
        }),
    }

    return fillFieldsFromData(fieldTemplates, item)
}

export function getCombinedStopObject(it = {}, index = undefined, stops, stopBys) {
    if (index === undefined) {
        index = it.StopType === STOP_TYPE_STOP_BY ? stopBys.length : stops.length;
    }

    return {
        key: (it.LoadStopID || it.LoadStopByID) ?? it.StopType + "_" + it.index,
        index: index,
        StopType: it.StopType ?? 0,
        IsStopCompleted: !!it.IsStopCompleted,
        IsSelected: !!it.IsSelected,
        IncludeInMileage: it.IncludeInMileage,
        metadata: it
    }
}

export function generateStops(multiStopsData, translate) {
    let stops = [];

    multiStopsData.forEach((stop) => {
        stops.push(getStopFields(stop, translate))
    });

    if (stops.length === 1) { // This scenario should be impossible, server should return 2+ or 0 multiStops, never 1.
        let stopOrder = stops[0].StopOrder === 1 ? 0 : 1;
        stops.push(getStopFields({StopOrder: stopOrder}, translate))
    }

    return stops.sort(function (a, b) {
        return a.StopOrder?.value - b.StopOrder?.value;
    });
}

export function generateQuoteStops(multiStopsData, translate) {
    let stops = [];

    multiStopsData.forEach((stop) => {
        stops.push(getStopFields(stop, translate, ["ReferenceNumber", "AptReferenceNumber", "RequiresAppointment"]))
    });

    if (stops.length === 1) { // This scenario should be impossible, server should return 2+ or 0 multiStops, never 1.
        let stopOrder = stops[0].StopOrder === 1 ? 0 : 1;
        stops.push(getStopFields({StopOrder: stopOrder}, translate, ["ReferenceNumber", "AptReferenceNumber", "RequiresAppointment"]))
    }

    return stops.sort(function (a, b) {
        return a.StopOrder?.value - b.StopOrder?.value;
    });
}

export function getDateWarnings(it, translate) {
    let warnings = [];
    if (it.metadata.warnings.isBeforeStopDate) {
        warnings.push(<p>{translate("text.available_through_before_from_date")}</p>)
    }

    if (it.metadata.warnings.isNonWorkingDaySelected) {
        warnings.push(<p>{translate("text.non_working_day_selected")}</p>)
    }

    if (it.metadata.warnings.isNotWithinWorkingHours) {
        warnings.push(<p>{translate("text.not_within_working_hours")}</p>)
    }

    if (!warnings.length) return;

    return (
        <Tippy content={warnings}>
            <button
                // make a global modal
                // onClick={this.toggleWorkingHoursModal}
                className="field-btn py-0 animate-button-fade-in h-4"
            >
                <ExclamationTriangleIcon className="w-5 h-5 text-red-600"/>
            </button>
        </Tippy>
    )
}

export function processStopInputChange(name, value, stops, stopsCombined, isCreateMode) {
    let stopsCombinedIndex = 0;

    let stop = stopsCombined.find((it, i) => {
        stopsCombinedIndex = i;
        return !!it.IsSelected
    });

    const autocomplete = isCreateMode || stops[stop.index]?.RequiresAppointment?.value;

    const selectedStopIndex = stop.index;

    stop = stops[stop.index];

    if ('StopID' === name) {
        stop.LocationData.value = value.metadata;

        stopsCombined[stopsCombinedIndex].StopName = value.label
        stopsCombined[stopsCombinedIndex].addressName = getFullAddressName(value.metadata);
    }

    if ('StopType' === name) {
        stopsCombined[stopsCombinedIndex].StopType = value
    }

    stop = FieldsManager.updateField(stop, name, value);
    stop[name].errorMessage = "";

    const hasReceivingHours = !!stop?.LocationData?.value?.ReceivingHours;
    const hasShippingHours = !!stop?.LocationData?.value?.ShippingHours;
    const hasCustomReceivingHours = stop?.LocationData?.value?.CustomReceivingHours === 2;
    const hasCustomShippingHours = stop?.LocationData?.value?.CustomShippingHours === 2;

    if ('StopDate' === name || 'StopTime' === name || 'StopEndDate' === name || 'StopEndTime' === name || 'StopType' === name || 'StopID' === name) {
        const dayIdx = !!stop.StopDate.value && (new Date(stop.StopDate.value)).getDay();
        const hasSelectedDayShippingHours = !!(hasShippingHours && stop.LocationData.value.ShippingHours[dayIdx] && stop.LocationData.value.ShippingHours[dayIdx]?.Active);
        const hasSelectedDayReceivingHours = !!(hasReceivingHours && stop.LocationData.value.ReceivingHours[dayIdx] && stop.LocationData.value.ReceivingHours[dayIdx].Active);

        if (("StopDate" === name || "StopID" === name || "StopType" === name)) {
            if (autocomplete && !stop.StopEndDate.value) {
                stop = FieldsManager.updateField(stop, 'StopEndDate', stop.StopDate.value);
            }

            if (autocomplete && Number(stop.StopType.value) === STOP_TYPE_PICKUP && dayIdx !== false && hasShippingHours) {
                if (hasCustomShippingHours && hasSelectedDayShippingHours) {
                    stop = FieldsManager.updateField(stop, 'StopTime', moment(stop.LocationData.value.ShippingHours[dayIdx]?.FromTime, "HH:mm:ss").format(getUserTimeFormat()));
                    stop = FieldsManager.updateField(stop, 'StopEndTime', moment(stop.LocationData.value.ShippingHours[dayIdx]?.ToTime, "HH:mm:ss").format(getUserTimeFormat()));
                }
            } else if (autocomplete && Number(stop.StopType.value) === STOP_TYPE_DELIVERY && dayIdx !== false && hasReceivingHours) {
                if (hasCustomReceivingHours && hasSelectedDayReceivingHours) {
                    stop = FieldsManager.updateField(stop, 'StopTime', moment(stop.LocationData.value.ReceivingHours[dayIdx]?.FromTime, "HH:mm:ss").format(getUserTimeFormat()));
                    stop = FieldsManager.updateField(stop, 'StopEndTime', moment(stop.LocationData.value.ReceivingHours[dayIdx]?.ToTime, "HH:mm:ss").format(getUserTimeFormat()));
                }
            }
        }

        stop = setStopWarnings(stop);
    }

    if ('StopTime' === name && !!value && !stop.StopDate.value) {
        stop = FieldsManager.updateField(stop, 'StopDate', moment().startOf('day').format(DEFAULT_DATABASE_DATETIME_FORMAT));
    }

    if ('StopEndTime' === name && !!value && !stop.StopEndDate.value) {
        stop = FieldsManager.updateField(stop, 'StopEndDate', moment().startOf('day').format(DEFAULT_DATABASE_DATETIME_FORMAT));
    }

    if ((!!stop.StopEndDate.disabled || !!stop.StopEndTime.disabled) && !!stop.StopDate.value) {
        stop.StopEndDate.disabled = false;
        stop.StopEndTime.disabled = false;
    }

    if (!stop.StopDate.value && (!stop.StopEndDate.disabled && !stop.StopEndTime.disabled)) {
        stop.StopEndDate.disabled = true;
        stop.StopEndTime.disabled = true;
        stop.StopEndDate.value = "";
        stop.StopEndTime.value = "";
    }

    if ("StopDate" === name && !value) {
        stop.StopTime.value = "";
    }

    if ("StopEndDate" === name && !value) {
        stop.StopEndTime.value = "";
    }

    if ("RequiresAppointment" === name) {
        stop.AptReferenceNumber.type = value ? "creatable" : "hidden";
    }

    if ("IsPickingTrailer" === name) {
        stop.PickingTrailerID.validate = !value ? [] : ['empty'];
        stop.IsDroppingTrailer.value = "";
    }

    if ("PickingTrailerID" === name) {
        stop.IsPickingTrailer.value = value ? 1 : 0;
    }

    if ("IsDroppingTrailer" === name) {
        if (value) {
            stop.PickingTrailerID.validate = [];
            stop.PickingTrailerID.value = "";
            stop.IsPickingTrailer.value = 0;
        }

        stop.IsSwitchDispatch.value = "";
        stop.IsPickingTrailer.value = "";
    }

    if ("IsSwitchDispatch" === name) {
        stop.DriverID.value = "";
        stop.CoDriverID.value = "";
        stop.TruckID.value = "";
        stop.TrailerID.value = "";
    }

    if ("IsSwitchDispatch" === name) {
        stop.IsDroppingTrailer.value = "";

        stop.DriverID.validate = !value ? [] : ['empty'];
        stop.TruckID.validate = !value ? [] : ['empty'];
    }

    stop.PickingTrailerID.props.query = {LocationID: stop.StopID?.value?.value ?? ''}

    if ('StopTime' === name) {
        if (stop.StopTime.value.search("p") > -1) {
            stop.StopEndTime.props.startWithPM = true;
        }
    }

    stops[selectedStopIndex] = stop;

    return {stops, stopsCombined}
}

export function getLoadSelects(UseMultiSCAC) {
    return {
        LoadTypeID: getLookup("LoadType"),
        LoadSubTypeID: getLookup("LoadSubType"),
        CustomerID: {
            api: 'api/' + Resources.CustomersQuick,
            query: DEFAULT_METADATA_SELECT_LOCATION_LOAD_SEARCH_QUERY(),
            searchMap: (it) => ({
                label: it.LegalName + (it.AddressName ? " - " + it.AddressName : "")
                    + (it.CityName ? ", " + it.CityName : "")
                    + (getLookup('State')[it.StateID] ? " " + getLookup('State')[it.StateID] : "")
                    + (it.PostalCode ? " " + it.PostalCode : ""),
                value: it.CustomerID,
                metadata: it,
            })
        },
        CoBrokerID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (it) => ({
                label: it.LegalName,
                value: it.OrganizationID,
                metadata: it,
            })
        },
        OfficeID: {
            api: 'api/' + Resources.OfficesQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (it) => ({
                label: it.OfficeName,
                value: it.OfficeID
            })
        },
        ContactGroupID: {
            api: 'api/' + Resources.ContactGroupsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (it) => ({
                label: it.ContactGroupName,
                value: it.ContactGroupID
            })
        },
        DriverID: {
            api: 'api/' + Resources.DriversQuick,
            query: WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY({
                HasActiveEmployment: 1
            }),
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.DriverID,
                metadata: it
            })
        },
        CoDriverID: {
            api: 'api/' + Resources.DriversQuick,
            query: WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY({
                HasActiveEmployment: 1
            }),
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.DriverID,
                metadata: it
            })
        },
        TruckID: {
            api: 'api/' + Resources.TrucksQuick,
            query: WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY({
                NotSoldRetired: 1
            }),
            customizeList: (list) => {
                if (UseMultiSCAC === 1) {
                    return groupListBySCAC(list, 'Truck')
                } else {
                    return list
                        .map(it => ({
                            label: it['Truck' + "Number"],
                            value: it['Truck' + "ID"],
                            metadata: it
                        }))
                }
            }
        },
        PickingTrailerID: {
            api: 'api/' + Resources.TrailersQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            customizeList: (list) => {
                if (UseMultiSCAC === 1) {
                    return groupListBySCAC(list, 'Trailer')
                } else {
                    return list
                        .map(it => ({
                            label: it['Trailer' + "Number"],
                            value: it['Trailer' + "ID"],
                            metadata: it
                        }))
                }
            }
        },
        TrailerID: {
            api: 'api/' + Resources.TrailersQuick,
            query: WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY({
                NotSoldRetired: 1
            }),
            customizeList: (list) => {
                if (UseMultiSCAC === 1) {
                    return groupListBySCAC(list, 'Trailer')
                } else {
                    return list
                        .map(it => ({
                            label: it['Trailer' + "Number"],
                            value: it['Trailer' + "ID"],
                            metadata: it
                        }))
                }
            }
        },
        SecondTrailerID: {
            api: 'api/' + Resources.TrailersQuick,
            query: WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY({
                NotSoldRetired: 1
            }),
            customizeList: (list) => {
                if (UseMultiSCAC === 1) {
                    return groupListBySCAC(list, 'Trailer')
                } else {
                    return list
                        .map(it => ({
                            label: it['Trailer' + "Number"],
                            value: it['Trailer' + "ID"],
                            metadata: it
                        }))
                }
            }
        },
        ThirdTrailerID: {
            api: 'api/' + Resources.TrailersQuick,
            query: WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY({
                NotSoldRetired: 1
            }),
            customizeList: (list) => {
                if (UseMultiSCAC === 1) {
                    return groupListBySCAC(list, 'Trailer')
                } else {
                    return list
                        .map(it => ({
                            label: it['Trailer' + "Number"],
                            value: it['Trailer' + "ID"],
                            metadata: it
                        }))
                }
            }
        },
        CarrierID: {
            api: 'api/' + Resources.CarriersQuick,
            query: DEFAULT_METADATA_SELECT_LOCATION_LOAD_SEARCH_QUERY(),
            searchMap: (it) => ({
                label: it.LegalName,
                value: it.CarrierID,
                metadata: it
            })
        },
        StopID: {
            api: 'api/' + Resources.LocationsQuick,
            query: DEFAULT_METADATA_SELECT_LOCATION_LOAD_SEARCH_QUERY(),
            searchMap: (it) => ({
                label: it.LocationName + " - " + it.AddressName + " " + it.CityName + ", " +
                    it.StateAbbreviation + " " + it.PostalCode,
                value: it.LocationID,
                metadata: it
            })
        },
        CountryID: getLookup('Country', 'CountryID', 'CountryName'),
        LocationID: {
            api: 'api/' + Resources.LocationsQuick,
            query: DEFAULT_METADATA_SELECT_LOCATION_LOAD_SEARCH_QUERY(),
            searchMap: (it) => ({
                label: it.LocationName + " - " + it.AddressName + " " + it.CityName + ", " +
                    it.StateAbbreviation + " " + it.PostalCode,
                value: it.LocationID,
                metadata: it
            })
        },
        StopType: STOP_TYPES,
        StateID: getLookup('State', 'StateID', 'StateAbbreviation')
    }
}


export function clearLocationFields(fields) {
    return Object.values(fields).reduce((memo, it) => {
        it.value = ['StopByTypeID', 'ActualArrivalDate', 'ActualArrivalDateTime', 'ActualDepartureDate', 'ActualDepartureDateTime', 'Notes'].includes(it.name) ? it.value : "";

        memo[it.name] = it;
        return memo
    }, {});
}

export function convertMeasurements(fields = []) {
    const MeasureUnit = LocalStorage.get('user')?.Contact.MeasureUnit ?? 'in';
    const defaultWeightUnit = LocalStorage.get('user')?.Contact?.WeightUnit ?? 'lb'

    const isMetric = ['cm', 'm'].includes(MeasureUnit);
    const standardLengthMeasure = MeasureUnit;
    const standardWeightMeasure = isMetric ? 'kg' : 'lb';

    return fields.map(it => {
        const length = cnv(it.Length, getMeasurementUnitName(it.MeasureUnitID), standardLengthMeasure)
        const width = cnv(it.Width, getMeasurementUnitName(it.MeasureUnitID), standardLengthMeasure)
        const height = cnv(it.Height, getMeasurementUnitName(it.MeasureUnitID), standardLengthMeasure)
        const weight = cnv(it.Weight, getWeightUnitName(it.WeightUnit), defaultWeightUnit)

        const weightStandard = cnv(it.Weight, getWeightUnitName(it.WeightUnit), standardWeightMeasure).toFixed(2)

        const volume = length * width * height
        it.VolumeCalculated = <div>{Number(volume).toFixed(2)} {standardLengthMeasure}<sup>3</sup></div>

        const density = (!weightStandard || !volume) ? 0 : (Number(weightStandard) / (volume)).toFixed(2)

        it.DensityCalculated =
            <div>{density} {standardWeightMeasure + '/' + standardLengthMeasure}<sup>3</sup></div>

        it.LengthConverted = length.toFixed(2) + ' ' + standardLengthMeasure
        it.WidthConverted = width.toFixed(2) + ' ' + standardLengthMeasure
        it.HeightConverted = height.toFixed(2) + ' ' + standardLengthMeasure

        it.WeightConverted = weight.toFixed(2) + ' ' + defaultWeightUnit

        return it
    })
}

export function getStopIcon(StopByTypeID) {
    StopByTypeID = Number(StopByTypeID);
    switch (StopByTypeID) {
        case STOP_BY_TYPE_WEIGHT_STATION:
            return AwesomeWeightScaleIcon
        case STOP_BY_TYPE_HOME:
            return AwesomeHomeIcon;
        case STOP_BY_TYPE_FUEL_STATION:
            return AwesomeGasStationIcon;
        case STOP_BY_TYPE_REPAIR_SHOP:
            return AwesomeRepairShopIcon;
        case STOP_BY_TYPE_REST:
            return AwesomeBedIcon;
        case STOP_BY_TYPE_PERSONAL:
            return AwesomeUserIcon;
        case STOP_BY_TYPE_PARKING:
            return AwesomeParkingIcon;
        case STOP_BY_TYPE_SWITCH_ASSET:
            return AwesomeRepeatIcon;

        default:
            return AwesomeStopIcon
    }
}

export function setStopWarnings(stop) {
    const timeFormat = getUserTimeFormat();

    const isShippingNonStop = stop.LocationData?.value?.CustomShippingHours === WORKING_HOURS_NON_STOP;
    const isReceivingNonStop = stop.LocationData?.value?.CustomReceivingHours === WORKING_HOURS_NON_STOP;

    const dayIdx = !!stop.StopDate.value && (new Date(stop.StopDate.value)).getDay();
    const StopEndDayIdx = !!stop.StopEndDate.value && (new Date(stop.StopEndDate.value)).getDay();
    const isSelectedDayNonStop = !!stop.LocationData?.value?.ShippingHours?.[dayIdx]?.NonStop;
    const isSelectedStopEndDayNonStop = !!stop.LocationData?.value?.ShippingHours?.[StopEndDayIdx]?.NonStop;

    const areShippingHoursSet = !!stop.LocationData?.value?.ShippingHours?.length;
    const areReceivingHoursSet = !!stop.LocationData?.value?.ReceivingHours?.length;

    if (Number(stop.StopType.value) === STOP_TYPE_PICKUP && !isShippingNonStop && areShippingHoursSet) {
        // StopDateTime
        stop.StopTime.metadata.warnings.isNonWorkingDaySelected =
            stop.LocationData.value.ShippingHours?.[dayIdx]?.Active === "" && !!stop.StopDate.value;

        if (!stop.StopTime.metadata.warnings.isNonWorkingDaySelected && !isSelectedDayNonStop) {
            let selectedDayFromTime = stop.LocationData?.value?.ShippingHours?.[dayIdx]?.FromTime;
            let selectedDayToTime = stop.LocationData?.value?.ShippingHours?.[dayIdx]?.ToTime;

            let isBeforeWorkingHours = moment(selectedDayFromTime, 'HH:mm:ss').isAfter(moment(stop.StopTime.value, timeFormat));
            let isAfterWorkingHours = moment(stop.StopTime.value, timeFormat).isAfter(moment(selectedDayToTime, 'HH:mm:ss'));

            stop.StopTime.metadata.warnings.isNotWithinWorkingHours = isBeforeWorkingHours || isAfterWorkingHours;
        }

        // StopEndDateTime
        stop.StopEndTime.metadata.warnings.isNonWorkingDaySelected =
            stop.LocationData.value.ShippingHours?.[StopEndDayIdx]?.Active === "" && !!stop.StopEndDate.value;

        if (!stop.StopEndTime.metadata.warnings.isNonWorkingDaySelected && !isSelectedStopEndDayNonStop) {
            let selectedDayFromTime = stop.LocationData?.value?.ShippingHours?.[StopEndDayIdx]?.FromTime;
            let selectedDayToTime = stop.LocationData?.value?.ShippingHours?.[StopEndDayIdx]?.ToTime;

            let isBeforeWorkingHours = moment(selectedDayFromTime, 'HH:mm:ss').isAfter(moment(stop.StopEndTime.value, timeFormat));
            let isAfterWorkingHours = moment(stop.StopEndTime.value, timeFormat).isAfter(moment(selectedDayToTime, 'HH:mm:ss'));

            stop.StopEndTime.metadata.warnings.isNotWithinWorkingHours = isBeforeWorkingHours || isAfterWorkingHours;
        }
    } else if (Number(stop.StopType.value) === STOP_TYPE_PICKUP) {
        // to remove warnings when changing a stop
        stop.StopTime.metadata.warnings.isNonWorkingDaySelected = false;
        stop.StopEndTime.metadata.warnings.isNonWorkingDaySelected = false;
        stop.StopTime.metadata.warnings.isNotWithinWorkingHours = false;
        stop.StopEndTime.metadata.warnings.isNotWithinWorkingHours = false;
    }

    if (Number(stop.StopType.value) === STOP_TYPE_DELIVERY && !isReceivingNonStop && areReceivingHoursSet) {
        // StopDateTime
        stop.StopTime.metadata.warnings.isNonWorkingDaySelected =
            stop.LocationData.value.ReceivingHours?.[dayIdx]?.Active === "" && !!stop.StopDate.value;

        if (!stop.StopTime.metadata.warnings.isNonWorkingDaySelected && !isSelectedDayNonStop) {
            let selectedDayFromTime = stop.LocationData?.value?.ReceivingHours?.[dayIdx]?.FromTime;
            let selectedDayToTime = stop.LocationData?.value?.ReceivingHours?.[dayIdx]?.ToTime;

            let isBeforeWorkingHours = moment(selectedDayFromTime, 'HH:mm:ss').isAfter(moment(stop.StopTime.value, timeFormat));
            let isAfterWorkingHours = moment(stop.StopTime.value, timeFormat).isAfter(moment(selectedDayToTime, 'HH:mm:ss'));

            stop.StopTime.metadata.warnings.isNotWithinWorkingHours = isBeforeWorkingHours || isAfterWorkingHours;
        } else {
            stop.StopTime.metadata.warnings.isNotWithinWorkingHours = false;
        }

        // StopEndDateTime
        stop.StopEndTime.metadata.warnings.isNonWorkingDaySelected =
            stop.LocationData.value.ReceivingHours?.[StopEndDayIdx]?.Active === "" && !!stop.StopEndDate.value;

        if (!stop.StopEndTime.metadata.warnings.isNonWorkingDaySelected && !isSelectedStopEndDayNonStop) {
            let selectedDayFromTime = stop.LocationData?.value?.ReceivingHours?.[StopEndDayIdx]?.FromTime;
            let selectedDayToTime = stop.LocationData?.value?.ReceivingHours?.[StopEndDayIdx]?.ToTime;

            let isBeforeWorkingHours = moment(selectedDayFromTime, 'HH:mm:ss').isAfter(moment(stop.StopEndTime.value, timeFormat));
            let isAfterWorkingHours = moment(stop.StopEndTime.value, timeFormat).isAfter(moment(selectedDayToTime, 'HH:mm:ss'));

            stop.StopEndTime.metadata.warnings.isNotWithinWorkingHours = isBeforeWorkingHours || isAfterWorkingHours;
        } else {
            stop.StopEndTime.metadata.warnings.isNotWithinWorkingHours = false;
        }
    } else if (Number(stop.StopType.value) === STOP_TYPE_DELIVERY) {
        // to remove warnings when changing a stop
        stop.StopTime.metadata.warnings.isNonWorkingDaySelected = false;
        stop.StopEndTime.metadata.warnings.isNonWorkingDaySelected = false;
        stop.StopTime.metadata.warnings.isNotWithinWorkingHours = false;
        stop.StopEndTime.metadata.warnings.isNotWithinWorkingHours = false;
    }

    return stop;
}

export function mergeStops(multiStopsData, stopBysData) {
    let stopsCombined;
    let multiStops;
    let stopBys;

    if (multiStopsData) {
        multiStops = multiStopsData;
    } else {
        // multiStops = getProp(resource, 'data.load/multistops', []);
    }

    if (stopBysData) {
        stopBys = stopBysData;
    } else {
        // stopBys = getProp(resource, 'data.load/stopby', []);
    }

    if (!multiStops.length && !stopBys.length) { // create load
        return [
            getCombinedStopObject({StopType: STOP_TYPE_PICKUP, IsSelected: true}, 0),
            getCombinedStopObject({StopType: STOP_TYPE_DELIVERY}, 1)
        ];
    }

    const unsortedMergedStops = [...multiStops, ...stopBys];

    const mergedStops = unsortedMergedStops.sort(function (a, b) {
        return a.StopOrder - b.StopOrder;
    });

    let stopsIndex = 0;
    let stopBysIndex = 0;
    stopsCombined = mergedStops.reduce((memo, it) => {
        let index;

        if (!it?.StopType) {
            it.Multistop = it.Location;

            index = stopBysIndex;
            stopBysIndex++;
        } else {
            index = stopsIndex;
            stopsIndex++;

            if (!it.StopType) {
                it.StopType = !index ? STOP_TYPE_PICKUP : STOP_TYPE_DELIVERY
            }
        }

        memo.push(getCombinedStopObject(it, index));

        return memo;
    }, []);

    if (stopsCombined.length < 2) {
        for (let i = stopsCombined.length; i < MINIMUM_STOP_NUMBER; i++) {
            stopsCombined.push(getCombinedStopObject({StopType: !i ? STOP_TYPE_PICKUP : STOP_TYPE_DELIVERY}, i));
        }
    }

    const firstUncompletedIndex = stopsCombined.findIndex(it => !it.IsStopCompleted);
    stopsCombined[firstUncompletedIndex > -1 ? firstUncompletedIndex : stopsCombined.length - 1].IsSelected = true;

    return stopsCombined;
}

export function getContactDialogFields(translate) {
    return {
        FirstName: new Field('FirstName', '', [''], false, 'select-search'),
        LastName: new Field('LastName', '', [''], false, 'select-search'),
        Email: new Field("Email", '', [], false, "text", {
            render: (it) => it.Email ?
                <TextCopy
                    text={it.Email}
                    copyText={translate("text.copy_to_clipboard")}
                    copiedText={translate("text.copied")}
                /> :
                null
        }),
        Phone: new Field("Phone", '', [], false, "text", {
            render: (it) => (it.Phone) ?
                <TextCopy
                    text={it.Phone}
                    copyText={translate("text.copy_to_clipboard")}
                    copiedText={translate("text.copied")}
                /> :
                null
        }),
        DepartmentID: new Field('DepartmentID', '', [''], false, 'select-search'),
        Notes: new Field("Notes", '', [], false, "textarea", {limitWidth: true})
    }
}
