import React, {useState} from 'react'
import Layout from "../../../common/components/layout";
import NavResponsive from "../../../common/components/nav-responsive";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import GrossReportTab from "./gross-report-tab";
import {checkPerm} from "../../../common/util/util-helpers";
import Resources from "../../../data/services/resources";
import {READ_PERM} from "../../../util/util-constants";
import TrucksGrossReportTab from "./trucks-gross-report-tab";
import LateLoadsReportTab from "./late-loads-report-tab";
import Page from "../../../common/components/layout/layout-components/page";
const DispatchReportsView = (props) => {

    function getTabs() {
        return [
            {
                name: 'GrossReport',
                resource: "gross-report",
                current: true,
                hasPerm: checkPerm(Resources.UnitReport, READ_PERM)
            },
            {
                name: 'TrucksGrossReport',
                resource: "truck-gross-report",
                current: false,
                hasPerm: checkPerm(Resources.TrucksGrossReport, READ_PERM)
            },
            {
                name: 'LateLoadsReport',
                resource: "late-loads-report",
                current: false,
                hasPerm: checkPerm(Resources.LateLoadsReport, READ_PERM)
            },
        ]
    }

    function handleTabChange(resource) {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }

    const [tabs, setTabs] = useState(getTabs())
    const currentTab = tabs.find(tab => tab.current)

    return (
        <Layout {...props}>
            <Page>
                <PageHeader
                    title={props.translate("page.heading.reports")}
                    titleClass="mb-5 text-2xl ml-4"
                />
                <div className="mb-5">
                    <NavResponsive
                        tabs={tabs}
                        onTabChange={handleTabChange}
                        translate={props.translate}
                    />
                </div>

                {currentTab.resource === 'gross-report' && (
                    <GrossReportTab
                        translate={props.translate}
                        pagePath={"gross-report"}
                    />
                )}

                {currentTab.resource === 'truck-gross-report' && (
                    <TrucksGrossReportTab
                        translate={props.translate}
                        pagePath={"trucks-gross-report"}
                    />
                )}

                {currentTab.resource === 'late-loads-report' && (
                    <LateLoadsReportTab
                        translate={props.translate}
                        pagePath={"late-loads-report"}
                    />
                )}
            </Page>
        </Layout>
    )
}

export default DispatchReportsView