import React, {useEffect, useState} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import LocalStorage from '../../../util/localStorage'
import {useDispatch, useSelector} from 'react-redux'
import {DEFAULT_CRUD_STATE, DEFAULT_METADATA_SELECT_SEARCH_QUERY, UPDATE_PERM} from '../../../util/util-constants'
import Resources from '../../../data/services/resources'
import {getSecondResource} from '../../../data/actions/secondResource'
import PayDateFilter from '../../accounting/carrier-pay/carrier-pay-date-filter'
import {
    getDefaultTableOptions,
    mileageToErrorMessage,
    openInNewTab,
    returnMileage,
    saveTableColumns
} from '../../../common/util/util-helpers'
import EyeIcon from '@heroicons/react/24/outline/EyeIcon'
import {checkPerm, classNames, getLookup} from '../../../common/util/util-helpers'
import {genericMoneyFormatter} from '../../../common/util/util-vanilla'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import Tippy from '@tippyjs/react'
import {getThirdResource} from "../../../data/actions/thirdResource";
import {showModal} from "../../../data/actions/ui";
import {ArrowPathIcon, ArrowsPointingOutIcon, IdentificationIcon} from "@heroicons/react/24/outline";
import {ArrowsPointingInIcon} from "@heroicons/react/20/solid";
import {numberWithCommas} from '../../../util/util-formaters'
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ReactPortal from "../../../common/components/layout/layout-components/react-portal";
import ModalDefault from "../../../common/components/modal/modal-default";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import Badge from "../../../common/components/badge";
import InfoParagraph from "../../../common/components/info-paragraph";
import CellLink from "../../../common/components/resource-table/table-components/cell-link";
import Tooltip from "../../../common/components/tooltip";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import ResourceTable from "../../../common/components/resource-table";
import {excludeFields} from "../../../common/util/util-fields";
import moment from "moment";

export default function GrossReportTab({
                                           translate,
                                           pagePath
                                       }) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch()
    const secondResource = useSelector((state) => state.secondResource)
    const thirdResource = useSelector((state) => state.thirdResource)
    const isLoading = secondResource.isLoading
    const isLoadingLoads = thirdResource.isLoading
    const data = secondResource?.data?.list ?? []


    const dataTotals = secondResource?.data ?? []
    const dataLoads = thirdResource?.data?.list ?? []
    const TotalPay = secondResource?.data?.TotalPay ?? 0

    /** Const
     ================================================================= */
    let rpmTotals = 0;
    let rpmOverriddenTotals = 0;
    let numberOfItems = data.length;
    let uniqueDrivers = {};
    data.forEach(item => {
        if (item.Amount && item.TotalMiles) {
            rpmTotals = rpmTotals + Number((item.Amount / item.TotalMiles).toFixed(2));
        }

        if (item.CalculationPrice && item.TotalMiles) {
            rpmOverriddenTotals = rpmOverriddenTotals + Number((item.CalculationPrice / item.TotalMiles).toFixed(2));
        }

        if (item.DriverID) {
            uniqueDrivers[item.DriverID] = item.DriverID;
        }
    })

    let rpmAverage = (rpmTotals / numberOfItems).toFixed(2);
    let rpmOverriddenAverage = (rpmOverriddenTotals / numberOfItems).toFixed(2);


    if (isNaN(rpmAverage)) {
        rpmAverage = 0
    }

    if (isNaN(rpmOverriddenAverage)) {
        rpmOverriddenAverage = 0
    }

    const uniqueDriversCount = Object.keys(uniqueDrivers).length;

    /** Helpers
     ================================================================= */
    const renderDriverNameField = (item, name) => {
        if (!item) return null;
        return (
            <button
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    checkPerm(Resources.DriverInfo, UPDATE_PERM) && openInNewTab(`/drivers/info/${item.DriverID}`);
                }}
                className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
            >
                {item[name]}
            </button>
        )
    }

    const handleCustomersClick = (item) => {
        dispatch(showModal('ViewCustomerCard', item));
    }

    const getFields = () => {
        return {
            FirstName: new Field('FirstName', '', [''], false, 'text', {
                addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                render: (item) => renderDriverNameField(item, "FirstName")
            }),
            LastName: new Field('LastName', '', [''], false, 'text', {
                addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                render: (item) => renderDriverNameField(item, "LastName")
            }),
            DriverPositionTypeID: new Field('DriverPositionTypeID', '', [''], false, 'custom', {
                label: "DriverPositionType",
                render: (it) => {
                    return getLookup('DriverPositionType', 'DriverPositionTypeID', 'DriverPositionType')[it.DriverPositionTypeID]
                }
            }),
            DriverSettlementTemplate: new Field('DriverSettlementTemplate', '', [''], false, 'text', {
                label: "DriverSettlementTemplate"
            }),
            PrimaryDispatch: new Field('PrimaryDispatch', '', [''], false, 'text'),
            Amount: new Field('Amount', '', [''], false, 'money', {
                label: "DriverPayAmount",
            }),
            CalculationPrice: new Field('CalculationPrice', '', [''], false, 'money', {
                label: "CalculationPrice"
            }),
            TotalMiles: new Field('TotalMiles', '', [''], false, 'float', {
                label: "LoadedMiles",
            }),
            EmptyMiles: new Field('EmptyMiles', '', [''], false, 'float', {
                label: "EmptyMiles",
            }),
            CalculatedMiles: new Field('CalculatedMiles', '', [''], false, 'float', {
                label: "CalculatedMiles",
                render: (item) => {
                    if (!item.isFooterData) {
                        return numberWithCommas(parseInt(item.TotalMiles) + parseInt(item.EmptyMiles))
                    } else {
                        return numberWithCommas(dataTotals?.TotalTotalTotalMiles)
                    }
                }
            }),
            RPM: new Field('RPM', '', [], false, 'float', {
                render: (item) => {
                    if (!item.isFooterData) {
                        return isNaN(item.Amount / item.TotalMiles)
                            ? 0
                            : (item.Amount / item.TotalMiles).toFixed(2)
                    } else {
                        return rpmAverage
                    }
                }
            }),
            RPMOverridden: new Field('RPMOverridden', '', [], false, 'float', {
                render: (item) => {
                    if (!item.isFooterData) {
                        return (isNaN(item.CalculationPrice / item.TotalMiles) ? 0 : (item.CalculationPrice / item.TotalMiles).toFixed(2))
                    } else {
                        return rpmOverriddenAverage
                    }
                }
            }),
            LoadCount: new Field('LoadCount', '', [''], false, 'integer'),
            LoadRefs: new Field('LoadRefs', '', [''], false, 'float', {
                render: (it) =>
                    <Tooltip
                        content={<div className={"text-break"}>{it.LoadRefs}</div>}
                    >
                        <div dir="rtl" className={"max-w-full truncate"}>{it.LoadRefs}</div>
                    </Tooltip>

            })
        }
    }

    const getQueryFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            StartDate: new Field('StartDate', moment().subtract('month', 1).format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {}, {}),
            EndDate: new Field('EndDate', moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {}, {}),
            DateType: new Field('DateType', 1, [''], false, 'button-group', {
                data: {1: 'Pickup', 2: 'Delivery'},
                addClass: 'w-full',
                addButtonClass: 'uppercase font-semibold',
                addContainerClass: 'col-span-2',
                labelType: 'stack',
                activeFilterLabel: "filter_pickup_date"
            }, {}),
            GrossMoreThan: new Field('GrossMoreThan', '', [''], false, 'integer'),
            GrossLessThan: new Field('GrossLessThan', '', [''], false, 'integer'),
            PayMoreThan: new Field('PayMoreThan', '', [''], false, 'integer'),
            PayLessThan: new Field('PayLessThan', '', [''], false, 'integer'),
            DriverIDs: new Field('DriverIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                all: true
            }),
            DriverPositionTypeID: new Field('DriverPositionTypeID', '', [''], false, 'select', {addContainerClass: 'col-span-full'},
                {
                    values: getLookup('DriverPositionType', 'DriverPositionTypeID', 'DriverPositionType'),
                    menuPlacement: 'top'
                }),
            DriverSettlementTemplateID: new Field('DriverSettlementTemplateID', '', [], false, 'select-search', {}, {menuPlacement: 'top'}),
            DispatchContactID: new Field('DispatchContactID', '', [], false, 'select-search', {}, {menuPlacement: 'top'})
        }
    }

    const getLoadsFields = () => {
        return {
            LoadNumber: new Field('LoadNumber', '', [''], false, 'custom', {
                render: (it) => {
                    return (
                        <CellLink
                            to={`/loads/info/` + it.LoadID}
                            openInNewTab={true}
                        >
                            <div>{it.LoadNumber}</div>
                        </CellLink>
                    )
                }
            }),
            Customer: new Field('Customer', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <CellButton
                            onClick={() => handleCustomersClick(item)}
                        >

                            <div>{item.Customer}</div>
                        </CellButton>
                    )
                }
            }),
            PickupDate: new Field('PickupDate', '', [''], false, 'datetime'),
            Pickup: new Field('Pickup', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <div>{item.PickupCityName}, {item.PickupState} {item.PickupPostalCode}</div>
                    )
                }
            }),
            DestinationDate: new Field('DestinationDate', '', [''], false, 'datetime'),
            Destination: new Field('Destination', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <div>{item.DestinationCityName}, {item.DestinationState} {item.DestinationPostalCode}</div>
                    )
                }
            }),
            EmptyMiles: new Field('EmptyMiles', '', [''], false, "float", {
                render: (item) => <div className={"text-right"}>{returnMileage(item.EmptyMiles)}</div>
            }),
            TotalMiles: new Field('TotalMiles', '', [''], false, "float", {
                render: (item) => <div
                    className={"text-right"}>{mileageToErrorMessage(returnMileage(item.TotalMiles), translate)}</div>
            }),
            Price: new Field('Price', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.Price)}</div>,
                colFilter: true
            }, {icon: false}),
            CalculationPrice: new Field('CalculationPrice', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.CalculationPrice)}</div>,
                colFilter: true
            }, {icon: false}),
            DriverPayAmount: new Field('DriverPayAmount', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.DriverPayAmount)}</div>,
                colFilter: true
            }, {icon: false}),
            IsPayed: new Field('IsPayed', '', [''], false, 'checkbox', {})
        }
    }

    const getResourceName = () => {
        return Resources.UnitReport
    }

    const getQuery = () => {
        return {
            ...DEFAULT_CRUD_STATE,
            ...FieldsManager.getFieldKeyValues(queryFilterFields),
            sort: sort,
            sortBy: sortBy
        }
    }

    const getTableOptions = (pagePath, translate) => {
        const tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: false
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        return getDefaultTableOptions(getFields(), tablePageDefaults, pagePath, translate)
    }

    const getLoadsTableOptions = (pagePath, translate) => {
        const tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: false
            },
            columns: {
                PickupDate: {
                    minWidth: 220,
                },
                Pickup: {
                    minWidth: 220,
                },
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        return getDefaultTableOptions(getLoadsFields(), tablePageDefaults, pagePath, translate)
    }

    /** State
     ================================================================= */
    const [queryFilterFields, setQueryFilterFields] = useState(getQueryFields())
    const [isLoadsModalOpen, setIsLoadsModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [tableOptions, setTableOptions] = useState(getTableOptions(pagePath, translate))
    const [tableOptionsLoads, setTableOptionsLoads] = useState(getLoadsTableOptions(pagePath, translate))
    const [isTableFullScreen, setIsTableFullScreen] = useState(false)
    const [isTableLoadsFullScreen, setIsTableLoadsFullScreen] = useState(false)
    const [sort, setSort] = useState("ASC")
    const [sortBy, setSortBy] = useState("FirstName")

    const metadata = {
        DispatchContactID: {
            api: "api/" + Resources.ContactsQuick,
            query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {IsDispatcher: 1}),
            searchMap: (item) => ({
                value: item.ContactID,
                label: item.FirstName + " " + item.LastName,
                Contact: item
            })
        },
        DriverSettlementTemplateID: {
            api: "api/" + Resources.DriverSettlementsTemplate,
            query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {}),
            searchMap: (item) => ({
                value: item.DriverSettlementTemplateID,
                label: item.DriverSettlementTemplate
            })
        },
        DriverIDs: {
            api: 'api/' + Resources.DriversQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.DriverID,
                label: item.FirstName + " " + item.LastName
            })
        },
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: getQuery()
        }))
    }

    const fetchLoadsData = (item) => {
        let DriverID = item?.DriverID
        let LoadIDs = item?.LoadIDs.split(',').map((it) => {
            return it
        })

        dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: Object.assign({}, getQuery(), {LoadIDs: LoadIDs, DriverID: DriverID})
        }))
    }

    const downloadExcel = () => {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: Object.assign({format: 'EXCEL', name: 'gross_report' + currentDate() + '.xlsx'}, getQuery())
        }))
    }

    const downloadExcelLoads = () => {
        let DriverID = selectedItem?.DriverID
        let LoadIDs = selectedItem?.LoadIDs.split(',').map((it) => {
            return it
        })

        dispatch(download({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'gross_report_loads' + currentDate() + '.xlsx'
            }, getQuery(), {LoadIDs: LoadIDs, DriverID: DriverID})
        }))
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [sort, sortBy, queryFilterFields])

    /** UI events
     ================================================================= */
    const handleFilterInputChange = (name, value) => {
        let queryFieldsUpdate = FieldsManager.updateField(queryFilterFields, name, value);

        if (name === 'StartDate') {
            queryFieldsUpdate.EndDate.props.minDate = value
        }
        if (name === 'EndDate') {
            queryFieldsUpdate.StartDate.props.maxDate = value
        }

        if (name === 'DateType') {
            queryFieldsUpdate.DateType.metadata.activeFilterLabel = value === "1" ? "filter_pickup_date" : "filter_delivery_date"
        }

        setQueryFilterFields(queryFieldsUpdate)
    }

    const handleOpenLoadsModal = (it) => {
        setSelectedItem(it)
        setIsLoadsModalOpen(true)
        fetchLoadsData(it)
    }

    const handleClearFiltersClick = () => {
        let queryFilterFieldsUpdate = queryFilterFields;

        queryFilterFieldsUpdate = Object.values(queryFilterFieldsUpdate).reduce((memo, it) => {
            it.value = "";
            memo[it.name] = it;

            return memo;
        }, {});

        setQueryFilterFields(queryFilterFieldsUpdate);
    }

    const handleFullScreenToggle = () => {
        setIsTableFullScreen(!isTableFullScreen)
    }

    const handleLoadsFullScreenToggle = () => {
        setIsTableLoadsFullScreen(!isTableLoadsFullScreen)
    }

    const setOptionsLoads = (options) => {
        setTableOptionsLoads(options)
        saveTableColumns(pagePath + 'Loads', options)
    }
    const setOptions = (options) => {
        setTableOptions(options)
        saveTableColumns(pagePath, options)
    }

    const handleUpdateSort = (sortByTmp) => {
        setSort((sortByTmp === sortBy) ? (sort === "ASC" ? "DESC" : "ASC") : "ASC")
        setSortBy(sortByTmp)
    };

    /** Render
     ================================================================= */
    return (
        <React.Fragment>
            <div
                className={"flex items-center mb-4"}><span
                className={"font-bold"}>{!isLoading ? ("Total driver pay: " + genericMoneyFormatter(TotalPay)) : ""}</span>
                <div className="flex max-w-fit mx-4 h-8 gap-4">
                    <InfoParagraph type="info">
                        <div className={"text-sm"}>
                            {translate("text.totalAreCalculated")}
                        </div>
                    </InfoParagraph>

                    <Badge type="outline">
                        {!isLoading ?
                            (
                                <div className="flex items-center gap-1.5">
                                    <IdentificationIcon className="w-5 h-5"/>
                                    <span>{translate("text.driver_count")}:</span> <span
                                    className="font-bold">{uniqueDriversCount}</span>
                                </div>
                            ) : ""}
                    </Badge>
                </div>
            </div>

            <div className={"sm:flex justify-between"}>
                <ActiveFilters
                    addClass={"mb-2"}
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    persistFilters={['DateType']}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />

                <Tippy content={translate('text.download_excel')}>
                    <button
                        className="btn-icon"
                        onClick={downloadExcel}
                    >
                        <DocumentArrowDownIcon className="w-6 h-6"/>
                    </button>
                </Tippy>
            </div>
            <TableCard
                className={
                    classNames(
                        "",
                        isTableFullScreen
                            ? "bg-inverse rounded-card fixed inset-1 -top-1 z-50"
                            : "flex flex-col h-dialog-body md:bg-inverse md:border border-tm-gray-300 md:shadow-card"
                    )}
            >
                <div>
                    <TableFilters
                        forceDialog
                        hideLimit
                        filterFields={excludeFields(queryFilterFields, ['StartDate', 'EndDate', 'DateType'])}
                        handleInputChange={handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={fetchData}
                        selects={metadata}
                        onFullScreenToggle={handleFullScreenToggle}
                        isTableFullScreen={isTableFullScreen}
                        isLoading={isLoading}
                        customHtml={
                            <PayDateFilter
                                queryFields={queryFilterFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    setQueryFilterFields(queryFieldsUpdate)
                                }}
                                onQueryChange={handleFilterInputChange}
                            />
                        }
                    />
                </div>

                <ResourceTable
                    tableKey={'DriverID'}
                    data={data}
                    fields={getFields()}
                    options={tableOptions}
                    isLoading={isLoading}
                    translate={translate}
                    sort={sort}
                    sortBy={sortBy}
                    onSortChange={handleUpdateSort}
                    saveTableOptions={setOptions}

                    maxHeightClass={"max-h-[calc(100vh-23rem)]"}

                    onRowClick={handleOpenLoadsModal}

                    actions={[
                        {
                            action: handleOpenLoadsModal,
                            icon: EyeIcon,
                            visible: () => true,
                            title: translate('text.show_details'),
                        }]
                    }
                    tfoot={!!data.length && {
                        isFooterData: true,
                        CalculationPrice: dataTotals?.TotalCalculationPrice,
                        Amount: dataTotals?.TotalAmount,
                        EmptyMiles: dataTotals?.TotalEmptyMiles,
                        LoadCount: dataTotals?.TotalTotalLoadCount,
                        TotalMiles: dataTotals?.TotalTotalMiles,
                        CalculatedMiles: 'isFooter',
                    }}
                />

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    title={'No matching records found'}
                    className={"pb-12 pt-16 px-6"}
                />
            </TableCard>

            <ModalDefault
                show={isLoadsModalOpen}
                widthClass={"max-w-full"}
                title={translate("text.LoadsForDriver", [selectedItem?.FirstName + " " + selectedItem?.LastName])}
                closeButtonLabel={translate("btn.close")}
                onClose={() => {
                    setIsLoadsModalOpen(false)
                }}
            >
                <ReactPortal
                    isActive={isTableLoadsFullScreen}
                    parent={document.getElementById('headlessui-portal-root')}
                >
                    <TableCard
                        className={
                            classNames(
                                "md:bg-inverse rounded-card md:border border-tm-gray-300 md:shadow-card",
                                isTableLoadsFullScreen
                                    ? "fixed inset-1 z-50"
                                    : "relative"
                            )}
                    >

                        <div className={"flex justify-end space-x-3 m-4"}>
                            <div className={"-mr-1"}>
                                <Tippy content={translate('text.download_excel')}>
                                    <button
                                        className="btn-icon"
                                        onClick={downloadExcelLoads}
                                    >
                                        <DocumentArrowDownIcon className="w-6 h-6"/>
                                    </button>
                                </Tippy>
                            </div>

                            <div>
                                <button className={
                                    classNames(
                                        "btn btn-header",
                                        isTableLoadsFullScreen ? "bg-primary text-primary-contrast" : undefined
                                    )
                                }
                                        onClick={handleLoadsFullScreenToggle}
                                >
                                    {!isTableLoadsFullScreen && (
                                        <ArrowsPointingOutIcon className="w-5 h-5"/>
                                    )}

                                    {!!isTableLoadsFullScreen && (
                                        <ArrowsPointingInIcon className="w-5 h-5"/>
                                    )}

                                </button>
                            </div>

                            <button
                                className={"btn btn-header z-10"}
                                onClick={() => {
                                    fetchLoadsData(selectedItem)
                                }}
                            >
                                <ArrowPathIcon className={
                                    classNames(
                                        "w-5 h-5",
                                        isLoadingLoads ? "animate-spin" : undefined
                                    )
                                }/>
                            </button>
                        </div>

                        <ResourceTable
                            tableKey={'LoadID'}
                            data={dataLoads}
                            fields={getLoadsFields()}
                            options={tableOptionsLoads}
                            saveTableOptions={setOptionsLoads}
                            isLoading={isLoadingLoads}
                            translate={translate}

                            onRowClick={(it) => checkPerm(Resources.LoadInfo, UPDATE_PERM) && openInNewTab('/loads/info/' + it.LoadID)}
                            maxHeightClass={
                                classNames(
                                    isTableLoadsFullScreen ? "max-h-[calc(100vh-9.5rem)]" : undefined,
                                    !isTableLoadsFullScreen && 'max-h-[calc(100vh-29rem)]'
                                )
                            }
                        />
                    </TableCard>
                </ReactPortal>
            </ModalDefault>
        </React.Fragment>
    )
}
