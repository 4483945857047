import React, {Component} from 'react'
import {connect} from 'react-redux'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {showGlobalModal, showModal} from '../../../data/actions/ui'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getDocumentTypesFor,
    getLookup,
    getProp,
    getTableProfile,
    getUserNotification,
    JSONParseFix,
    mileageToErrorMessage,
    minutesToDayHourMinutes,
    resourceIsLoaded,
    returnMileage,
    returnOffsetAndPagination,
    saveTableColumns,
    verifyTableOptions,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import {
    ArchiveBoxIcon,
    CheckCircleIcon,
    DocumentIcon,
    EyeIcon,
    Square2StackIcon,
    TrashIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';
import {BellIcon as BellSolidIcon, ChevronUpIcon as ChevronUpSolidIcon, RssIcon} from '@heroicons/react/20/solid';
import StarSolidIcon from '@heroicons/react/20/solid/StarIcon'
import ScaleIcon from '@heroicons/react/24/outline/ScaleIcon'
import ArrowTrendingUpIcon from '@heroicons/react/24/outline/ArrowTrendingUpIcon'
import ArrowTrendingDownIcon from '@heroicons/react/24/outline/ArrowTrendingDownIcon'
import TruckIcon from '@heroicons/react/24/outline/TruckIcon'
import CubeIcon from '@heroicons/react/24/outline/CubeIcon'
import CubeTransparentIcon from '@heroicons/react/24/outline/CubeTransparentIcon'
import {download, uploadDocument} from '../../../data/actions/download'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import CalendarIcon from '@heroicons/react/20/solid/CalendarIcon'
import FireSolidIcon from '@heroicons/react/20/solid/FireIcon'
import FireIcon from '@heroicons/react/24/outline/FireIcon'
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon'
import {
    CREATE_PERM,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    LINE_OF_BUSINESS_PRIMARY_CARRIER,
    LINE_OF_BUSINESS_PRIMARY_DRIVER,
    LOAD_STATUS_DELIVERED,
    LOAD_STATUS_DISPATCHED,
    LOAD_STATUS_IN_TRANSIT,
    LOAD_STATUS_RESERVED,
    READ_PERM,
    SENSITIVE_LOAD_LIST_FIELDS,
    TABLE_OPTIONS_SUF,
    UPDATE_PERM
} from '../../../util/util-constants'
import {currentDate, toFrontDate} from '../../../common/util/util-dates'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import {numberWithCommas} from '../../../util/util-formaters'
import PresentationChartLineIcon from '@heroicons/react/24/outline/PresentationChartLineIcon'
import FunnelIcon from '@heroicons/react/20/solid/FunnelIcon'
import CalculatorIcon from '@heroicons/react/20/solid/CalculatorIcon'
import LoadPlaningModalBody from '../load-view/load-sections/dialog/load-planing-modal'
import PayDateFilter from "../../accounting/carrier-pay/carrier-pay-date-filter";
import moment from 'moment'
import {createDialogResource} from '../../../data/actions/dialogResource'
import MapPinIcon from "@heroicons/react/24/outline/MapPinIcon";
import ShareIcon from "@heroicons/react/20/solid/ShareIcon";
import {
    createResourceDiff,
    deleteResourceDiff,
    getResourceDiff,
    updateResourceDiff
} from "../../../data/actions/resourceDiff";
import PopOver from "../../../common/components/popover";
import Modal from "../../../common/components/modal";
import TableProfilesDialog from "../../../common/components/resource-table/table-components/table-profiles-dialog";
import SlideOver from "../../../common/components/slideover";
import TableColumnFilters from "../../../common/components/resource-table/table-components/table-column-filters";
import {excludeFields, fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import CellLink from "../../../common/components/resource-table/table-components/cell-link";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import StatusProgressTable from "../../../common/components/resource-table/table-components/status-progress-table";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import WidgetStats from "../../../common/components/widgets/widget-stats";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableTagManager from "../../../common/components/resource-table/table-components/table-tag-manager";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import Tooltip from "../../../common/components/tooltip";
import ResourceTable from "../../../common/components/resource-table";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Layout from "../../../common/components/layout";
import CardSubTitle from "../../../common/components/card/card-sub-title";
import FieldCheckbox from "../../../common/components/fields/field-checkbox";
import ModalDefault from "../../../common/components/modal/modal-default";
import {DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS} from "../../../common/util/util-consts";
import FileViewer from "../../../common/components/file-viewer/components";
import ArrowsRightLeftIcon from "@heroicons/react/20/solid/ArrowsRightLeftIcon";
import PostLocationUpdate from "../../../common/components/modal/post-location-update";
import TableFilterProfiles
    from "../../../common/components/resource-table/table-components/table-filter-profiles";


export const NeedsAttentionSolidIcon = ({className = 'relative z-12 h-5 w-5 cursor-pointer text-red-600 animate-pulse'}) => {
    return (
        <FireSolidIcon
            className={classNames(className)}/>
    )
}

export const MapPinIconPulse = ({className = 'relative z-12 h-5 w-5 cursor-pointer text-blue-600 animate-pulse'}) => {
    return (
        <MapPinIcon
            className={classNames(className)}/>
    )
}

export const RssIconPulse = ({className = 'relative z-12 h-5 w-5 cursor-pointer text-blue-600 animate-pulse'}) => {
    return (
        <RssIcon
            className={classNames(className)}/>
    )
}

export const ShareIconPulse = ({className = 'relative z-12 h-5 w-5 cursor-pointer text-green-600 opacity-50'}) => {
    return (
        <ShareIcon
            className={classNames(className)}/>
    )
}

const COLUMN_FILTER_FLAG_FIELD = 'ColumnFilters';

const CheckboxCell = ({value}) => {
    return value ? <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/>
        : <XMarkIcon className="w-5 h-5 text-tm-gray-400 inline"/>
}

class Load extends Component {
    constructor(props) {
        super(props)

        this.pagePath = getTableProfile(this.props.location.pathname.substring(1)) ?? this.props.location.pathname.substring(1);

        this.pageSettingsName = 'page-settings-' + this.pagePath

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                hasMenu: true,
                canAdjustWidth: true,

            },
            columns: { // for performance table mode 20+ limit
                LoadNumber: {
                    minWidth: 150,
                    subColumns: [{'Labels': {show: true, name: 'Labels'}}]
                },
                IsBroker: {
                    show: false,
                    subColumns: [{'Carrier': {show: false, name: 'Carrier'}}]
                },
                LoadStatus: {minWidth: 150},
                LoadTypeID: {minWidth: 80},
                LoadSubTypeID: {minWidth: 130},
                Customer: {minWidth: 180},
                CustomerReferenceNumber: {minWidth: 175},
                Truck: {minWidth: 150},
                Trailer: {minWidth: 150},
                Labels: {minWidth: 150, show: false},
                TrailerType: {minWidth: 130, show: false},
                SecondTrailer: {minWidth: 150, show: false},
                LabelsCustomer: {minWidth: 215}
            }
        }
        const newLocalStorage = LocalStorage.get(this.pagePath + "_test" + TABLE_OPTIONS_SUF);
        const existingLocalsStorage = LocalStorage.get(this.pagePath + TABLE_OPTIONS_SUF);
        let tableOptions;

        if (newLocalStorage) {
            tableOptions = verifyTableOptions(this.getFields(), newLocalStorage, this.props.translate);
            LocalStorage.remove(this.pagePath + TABLE_OPTIONS_SUF);
            LocalStorage.remove(this.pagePath + "_test" + TABLE_OPTIONS_SUF);
            saveTableColumns(this.pagePath, tableOptions)
        } else if (existingLocalsStorage) {
            tableOptions = verifyTableOptions(this.getFields(), existingLocalsStorage, this.props.translate);
        } else {
            tableOptions = getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate);
        }

        tableOptions.behaviour.hasVirtualRows = true;

        if (tableOptions.behaviour.hasMenu === undefined) {
            tableOptions.behaviour.hasMenu = true;
            tableOptions.behaviour.canAdjustWidth = true;
        }

        if (tableOptions.style.isGPUAccelerated !== false) {
            tableOptions.style.isGPUAccelerated = true;
        }

        if (tableOptions?.style?.isGPUAccellerated !== undefined) {
            delete tableOptions.style.isGPUAccellerated;
        }

        if (tableOptions?.style?.tableTest) {
            delete tableOptions.style.tableTest;
        }

        if (tableOptions.style.columnFilters === undefined) {
            tableOptions.style.columnFilters = false;
        }

        this.state = {
            offset: 0,
            limit: 50,
            paginationPage: 1,

            sortBy: 'LoadNumber',
            sort: 'DESC',

            secondSortBy: '',
            secondSort: '',

            fields: this.getFields(LocalStorage.get(this.pagePath + "_column_filters", {})),
            tableOptions: tableOptions,

            selectedItems: {},
            files: [],
            canSubmit: false,
            uploadFilesModalOpen: false,
            queryFilterFields: this.getQueryFilterFields(),
            tableColumnFields: {},
            clearTableColumnFields: 0,

            prefilled: '',

            confirmModalOpen: false,
            isDateFilterDialogVisible: false,
            isFilterSlideOverOpen: false,
            isLoadPreviewDialogOpen: false,
            selectedRows: {},
            areStatsVisible:
                checkPerm(Resources.LoadsProfitSummary, READ_PERM)
                &&
                (
                    LocalStorage.has(this.pageSettingsName)
                        ? JSONParseFix(LocalStorage.get(this.pageSettingsName) ?? {})?.areStatsVisible
                        : true
                ),
            selectedLabels: {},
            loadPreviewData: {},
            breakpoint: {},
            isTableFullScreen: false,
            isAutoRefreshEnabled: false,
            isUpdateStopModalOpen: false,
            isTableProfilesSideoverOpen: false,
            isStopUpdateSnoozeDialogOpen: false,
            showPreviewModal: false,
            willCopyValues: true
        }

        this.state = Object.assign(this.state, LocalStorage.rehydrateState(this.pagePath))

        this.PopoverButtonRef = React.createRef();
        this.inputFile = React.createRef();

        this.stateList = getLookup("State", "StateID", "State");

        this.IsCarrier = getProp(LocalStorage.get('user'), 'Contact.LineOfBusiness', [])
            .includes(LINE_OF_BUSINESS_PRIMARY_CARRIER);
        this.IsBroker = getProp(LocalStorage.get('user'), 'Contact.LineOfBusiness', [])
            .includes(LINE_OF_BUSINESS_PRIMARY_DRIVER);

        this.autoRefreshTimeout;
        this.autoRefresTime = 60000;
        this.canPostStopUpdates = checkPerm(Resources.LoadInfo, UPDATE_PERM);
        this.reminderIntervalDiff = {};

        this.notificationSoundID = getUserNotification();
        this.filterChangeTimeout;

        this.querySelects = {
            Customer: {
                api: 'api/' + Resources.CustomersQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LegalName || item.Organization,
                    value: item.CustomerID
                }),
            },
            Driver: {
                api: 'api/' + Resources.DriversQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.FirstName + " " + item.LastName
                }),
            },
            CoDriver: {
                api: 'api/' + Resources.DriversQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.FirstName + " " + item.LastName
                }),
            },
            Truck: {
                api: 'api/' + Resources.TrucksQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.TruckID,
                    label: item.SCAC + ' - ' + item.TruckNumber
                })
            },
            Trailer: {
                api: 'api/' + Resources.TrailersQuick,
                query: {},
                searchMap: (item) => ({
                    value: item.TrailerID,
                    label: item.SCAC + ' - ' + item.TrailerNumber
                })
            },

            PickupState: this.stateList,
            DestinationState: this.stateList,
            LoadStatus: getLookup("LoadStatus", "LoadStatusID", "LoadStatus"),
            IsBroker: {"": "All", 0: "Carrier", 1: "Broker"},

            IsTrackingEnabled: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            IsCustomerSyncEnabled: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            IsAutomaticCustomerShareEnabled: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            TrailerWasChanged: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            AssetWasChanged: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            AssetWillChange: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            TrailerWillChange: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            IsHazmat: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            IsTonu: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            IsInvoiced: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            IsPODReady: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            IsPODReviewed: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            IsSwitchDispatch: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            IsAccessorialHold: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            IsDropped: {
                "": this.props.translate("text.all"),
                1: this.props.translate("text.yes"),
                0: this.props.translate("text.no")
            },
            BookedBy: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + " " + item.LastName
                }),
            },
            CoveredBy: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + " " + item.LastName
                }),
            },
            CreatedBy: {
                api: 'api/' + Resources.ContactsQuick,
                query: {},
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + " " + item.LastName
                })
            },
            OfficeName: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            },
            ContactGroupName: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        sessionStorage.setItem('go_back_path', '/loads');
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.dialogResource.create && !prevProps.dialogResource.create) {
            this.setState({
                loadPreviewData: this.props.dialogResource.create
            })
        }

        if (resourceIsLoaded(this.props.resourceDiff, prevProps.resourceDiff)) {
            if (!!this.props.resourceDiff?.data?.count && this.props.resourceDiff?.data?.list?.length === 0) {
                this.handleUpdateOffset(0);
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.autoRefreshTimeout);
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResourceDiff({
            user: LocalStorage.get('user'),
            resource: Resources.Loads,
            data: {isInitial: true},
            query: this.getQuery()
        }))

        if (this.state.isAutoRefreshEnabled) {
            clearTimeout(this.autoRefreshTimeout);
            this.autoRefreshTimeout = setInterval(this.autoFetchData, this.autoRefresTime);
        }
    }

    autoFetchData = () => {
        this.props.dispatch(getResourceDiff({
            user: LocalStorage.get('user'),
            resource: Resources.Loads,
            data: {
                deltaKey: "LoadID",
                compareKeys: Object.values(this.state.tableOptions?.columns).filter(it => it.show).map(it => it.name)
            },
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.Loads,
            query: Object.assign(
                {format: 'EXCEL', name: 'Loads_' + currentDate() + '.xlsx'},
                this.getQuery()
            )
        }))
    }

    archiveResource = (item) => {
        this.handleShowConfirmDialog(() => {
            this.setState({
                ...returnOffsetAndPagination(getProp(this.props.resourceDiff.data, 'list', []), this.getQuery(), this.state.paginationPage),
                confirmModalOpen: false
            }, () => {
                this.props.dispatch(deleteResourceDiff({
                    user: LocalStorage.get('user'),
                    query: Object.assign({}, this.getQuery(), {LoadID: item.LoadID}),
                    errorMessage: true, successMessage: `${item.LoadNumber} archived.`,
                    resource: Resources.Load,
                    piggyResource: Resources.Loads
                }))
            })
        }, item)
    }

    restoreFromArchive = (item) => {
        this.handleShowConfirmDialog(() => {
            this.setState({
                confirmModalOpen: false
            }, () => {
                this.props.dispatch(updateResourceDiff({
                    user: LocalStorage.get('user'),
                    params: {
                        LoadID: item.LoadID,
                        ArchivedDate: 1
                    },
                    query: this.getQuery(),
                    errorMessage: true, successMessage: `${item.LoadNumber} restored.`,
                    resource: Resources.Load,
                    piggyResource: Resources.Loads
                }))
            })
        }, item, true)
    }

    handlePreviewLoadClick = (params) => {
        this.props.dispatch(createDialogResource({
            user: LocalStorage.get('user'),
            params: params,
            resource: Resources.LoadPreview,
        }));
    }

    // Bulk actions
    handleSetFavouriteClick = (isFavouriteAction = true) => {
        const selectedRows = Object.keys(this.state.selectedRows)

        this.props.dispatch(updateResourceDiff({
            user: LocalStorage.get('user'),
            params: {
                IDs: selectedRows,
                'Favorite': isFavouriteAction ? 1 : 0,
            },
            query: this.getQuery(),
            resource: Resources.LoadFavorites,
            piggyResource: Resources.Loads,
            errorMessage: true,
            successMessage: isFavouriteAction ? this.props.translate('message.selection_add_favourite') : this.props.translate('message.selection_remove_favourite')
        }))

        this.setState({selectedRows: {}})
    }

    handleSetMarkedClick = (isMarkAction = true) => {
        const selectedRows = Object.keys(this.state.selectedRows)

        this.props.dispatch(updateResourceDiff({
            user: LocalStorage.get('user'),
            params: {
                IDs: selectedRows,
                Marked: isMarkAction ? 1 : 0
            },
            query: this.getQuery(),
            resource: Resources.LoadMark,
            piggyResource: Resources.Loads,
            errorMessage: true,
            successMessage: isMarkAction ? this.props.translate('message.selection_add_marked') : this.props.translate('message.selection_remove_marked')
        }))

        this.setState({selectedRows: {}})
    }

    /** UI Events filters
     ================================================================= */
    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num,
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC';
        let secondSortBy = this.state.secondSortBy;
        let secondSort = this.state.secondSort;

        if (!sortBy) {
            sortBy = 'LoadNumber';
            sort = 'DESC';
            secondSortBy = '';
            secondSort = ''
        }

        if (sortBy === secondSortBy) {
            secondSortBy = '';
            secondSort = ''
        }

        this.setState({
            sortBy: sortBy,
            sort: sort,
            secondSortBy: secondSortBy,
            secondSort: secondSort
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleSecondSortChange = (secondSortBy) => {
        this.setState({
                secondSortBy: secondSortBy,
                secondSort: secondSortBy
                    ? (this.state.secondSortBy === secondSortBy) ? (this.state.secondSort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
                    : ""
            }, () => {
                this.saveFilters()
                this.fetchData(true)
            }
        );
    }

    handleFilterInputChange = (name, value) => {
        let fields = this.state.fields;

        if ("ColumnFilters" === name) {
            fields = Object.values(fields).map(it => {
                it.value = "";
                return it;
            });

            fields = fields.reduce((memo, it) => {
                memo[it.name] = it;
                return memo
            }, [])
        }

        const fieldsUpdate = FieldsManager.updateField(this.state.queryFilterFields, name, value);

        fieldsUpdate.StartDate.props.maxDate = fieldsUpdate.EndDate.value;
        fieldsUpdate.EndDate.props.minDate = fieldsUpdate.StartDate.value;

        this.setState({
            queryFilterFields: fieldsUpdate,
            offset: 0,
            paginationPage: 1,
            fields: fields
        }, () => {
            this.saveFilters();
            this.fetchData();
            this.persistColumnFilters(fields);
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)
        let fields = this.state.fields;

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            if ("ColumnFilters" === it.name && !!it.value) {
                fields = Object.values(fields).map(it => {
                    it.value = "";
                    return it;
                });

                fields = fields.reduce((memo, it) => {
                    memo[it.name] = it;
                    return memo
                }, []);

            }

            if ('DateType' !== it.name) {
                FieldsManager.updateField(queryFilterFields, it.name, '')
            }
        })

        queryFilterFields.StartDate.props.maxDate = ''
        queryFilterFields.EndDate.props.minDate = ''

        this.setState({
            queryFilterFields: queryFilterFields,
            tableColumnFields: {},
            fields: fields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters();
            this.persistColumnFilters(fields);
            this.fetchData(true)
        })
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    /** UI Events dialogs
     ================================================================= */
    handleShowConfirmDialog = (submit, item, restore = false) => {
        this.setState({
            confirmText: `${this.props.translate(!restore ? 'message.confirm_archive_generic' : 'message.confirm_restore_generic')} Load ${item.LoadNumber}?`,
            confirmModal: submit,
            confirmModalOpen: true
        })
    }

    toggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    toggleTableProfiles = () => {
        this.setState({
            isTableProfilesSideoverOpen: !this.state.isTableProfilesSideoverOpen
        })
    }

    setOptions = (options) => {
        let path = this.pagePath;

        this.setState({
            tableOptions: options
        }, () => saveTableColumns(path, options));
    }

    setTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)
        let hasSubColumns = false;

        tableOptions.columns = columns.reduce((memo, it) => {
            if (it?.subColumns?.length) {
                hasSubColumns = true;
            }
            memo[it.name] = it
            return memo
        }, {});

        if (hasSubColumns) {
            delete tableOptions.style.condensed;
        } else {
            tableOptions.style.condensed = !!tableOptions?.style?.condensed;
        }

        this.setState({
                tableOptions,
                isColumnsDialogVisible: false
            }, () => {
                saveTableColumns(this.pagePath, tableOptions);
            }
        )
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate);
    }

    onUploadClick = (uploadItem) => {
        this.inputFile.current.click();

        this.setState({uploadItem})
    };

    /** UI Events navigation
     ================================================================= */
    handleCreateUpdateResource = (item = null) => {
        if (item) {
            this.handleLinkClick(`/loads/info/${item.LoadID}`)
        } else {
            this.handleLinkClick(`/loads/create`)
        }
    }

    handleCopyLoad = (item) => {
        this.handleLinkClick('/loads/copy/' + item.LoadID)
    }

    handleLinkClick = (link) => {
        this.props.history.push(link)
    }

    /** UI Events table
     ================================================================= */
    handleFilterChange = (name, value) => {
        const fieldsUpdate = FieldsManager.updateField(this.state.fields, name, value);

        let queryFields = this.state.queryFilterFields;

        queryFields = FieldsManager.updateField(queryFields, COLUMN_FILTER_FLAG_FIELD, true);

        this.setState({
            fields: fieldsUpdate,
            queryFilterFields: queryFields
        }, () => {
            if (this.filterChangeTimeout) {
                clearTimeout(this.filterChangeTimeout);
            }

            this.filterChangeTimeout = setTimeout(() => {
                this.fetchData();
                this.persistColumnFilters(fieldsUpdate);
                this.saveFilters();
            }, 300);
        })
    }

    handleStoragePathChange = (storagePathsOptions) => {
        const newStoragePathID = storagePathsOptions.id;

        const newStoragePath = !newStoragePathID
            ? this.props.location.pathname.substring(1)
            : this.props.location.pathname.substring(1) + "_table_profile_" + newStoragePathID;

        this.pagePath = newStoragePath;

        let state = Object.assign(this.state, LocalStorage.rehydrateState(newStoragePath));
        let filters = LocalStorage.get(newStoragePath + "_column_filters", {});
        let queryFilterFields = LocalStorage.rehydrateState(newStoragePath)?.queryFilterFields ?? this.getQueryFilterFields();
        let tableOptions = LocalStorage.get(newStoragePath + "_table_options", undefined) ?? getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate);

        state.fields = this.getFields(filters);
        state.tableOptions = tableOptions;

        if (queryFilterFields) {
            state.queryFilterFields = Object.values(state.queryFilterFields).reduce((memo, it) => {
                it.value = queryFilterFields?.[it.name]?.value ?? "";
                memo[it.name] = it;

                return memo;
            }, {})
        }



        this.setState(state, () => {
            this.fetchData();
        });
    }

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }


    /** Helpers
     ================================================================= */
    getQuery = () => {
        const queryFilterFields = FieldsManager.getFieldKeyValues(this.state.queryFilterFields);
        const queryFilterFieldsWithValues = Object.keys(queryFilterFields).filter(it => !!queryFilterFields[it]).reduce((memo, it) => {
            if (it === 'customers' && !queryFilterFields[it]?.length) {
                return memo;
            }

            memo[it] = queryFilterFields[it];
            return memo;
        }, {});

        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            secondSort: this.state.secondSort,
            secondSortBy: this.state.secondSortBy,
            offset: this.state.offset,
            ...queryFilterFieldsWithValues,
            searchFields: this.getSearchFields(),
            limit: this.state.queryFilterFields.limit.value
        }
    }

    getQueryFilterFields = (item = {}) => {
        const fieldTemplates = {
            query: new Field('query', '', [''], false, 'search', {}),
            LoadStatusID: new Field('LoadStatusID', '', [''], false, 'select', {}, {
                all: true,
                isMulti: true,
                hasPortal: true
            }),
            IsBroker: new Field('IsBroker', '', [''], false, 'select', {}, {
                all: true
            }),

            customers: new Field('customers', '', [''], false, 'multi-select-search', {
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            }, {
                isClearable: true,
                multi: true
            }),
            favorites: new Field('favorites', '', [''], false, 'checkbox'),
            HaveOpenClaims: new Field('HaveOpenClaims', '', [''], false, 'checkbox'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            Marked: new Field('Marked', '', [''], false, 'checkbox'),
            IsOverpaid: new Field('IsOverpaid', '', [''], false, 'checkbox'),
            IsTailgate: new Field('IsTailgate', '', [''], false, 'checkbox'),
            StartDate: new Field('StartDate', '', [], false, 'date', {}, {}),
            EndDate: new Field('EndDate', '', [], false, 'date', {}, {}),
            DateType: new Field('DateType', 1, [''], false, 'button-group', {
                    data: {1: 'Pickup', 2: 'Delivery'},
                    labelType: 'stack',
                    addClass: 'w-full',
                    addButtonClass: 'uppercase font-semibold',
                    addContainerClass: 'col-span-2',
                }
            ),
            limit: new Field('limit', 50, [''], false, 'select', {
                hideLabel: true,
                labelType: 'float'
            }, {menuPlacement: "top", hasPortal: true}),
            ColumnFilters: new Field(COLUMN_FILTER_FLAG_FIELD, '', [''], false, 'hidden'),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getFields = (item = null) => {

        if (item) {
            item = Object.keys(item).reduce((memo, key) => {
                memo[key] = item[key];

                if (memo[key] === false) {
                    memo[key] = "";
                }

                return memo;
            }, {});
        }

        const fieldTemplates = {
            LoadID: new Field('LoadID', '', [''], false, 'hidden', {hideTable: true}),
            LoadNumber: new Field('LoadNumber', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <CellLink
                            to={checkPerm(Resources.LoadInfo, UPDATE_PERM) && "/loads/info/" + item.LoadID}
                            disabled={!checkPerm(Resources.LoadInfo, UPDATE_PERM)}
                        >
                            {item.InternalNotes
                                ? (
                                    <Tippy content={<div className='whitespace-pre-line'>
                                        {item.InternalNotes}
                                    </div>}
                                    >
                                        <div key={item.LoadNumber} className="flex">
                                            {item.LoadNumber}
                                        </div>
                                    </Tippy>
                                )
                                :
                                item.LoadNumber
                            }

                            {(!!item.Favourite || !!item.Marked) && (
                                <div className="ml-auto flex pl-3">
                                    {!!item.Favourite && (
                                        <Tippy
                                            content={this.props.translate('btn.favourite_load')}
                                        >
                                            <StarSolidIcon
                                                className={classNames('h-5 w-5 cursor-pointer text-yellow-400')}
                                            />
                                        </Tippy>
                                    )}

                                    {!!item.Marked && (
                                        <Tippy
                                            content={this.props.translate('btn.load_needs_attention')}
                                        >
                                            <span><NeedsAttentionSolidIcon/></span>
                                        </Tippy>
                                    )}
                                </div>
                            )}

                            {!!item.IsTrackingEnabled && (
                                <div className="ml-auto flex pl-3">
                                    <Tippy
                                        content={this.props.translate('text.loadAutoTracked')}
                                    >
                                        <span><MapPinIconPulse/></span>
                                    </Tippy>
                                </div>
                            )}

                            {!!item.IsCustomerSyncEnabled && (
                                <div className="ml-auto flex pl-3">
                                    <Tippy
                                        content={this.props.translate('text.customerAutoSynced')}
                                    >
                                        <span><RssIconPulse/></span>
                                    </Tippy>
                                </div>
                            )}

                            {!!item.AllowShare && (
                                <div className="ml-auto flex pl-3">
                                    <Tippy
                                        content={this.props.translate('text.customerShareEnabled')}
                                    >
                                        <span><ShareIconPulse/></span>
                                    </Tippy>
                                </div>
                            )}

                            {(!!item.TrailerWasChanged || !!item.AssetWasChanged) && (
                                <div className="ml-auto flex pl-3">
                                    <Tippy
                                        content={
                                            !!item.TrailerWasChanged && !!item.AssetWasChanged
                                                ? this.props.translate('text.TrailerAndAssetWasChanged')
                                                : item.TrailerWasChanged
                                                    ? this.props.translate('text.TrailerWasChanged')
                                                    : this.props.translate('text.AssetWasChanged')
                                        }
                                    >
                                        <ArrowsRightLeftIcon className="text-green-600 w-5 h-5"/>
                                    </Tippy>
                                </div>
                            )}

                            {(!!item.TrailerWillChange || !!item.AssetWillChange) && (
                                <div className="ml-auto flex pl-3">
                                    <Tippy
                                        content={
                                            !!item.TrailerWillChange && !!item.AssetWillChange
                                                ? this.props.translate('text.TrailerAndAssetWillChange')
                                                : item.TrailerWillChange
                                                    ? this.props.translate('text.TrailerWillChange')
                                                    : this.props.translate('text.AssetWillChange')
                                        }
                                    >
                                        <ArrowsRightLeftIcon className="text-yellow-600 w-5 h-5"/>
                                    </Tippy>
                                </div>
                            )}
                        </CellLink>
                    )
                },
                colFilter: {
                    name: "LoadID"
                }
            }, {icon: false}),

            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
            }),
            IsBroker: new Field('IsBroker', '', [''], false, 'select', {
                render: (it) => it.IsBroker ?
                    <span
                        className={'w-5 h-5 font-bold text-indigo-500'}>{this.props.translate("text.brokerage")}</span> :
                    <span className={'w-5 h-5 font-bold text-green-600'}>{this.props.translate("text.carrier")}</span>
            }),
            LoadStatus: new Field('LoadStatus', '', [''], false, 'select', {
                render: (item) => {
                    const IsDropped = item.IsDropped;
                    const IsTailgate = item.ParentLoadID;
                    const IsTonu = item.IsTonu;
                    return (
                        <StatusProgressTable
                            addClass=""
                            htmlAfterStatus={
                                IsDropped ? this.props.translate("text.droppedTagAddition") :
                                    IsTailgate ? this.props.translate("text.tailgateTagAddition") :
                                        IsTonu ? this.props.translate("text.tonuTagAddition") :
                                            undefined
                            }
                            LoadStatusID={item.LoadStatusID}
                            translate={this.props.translate}
                        />
                    )
                },
                colFilter: {
                    name: "LoadStatusID",
                    type: "select",
                    select: getLookup("LoadStatus", "LoadStatusID", "LoadStatus")
                }
            }, {all: true, addClass: "min-w-[9rem] form-control", isMulti: true, hasPortal: true}),

            StopUpdateReminder: new Field('StopUpdateReminder', "", [''], false, 'custom', {
                disableColumnFilter: true,
                render: (item) => this.getReminderMessage(item)
            }),
            LastStopUpdate: new Field('LastStopUpdate', "", [''], false, 'custom', {
                disableColumnFilter: true,
                render: (item) => {
                    if (!item.LastStopUpdate) {
                        return null;
                    }
                    if (item.LastStopUpdateNotes) {
                        return (
                            <Tippy
                                content={<span>{item.LastStopUpdateNotes}</span>}
                            >
                                <span>
                                    {item.LastStopUpdate}
                                </span>
                            </Tippy>
                        )
                    }
                    return (
                        <span>
                            {item.LastStopUpdate}
                        </span>
                    )
                }
            }),
            LoadTypeID: new Field('LoadTypeID', "", [''], false, 'select', {}, {isClearable: true}),
            LoadSubTypeID: new Field('LoadSubTypeID', "", [''], false, 'select', {}, {isClearable: true}),

            Customer: new Field('Customer', "", [''], false, 'select-search', {
                colFilter: {
                    name: "CustomerID",
                    select: {
                        CustomerID: {
                            api: 'api/' + Resources.CustomersQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.LegalName || item.Organization,
                                value: item.CustomerID
                            })
                        }
                    }
                },
                render: (item) => {
                    if (!item.CustomerID) return null;
                    return (
                        <CellButton
                            onClick={() => {
                                this.props.dispatch(showModal('ViewCustomerCard', {
                                    CustomerID: item.CustomerID,
                                    OrganizationID: item.OrganizationID
                                }));
                            }}
                        >
                            {item.Customer}
                        </CellButton>
                    )
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            CustomerReferenceNumber: new Field('CustomerReferenceNumber', "", [''], false, 'text', {}),
            Driver: new Field('Driver', '', [''], false, 'select-search', {
                colFilter: {
                    name: "DriverID",
                    select: {
                        DriverID: {
                            api: 'api/' + Resources.DriversQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.DriverID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                },
                render: (item) => {
                    if (!item) return null;

                    if (item.DriverID) {
                        return (
                            <CellButton
                                onClick={() => {
                                    this.props.dispatch(showGlobalModal('ViewDriverCard', item.DriverID))
                                }}
                            >
                                {item.Driver}
                            </CellButton>
                        )
                    } else {
                        return (<span>{item.Driver}</span>)
                    }
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            CoDriver: new Field('CoDriver', '', [''], false, 'select-search', {
                colFilter: {
                    name: "CoDriver",
                    select: {
                        CoDriver: {
                            api: 'api/' + Resources.DriversQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.DriverID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                },
                render: (item) => {
                    if (!item) return null;

                    if (item.CoDriverID) {
                        return (
                            <CellButton
                                onClick={() => {
                                    this.props.dispatch(showGlobalModal('ViewDriverCard', item.CoDriverID))
                                }}
                            >
                                {item.CoDriver}
                            </CellButton>
                        )
                    } else {
                        return (<span>{item.CoDriver}</span>)
                    }
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            Truck: new Field('Truck', '', [''], false, 'text', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TruckID) {
                        return (
                            <CellButton
                                onClick={() => {
                                    this.props.dispatch(showGlobalModal('ViewTruckCard', item.TruckID));
                                }}
                            >
                                {item.Truck}
                            </CellButton>
                        )
                    } else {
                        return (<span>{item.Truck}</span>)
                    }
                }
            }, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            Trailer: new Field('Trailer', '', [''], false, 'text', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TrailerID) {
                        return (
                            <CellButton
                                onClick={() => {
                                    this.props.dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID));
                                }}
                            >
                                {item.Trailer}
                            </CellButton>
                        )
                    } else {
                        return item.Trailer;
                    }
                }
            }, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            SecondTrailer: new Field('SecondTrailer', '', ['']),
            TrailerType: new Field('TrailerType', '', [''], false, 'text', {}, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            BookedBy: new Field('BookedBy', '', [''], false, 'multi-select-search', {
                colFilter: {
                    name: "BookedByID",
                    select: {
                        BookedByID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                }
            }, {isClearable: true, multi: true}),
            CoveredBy: new Field('CoveredBy', '', [''], false, 'select-search', {
                colFilter: {
                    name: "CoveredByID",
                    select: {
                        CoveredByID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                }
            }, {isClearable: true}),
            Carrier: new Field('Carrier', '', [''], false, 'text', {}, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true,
                colFilter: true
            }),
            Price: new Field('Price', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.Price)}</div>,
                colFilter: true
            }, {icon: false}),
            AccessorialAmount: new Field('AccessorialAmount', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.AccessorialAmount)}</div>,
                colFilter: true
            }, {icon: false}),
            CalculationPrice: new Field('CalculationPrice', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.CalculationPrice)}</div>,
                colFilter: true
            }, {icon: false}),
            DriverPayAmount: new Field('DriverPayAmount', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.DriverPayAmount)}</div>,
                colFilter: true
            }, {icon: false}),
            AccessorialDriverPayAmount: new Field('AccessorialDriverPayAmount', '', [''], false, "float", {
                render: (it) => <div
                    className={"text-right"}>{genericMoneyFormatter(it.AccessorialDriverPayAmount)}</div>,
                colFilter: true
            }, {icon: false}),
            CarrierAmount: new Field('CarrierAmount', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.CarrierAmount)}</div>,
                colFilter: true
            }, {icon: false}),
            CommisionAmount: new Field('CommisionAmount', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.CommisionAmount)}</div>,
                colFilter: true
            }, {icon: false}),
            PickupDate: new Field('PickupDate', '', [''], false, 'datetime', {disableColumnFilter: true}),
            PickupEndDate: new Field('PickupEndDate', '', [''], false, 'datetime', {disableColumnFilter: true}),
            Pickup: new Field('Pickup', '', [''], false, "text", {colFilter: true}, {icon: false}),
            PickupCityName: new Field('PickupCityName', '', [''], false, "text", {colFilter: true}, {icon: false}),
            PickupState: new Field('PickupState', '', [''], false, "select", {colFilter: true}, {
                icon: false,
                isClearable: true
            }),
            PickupPostalCode: new Field('PickupPostalCode', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationDate: new Field('DestinationDate', '', [''], false, 'datetime', {disableColumnFilter: true}),
            DestinationEndDate: new Field('DestinationEndDate', '', [''], false, 'datetime', {disableColumnFilter: true}),
            Destination: new Field('Destination', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationCityName: new Field('DestinationCityName', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationState: new Field('DestinationState', '', [''], false, "select", {colFilter: true}, {
                icon: false,
                isClearable: true
            }),
            DestinationPostalCode: new Field('DestinationPostalCode', '', [''], false, "text", {colFilter: true}, {icon: false}),
            NumberOfStops: new Field('NumberOfStops', '', [''], false, 'integer', {hideDialog: true}),
            TotalMiles: new Field('TotalMiles', '', [''], false, "float", {
                colFilter: true,
                render: (item) => <div
                    className={"text-right"}>{mileageToErrorMessage(returnMileage(item.TotalMiles), this.props.translate)}</div>
            }, {icon: false}),
            EmptyMiles: new Field('EmptyMiles', '', [''], false, "float", {
                colFilter: true,
                render: (item) => <div className={"text-right"}>{returnMileage(item.EmptyMiles)}</div>
            }, {icon: false}),
            TotalWeight: new Field('TotalWeight', '', [''], false, "float", {
                render: (it) => {
                    return <div className="text-right">{it.TotalWeight}</div>
                }
            }),// TODO Add unit suffix, will be return by API in LBS
            TotalVolume: new Field('TotalVolume', '', [''], false, 'float'), // TODO Add unit suffix, will be return by API in inches
            TotalPieces: new Field('TotalPieces', '', [''], false, "float", {colFilter: true}, {icon: false}),
            LastDetectedAddress: new Field('LastDetectedAddress', '', [''], false, "text", {colFilter: true}, {icon: false}),
            LastDetectedAddressEtaTime: new Field('LastDetectedAddressEtaTime', '', [''], false, 'datetimezone', {disableColumnFilter: true}),
            IsTrackingEnabled: new Field('IsTrackingEnabled', '', [''], false, 'select', {
                colFilter: true,
                hideTable: true
            }),
            IsCustomerSyncEnabled: new Field('IsCustomerSyncEnabled', '', [''], false, 'select', {
                colFilter: true,
                showInTable: false,
                hideTable: true
            }),
            IsAutomaticCustomerShareEnabled: new Field('IsAutomaticCustomerShareEnabled', '', [''], false, 'select', {
                colFilter: true,
                showInTable: false,
                hideTable: true
            }),
            TrailerWasChanged: new Field('TrailerWasChanged', '', [''], false, 'select', {
                colFilter: true,
                showInTable: false,
                hideTable: true
            }),
            AssetWasChanged: new Field('AssetWasChanged', '', [''], false, 'select', {
                colFilter: true,
                showInTable: false,
                hideTable: true
            }),
            AssetWillChange: new Field('AssetWillChange', '', [''], false, 'select', {
                colFilter: true,
                showInTable: false,
                hideTable: true
            }),
            TrailerWillChange: new Field('TrailerWillChange', '', [''], false, 'select', {
                colFilter: true,
                showInTable: false,
                hideTable: true
            }),
            IsHazmat: new Field('IsHazmat', '', [''], false, 'select', {
                render: (item) => {
                    return (
                        item.IsHazmat ?
                            <div className="text-center"><ExclamationTriangleIcon className={'w-5 h-5 text-red-600'}/>
                            </div> : null
                    )
                },
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsTonu: new Field('IsTonu', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsTonu}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsInvoiced: new Field('IsInvoiced', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsInvoiced}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsPODReady: new Field('IsPODReady', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsPODReady}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsPODReviewed: new Field('IsPODReviewed', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsPODReviewed}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsSwitchDispatch: new Field('IsSwitchDispatch', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsSwitchDispatch}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsAccessorialHold: new Field('IsAccessorialHold', '', [''], false, 'select', {
                disableColumnFilter: true,
                render: (it) => <CheckboxCell value={it.IsAccessorialHold}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsDropped: new Field('IsDropped', '', [''], false, 'select', {
                showInTable: false, // Prop to hide on table only
                disableColumnFilter: true,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            LastCustomerUpdateDate: new Field('LastCustomerUpdateDate', '', [''], false, 'datetimezone', {disableColumnFilter: true}),
            OfficeName: new Field('OfficeName', '', [''], false, 'select-search', {
                colFilter: {
                    name: "OfficeID",
                    select: {
                        OfficeID: {
                            api: 'api/' + Resources.OfficesQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.OfficeName,
                                value: item.OfficeID
                            })
                        },
                    }
                }
            }, {isClearable: true}),
            ContactGroupName: new Field('ContactGroupName', '', [''], false, 'select-search', {
                colFilter: {
                    name: "ContactGroupID",
                    select: {
                        ContactGroupID: {
                            api: 'api/' + Resources.ContactGroupsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.ContactGroupName,
                                value: item.ContactGroupID
                            })
                        },
                    }
                }
            }, {isClearable: true}),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [''], false, 'datetimezone', {disableColumnFilter: true}),
            CreateDate: new Field('CreateDate', '', [''], false, 'datetimezone', {
                hideDialog: true,
                disableColumnFilter: true
            }),
            CreatedBy: new Field('CreatedBy', '', [''], false, 'select-search', {
                colFilter: {
                    name: "CreatedByID",
                    select: {
                        CreatedByID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                }
            }, {isClearable: true}),
        }

        let filledFields = fillFieldsFromData(fieldTemplates, item)

        if (item) {
            Object.values(filledFields).reduce((memo, field) => {
                if (field.type === 'select-search' && item[[field.name] + "ID"]) {
                    field.value = {label: item[field.name], value: item[[field.name] + "ID"]}
                }

                memo[field.name] = field;
                return memo;
            }, {})
        }

        filledFields = Object.values(filledFields).reduce((memo, it) => {
            if (!checkPerm(Resources.LoadsPricing, READ_PERM)
                &&
                SENSITIVE_LOAD_LIST_FIELDS.includes(it.name)
            ) {
                return memo;
            }
            if (it.type === 'checkbox') {
                it.value = it.value ? it.value.toString() : "";
            }
            memo[it.name] = it;
            return memo;
        }, {});

        return filledFields;
    }


    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['offset_override', 'sort', 'sortBy', 'secondSort', 'secondSortBy', 'paginationPage', 'limit', 'isAutoRefreshEnabled'])
    }

    getPrimaryKey = () => {
        return 'LoadID'
    }

    getDateFilterLabel = () => {
        let defaultText = (!this.state.queryFilterFields.StartDate.value || !this.state.queryFilterFields.EndDate.value) && this.props.translate('text.select_date_range')
        let pickupOrDelivery = ''
        if (this.state.queryFilterFields.DateType.value !== '') {
            pickupOrDelivery = ' - ' + (this.state.queryFilterFields.DateType.value == 1 ? 'Pickup' : 'Delivery')
        }

        let from = '*'
        let to = '*'

        if (this.state.queryFilterFields.StartDate.value) {
            from = toFrontDate(this.state.queryFilterFields.StartDate.value);

        }

        if (this.state.queryFilterFields.EndDate.value) {
            to = toFrontDate(this.state.queryFilterFields.EndDate.value)
        }

        return defaultText ? defaultText : (from + ' - ' + to + pickupOrDelivery)
    }


    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }

    handleSelectRowClick = (item) => {
        const itemID = item.LoadID;

        let selectedRows = cloneDeep(this.state.selectedRows)

        if (selectedRows[itemID]) {
            delete selectedRows[itemID]
        } else {
            Object.assign(selectedRows, {[itemID]: item})
        }

        this.setState({selectedRows})
    }

    handleSelectAllNewClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resourceDiff.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows);

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                memo[it.LoadID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.LoadID.toString())) {
                    delete selectedRows[it.LoadID]
                }
            })
        }

        this.setState({selectedRows})
    }

    getResource = () => {
        return Resources.Loads
    }

    handleStatsToggleClick = () => {
        this.setState({areStatsVisible: !this.state.areStatsVisible}, () => {
            let pageSettings = {}

            if (LocalStorage.has(this.pageSettingsName)) {
                pageSettings = JSONParseFix(LocalStorage.get(this.pageSettingsName))
            }

            Object.assign(pageSettings, {areStatsVisible: this.state.areStatsVisible})
            LocalStorage.set(this.pageSettingsName, pageSettings)
        })
    }

    toggleLoadPreviewDialog = () => {
        this.setState({
            isLoadPreviewDialogOpen: !this.state.isLoadPreviewDialogOpen,
            loadPreviewData: {}
        })
    }

    toggleColumnFilterSlideOver = () => {
        this.setState({
            isFilterSlideOverOpen: !this.state.isFilterSlideOverOpen
        })
    }

    toggleStopUpdateDialog = (item) => {
        this.setState({
            isUpdateStopModalOpen: !this.state.isUpdateStopModalOpen,
            selectedItem: item
        })
    }

    toggleStopUpdateSnoozeDialog = () => {
        if (this.state.isUpdateStopModalOpen) {
            this.setState({
                    isUpdateStopModalOpen: false
                },
                () => setTimeout(() => {
                    this.toggleStopUpdateSnoozeDialog();
                }, 10)
            );
        } else {
            this.setState({
                isStopUpdateSnoozeDialogOpen: !this.state.isStopUpdateSnoozeDialogOpen
            });
        }
    }

    getSearchFields = () => {
        return JSON.stringify(Object.values(this.state.fields).filter(it => it.value !== '' && it.value !== false).reduce((memo, it) => {

            let customValue = null;
            let name = it?.metadata?.colFilter?.name ?? it.name
            if (!!it?.value && it.value.length > 1 && (it.type === "float" || it.type === "money") && ["<", ">"].includes(it.value.charAt(0))) {
                customValue = [name, it.value.charAt(0), it.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')];
            }

            if (it.type === "select-search") {
                if (it?.props?.multi) {
                    memo[name] = it.value?.length ? it.value.map(it => it.value) : undefined;
                } else {
                    memo[name] = customValue ?? it.value?.value;
                }
            } else {
                memo[name] = customValue ?? it.value;
            }

            if (it.name === "PickupState" || it.name === "DestinationState") {
                memo[name] = this.stateList[it.value].substr(0, this.stateList[it.value].lastIndexOf(" "));
            }

            if (it.name === 'LoadStatus') {
                memo[name] = memo[name]?.reduce((statusMemo, status) => {
                    statusMemo.push(status.value);
                    return statusMemo;
                }, []);
            }

            if (it.name === 'BookedBy') {
                if (it?.value?.length) {
                    memo[name] = it.value.map(it => it.value);
                }
            }

            if (memo[name] === null) {
                memo[name] = undefined;
            }

            return memo;
        }, {}))
    }


    handleUploadInputChange = (name, value, i) => {
        let files = [...this.state.files];
        files[i].fields = FieldsManager.updateField(files[i].fields, name, value)
        let updateIsVisibleForCustomer = false;

        if (!i && name === "IsSendWithInvoice") {
            // In value is initialy false, checking IsSendWithInvoice will also check IsVisibleForCustomer
            if (!this.areCheckboxesTouched && value) {
                files[i].fields.IsVisibleForCustomer.value = value;
                updateIsVisibleForCustomer = true;
            }

            this.areCheckboxesTouched = true;
        }

        if (!i && this.state.willCopyValues) {
            files = files.map((file, fileIndex) => {
                if (fileIndex) {
                    file.fields[name].value = value;

                    if (updateIsVisibleForCustomer) {
                        file.fields.IsVisibleForCustomer.value = value;
                    }
                }

                return file
            });
        }

        this.setState({
            files,
            canSubmit: true
        })
    };

    getDocumentUploadFields = (index = 0, file) => {
        let fields;
        const DocumentTypeID = file.type

        fields = {
            DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {}, {
                values: getDocumentTypesFor("IsDispatchDocument")
            }),
            ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {}),
            IsSendWithInvoice: new Field("IsSendWithInvoice", '', [], false, 'checkbox', {}, {
                htmlFor: `IsSendWithInvoice${index}`,
                id: `IsSendWithInvoice${index}`,
            }),
            IsVisibleForCustomer: new Field("IsVisibleForCustomer", '', [], false, 'checkbox', {}, {
                htmlFor: `IsVisibleForCustomer${index}`,
                id: `IsVisibleForCustomer${index}`,
            }),
            Description: new Field("Description", '', [], false, 'textarea', {}),
        }

        if (!!DocumentTypeID && !!fields.DocumentTypeID) {
            fields.DocumentTypeID.value = DocumentTypeID;
        }

        return fields;
    }

    onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();

        this.setState({
            files: Object.values(event.target.files),
            uploadFilesModalOpen: true
        });
        this.setState({
            files: Object.values(event.target.files).map((file, index) => Object.assign(file, {
                preview: URL.createObjectURL(file),
                errorMessage: '',
                fields: Object.assign({}, this.getDocumentUploadFields(index, file))
            }))
        })
    }

    handleCancelUpload = () => {
        this.setState({
            uploadFilesModalOpen: false,
            files: []
        });
    }

    downloadPreview = (uri, name) => {
        const link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleRemoveDocumentFromUpload = (index) => {
        let files = this.state.files
        files.splice(index, 1)
        if (files.length === 0) {
            this.handleCancelUpload()
        } else {
            this.setState({files})
        }
    }

    showPreviewModal = (item) => {
        this.setState({showPreviewModal: true, previewFile: item})
    }

    handleUploadDocument = () => {
        this.setState({
            files: this.state.files.map((file) => Object.assign(file, {
                fields: FieldsManager.validateFields(file.fields)
            }))
        }, () => {
            if (this.state.files.reduce((memo, file) => (memo && FieldsManager.checkFieldsForErrors(file.fields)), true)) {
                const files = this.state.files.map(file => Object.assign(file, {
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(file.fields), {
                        Version: 0
                    })
                }))

                this.props.dispatch(uploadDocument({
                    user: LocalStorage.get("user"),
                    files: files,
                    id: this.state.uploadItem.LoadID,
                    query: Object.assign({}, {
                        id: this.state.uploadItem.LoadID,
                    }, this.getQuery()),
                    resource: Resources.LoadDocuments,
                    errorMessage: true,
                    successMessage: `Attachment${this.state.files.length > 1 ? "s" : ""} ${this.state.files.length > 1 ? "have" : "has"} been uploaded successfully.`,
                    piggyResource: Resources.Loads
                }))
                this.handleCancelUpload()
            }
        })
    }

    persistColumnFilters = (fields) => {
        let filters = LocalStorage.get(this.pagePath + "_column_filters", {});
        let fieldValues = Object.values(fields).reduce((memo, it) => {
            if (it.type === "select-search") {
                memo[it.name + "ID"] = it?.value?.value;
                memo[it.name] = it?.value?.label
            } else {
                memo[it.name] = it.value;
            }
            return memo
        }, {});

        LocalStorage.set(this.pagePath + "_column_filters", Object.assign(filters, fieldValues));
    }

    async watchReminderIntervalForChange(id, status) {
        if (this.reminderIntervalDiff[id] && this.reminderIntervalDiff[id] !== status && status !== 'green') {
            const audio = new Audio(`/audio/${this.notificationSoundID}.mp3`);

            if (audio.paused || audio.ended) {
                const playPromise = audio.play();

                if (playPromise !== undefined) {
                    playPromise.catch((error) => {
                        console.error('Error while trying to play media:', error);
                    });
                }
            }

            this.reminderIntervalDiff[id] = status;
        }
    }

    getReminderMessage = (item) => {
        let intervalDiff = moment.utc().diff(moment.utc(item?.LastStopUpdateDateTime), 'minutes')

        let className;
        let message = this.props.translate("text.stop_interval_in_minutes", [item.StopUpdateReminderInternal]);
        let content;
        const roundedIntervalDiff = Math.abs(item.StopUpdateReminderInternal - Math.round(intervalDiff));
        const conversion = minutesToDayHourMinutes(Math.abs(roundedIntervalDiff))
        let status = '';

        if (intervalDiff <= item.StopUpdateReminderInternal * 0.8) {
            content = this.props.translate('text.lastStopReminderNote', [roundedIntervalDiff]);

            if (conversion.days) {
                content = this.props.translate(`text.reminderToNoteDHM`, [conversion.days, conversion.hours, conversion.minutes])
            } else if (conversion.hours) {
                content = this.props.translate("text.reminderToNoteHM", [conversion.hours, conversion.minutes])
            }

            className = 'mr-1 w-5 h-5 flex-shrink-0 text-green-600'
            status = 'green'
        } else if (roundedIntervalDiff === 0) {
            content = this.props.translate('text.lastStopReminderNowNote');
            className = 'mr-1 w-5 h-5 flex-shrink-0 animate-blink-soft text-yellow-600'
            status = 'yellow'
        } else if (intervalDiff <= item.StopUpdateReminderInternal) {
            content = this.props.translate('text.lastStopReminderNote', [roundedIntervalDiff]);
            className = 'mr-1 w-5 h-5 flex-shrink-0 animate-blink-soft text-yellow-600'
            status = 'yellow'
        } else {
            content = this.props.translate('text.lastStopReminderDueNote', [roundedIntervalDiff]);

            if (conversion.days) {
                content = this.props.translate(`text.reminderDueNoteDHM`, [conversion.days, conversion.hours, conversion.minutes])
            } else if (conversion.hours) {
                content = this.props.translate("text.reminderDueNoteHM", [conversion.hours, conversion.minutes])
            }

            className = 'mr-1 w-5 h-5 flex-shrink-0 animate-blink-soft text-red-600'
            status = 'red'
        }

        if (this.notificationSoundID && item.IsStopUpdateReminderOn) {
            this.watchReminderIntervalForChange(item.LoadID, status);
        }

        if (!!item.LastStopUpdateDateTime && !!item.IsStopUpdateReminderOn && [LOAD_STATUS_DISPATCHED, LOAD_STATUS_RESERVED, LOAD_STATUS_IN_TRANSIT].includes(item.LoadStatusID)) {
            return (
                <CellButton
                    disabled={!this.canPostStopUpdates}
                    onClick={() => this.toggleStopUpdateDialog(item)}
                >
                    <Tooltip content={message}>
                        <BellSolidIcon className={className}/>
                    </Tooltip>

                    {content}
                </CellButton>
            )
        } else if (!!item.IsStopUpdateReminderOn && [LOAD_STATUS_DISPATCHED, LOAD_STATUS_RESERVED, LOAD_STATUS_IN_TRANSIT].includes(item.LoadStatusID)) {
            return (
                <CellButton
                    disabled={!this.canPostStopUpdates}
                    onClick={() => this.toggleStopUpdateDialog(item)}
                >
                    Reminder is set, first update should be posted
                </CellButton>
            )
        } else if (item.IsStopUpdateReminderOn && item.LoadStatusID !== LOAD_STATUS_DELIVERED) {
            return (
                <div className='flex relative z-0'>
                    When load is covered reminder timer will start
                </div>
            )
        } else {
            return (
                <div className='flex relative z-0'>
                    No reminder
                </div>
            )
        }
    }

    toggleAutoRefresh = () => {
        this.setState({
                isAutoRefreshEnabled: !this.state.isAutoRefreshEnabled
            }, () => {
                this.saveFilters();

                if (this.state.isAutoRefreshEnabled) {
                    this.autoRefreshTimeout = setInterval(this.autoFetchData, this.autoRefresTime);
                } else {
                    clearTimeout(this.autoRefreshTimeout);
                }
            }
        )
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props
        const resource = this.props.resourceDiff ?? {};
        const data = getProp(resource.data, 'list', []);

        const Price = getProp(resource.data, 'Price', 0)
        const AccessorialAmount = getProp(resource.data, 'AccessorialAmount', 0)
        const ExtraChargesAmount = getProp(resource.data, 'ExtraChargesAmount', 0)
        const TotalIncome = AccessorialAmount + Price + ExtraChargesAmount
        const AccessorialPayAmount = getProp(resource.data, 'AccessorialPayAmount', 0)
        const CarrierAmount = getProp(resource.data, 'CarrierAmount', 0)
        const CommissionAmount = getProp(resource.data, 'CommisionAmount', 0)
        const DriverPayAmount = getProp(resource.data, 'DriverPayAmount', 0);
        const CalculationPrice = getProp(resource.data, 'CalculationPrice', 0);
        const TotalExpense = CarrierAmount + CommissionAmount + DriverPayAmount + AccessorialPayAmount
        const FullMiles = getProp(resource.data, 'TotalMiles', 0)
        const EmptyMiles = getProp(resource.data, 'EmptyMiles', 0)
        const TotalMiles = FullMiles + EmptyMiles;

        let DriverRPM = isNaN(DriverPayAmount / TotalMiles) ? 0 : (DriverPayAmount / TotalMiles).toFixed(2);
        const CalculationPriceRPM = isNaN(CalculationPrice / TotalMiles) ? 0 : (CalculationPrice / TotalMiles).toFixed(2);

        const PrevCarrierAmount = getProp(resource.data, 'PrevCarrierAmount', 0)
        const PrevCommisionAmount = getProp(resource.data, 'PrevCommisionAmount', 0)
        const PrevDriverPayAmount = getProp(resource.data, 'PrevDriverPayAmount', 0)
        const PrevAccessorialPayAmount = getProp(resource.data, 'PrevAccessorialPayAmount', 0)
        const PrevPrice = getProp(resource.data, 'PrevPrice', 0)
        const PrevAccessorialAmount = getProp(resource.data, 'PrevPrice', 0)
        const PrevExtraChargesAmount = getProp(resource.data, 'PrevExtraChargesAmount', 0)
        const PrevTotalIncome = PrevAccessorialAmount + PrevPrice + PrevExtraChargesAmount
        const PrevTotalExpense = PrevCarrierAmount + PrevCommisionAmount + PrevDriverPayAmount + PrevAccessorialPayAmount

        const PrevFullMiles = getProp(resource.data, 'PrevTotalMiles', 0)
        const PrevEmptyMiles = getProp(resource.data, 'PrevEmptyMiles', 0)
        const PrevTotalMiles = PrevFullMiles + PrevEmptyMiles

        //const altPopupClass = 'rounded-lg bg-popup overflow-hidden shadow divide-y-px divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px py-2'

        const isLoading = resource.isLoading
        const count = resource?.data?.count
        const areSomeItemsSelected = !!Object.keys(this.state.selectedRows).length
        const stats = [
            {
                id: 2,
                name: translate('text.revenue'),
                icon: ArrowTrendingUpIcon,
                iconClass: 'bg-green-500',
                value: TotalIncome,
                valuePrefix: '$',
                valueOld: PrevPrice,
                more: [
                    {
                        id: 1,
                        name: translate('text.Price'),
                        amount: '$' + numberWithCommas(Price),
                        icon: ArrowTrendingUpIcon
                    },
                    {
                        id: 2,
                        name: translate('text.AccessorialAmount'),
                        amount: '$' + numberWithCommas(AccessorialAmount),
                        icon: ArrowTrendingUpIcon
                    }
                ]
            },
            {
                id: 3,
                name: translate('text.expenses'),
                icon: ArrowTrendingDownIcon,
                iconClass: 'bg-red-500',
                value: TotalExpense,
                valuePrefix: '$',
                valueOld: PrevTotalExpense,
                more: [
                    {
                        id: 1,
                        name: translate('text.CarrierAmount'),
                        amount: '$' + numberWithCommas(CarrierAmount),
                        icon: ArrowTrendingDownIcon,
                        hidden: !this.IsBroker
                    },
                    {
                        id: 3,
                        name: translate('text.DriverPayAmount'),
                        valueClass: "text-primary font-medium",
                        amount: <div className="pl-7">
                            {'$' + numberWithCommas(DriverPayAmount)}
                            {CalculationPrice
                                ? (<span
                                    className="opacity-70">{" ( $" + numberWithCommas(CalculationPrice) + " )"}</span>)
                                : null
                            }
                        </div>,
                        icon: ArrowTrendingDownIcon,
                        hidden: !this.IsCarrier
                    },
                    {
                        id: 4,
                        name: translate('text.DriverRPM'),
                        className: "block pl-4 pr-8 py-3 hover:bg-tm-gray-200 bg-tm-gray-100",
                        labelClass: "pl-7 flex flex-col text-tm-gray-900 text-sm truncate",
                        valueClass: "text-primary font-medium",
                        amount: <div className="pl-7">
                            {'$' + numberWithCommas(DriverRPM)}
                            {CalculationPriceRPM
                                ? (<span
                                    className="opacity-70">{" ( $" + numberWithCommas(CalculationPriceRPM) + " )"}</span>)
                                : null
                            }
                        </div>,
                        hidden: !this.IsCarrier
                    },
                    {
                        id: 5,
                        name: translate('text.AccessorialDriverPayAmount'),
                        amount: '$' + numberWithCommas(AccessorialPayAmount),
                        icon: ArrowTrendingDownIcon,
                        hidden: !this.IsCarrier
                    },
                    {
                        id: 2,
                        name: translate('text.CommisionAmount'),
                        amount: '$' + numberWithCommas(CommissionAmount),
                        icon: ArrowTrendingDownIcon,

                    }

                ],
                "more-col-2": []
            },
            {
                id: 1,
                name: translate('text.profitAndLoss'),
                icon: ScaleIcon,
                value: TotalIncome - TotalExpense,
                valuePrefix: '$',
                valueOld: PrevTotalIncome - PrevTotalExpense,
                widthClass: "max-w-sm",
                positionClass: "z-50 px-4 mt-3 transform sm:px-0 absolute xl:right-0 opacity-100 left-10 lg:left-0 xl:left-auto xl:right-0 -translate-x-full sm:-translate-x-1/2 xl:translate-x-0 xl:translate-y-0",
                more: [
                    {
                        id: 1,
                        name: translate('text.Price'),
                        amount: '$' + numberWithCommas(Price),
                        icon: ArrowTrendingUpIcon
                    },
                    {
                        id: 2,
                        name: translate('text.AccessorialAmount'),
                        amount: '$' + numberWithCommas(AccessorialAmount),
                        icon: ArrowTrendingUpIcon
                    },
                    {
                        id: 3,
                        name: translate('text.total_revenue'),
                        amount: '$' + numberWithCommas(TotalIncome),
                        icon: ArrowTrendingUpIcon,
                        labelClass: 'pl-5 flex flex-col text-green-500 text-sm font-bold truncate',
                        valueClass: 'pl-7 text-green-600 font-bold',
                    },
                ],
                "more-col-2": [
                    {
                        id: 4,
                        name: translate('text.CarrierAmount'),
                        amount: '$' + numberWithCommas(CarrierAmount),
                        icon: ArrowTrendingDownIcon,
                        hidden: !this.IsBroker
                    },
                    {
                        id: 6,
                        name: translate('text.DriverPayAmount'),
                        amount: '$' + numberWithCommas(DriverPayAmount),
                        icon: ArrowTrendingDownIcon,
                        hidden: !this.IsCarrier
                    },
                    {
                        id: 7,
                        name: translate('text.AccessorialDriverPayAmount'),
                        amount: '$' + numberWithCommas(AccessorialPayAmount),
                        icon: ArrowTrendingDownIcon,
                        hidden: !this.IsCarrier
                    },
                    {
                        id: 11,
                        name: translate('text.CommisionAmount'),
                        amount: '$' + numberWithCommas(CommissionAmount),
                        icon: ArrowTrendingDownIcon
                    },
                    {
                        id: 8,
                        name: translate('text.total_expense'),
                        amount: '$' + numberWithCommas(TotalExpense),
                        icon: ArrowTrendingDownIcon,
                        labelClass: 'pl-5 flex flex-col text-red-500 text-sm font-bold truncate',
                        valueClass: 'pl-7 text-red-600 font-bold',
                    }
                ]
            },
            {
                id: 4,
                name: translate('text.TotalMiles'),
                icon: TruckIcon,
                valueOld: PrevTotalMiles,
                value: returnMileage(TotalMiles),
                valueSuffix: ' miles',
                decimalCount: 0,
                more: [
                    {
                        id: 1,
                        name: translate('text.FullMiles'),
                        amount: returnMileage(FullMiles),
                        icon: CubeIcon
                    },
                    {
                        id: 2,
                        name: translate('text.EmptyMiles'),
                        amount: returnMileage(EmptyMiles),
                        icon: CubeTransparentIcon
                    },
                ]
            },
        ]

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.Loads')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateUpdateResource()}
                        hasPerm={checkPerm(Resources.Load, CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    >
                        {checkPerm(Resources.LoadsProfitSummary, READ_PERM) && (
                            <Tippy
                                content={translate(this.state.areStatsVisible ? 'text.hide_stats' : 'text.show_stats')}
                                delay={[400, 0]}
                                trigger="mouseenter"
                            >
                                <button
                                    className={
                                        classNames(
                                            this.state.areStatsVisible ? 'text-primary border-primary' : undefined,
                                            'btn btn-header'
                                        )
                                    }
                                    onClick={this.handleStatsToggleClick}
                                >
                                    <PresentationChartLineIcon
                                        className="w-5 h-5"
                                    />
                                </button>
                            </Tippy>
                        )}
                        {checkPerm(Resources.LoadPreview, CREATE_PERM) && (
                            <Tippy
                                content={translate('btn.plan_load')}
                                delay={[400, 0]}
                                trigger="mouseenter"
                            >
                                <button
                                    className={
                                        classNames(
                                            this.state.areStatsVisible ? 'text-primary border-primary' : undefined,
                                            'btn btn-header'
                                        )
                                    }
                                    onClick={this.toggleLoadPreviewDialog}
                                >
                                    <CalculatorIcon
                                        className="w-5 h-5"
                                    />
                                </button>
                            </Tippy>
                        )}
                    </PageHeader>

                    {this.state.areStatsVisible && (
                        <div className="relative z-40">
                            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-4">
                                <WidgetStats
                                    isLoading={isLoading}
                                    stats={stats}
                                />
                            </div>
                        </div>
                    )}

                    <div className="sm:flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            excludedFields={['limit', 'DateType']}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <Tippy content={translate('text.column_filters')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.toggleColumnFilterSlideOver}
                                >
                                    <FunnelIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.toggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard
                        addClass={
                            classNames(
                                this.state.isTableFullScreen
                                    ? "fixed inset-1 -top-1 z-50"
                                    : undefined
                            )}
                    >
                        <TableFilters
                            hideLimit
                            forceDialog={true}
                            filterFields={excludeFields(this.state.queryFilterFields, ['StartDate', 'EndDate', 'DateType', COLUMN_FILTER_FLAG_FIELD])}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            toggleAutoRefresh={this.toggleAutoRefresh}
                            isAutoRefreshEnabled={this.state.isAutoRefreshEnabled}
                            isLoading={isLoading || resource.isLoadingSilent}
                            isTableFullScreen={this.state.isTableFullScreen}
                            onFullScreenToggle={this.handleFullScreenToggle}


                            onStoragePathChange={this.handleStoragePathChange}


                            selects={{
                                limit: {
                                    10: '10',
                                    20: '20',
                                    30: '30',
                                    40: '40',
                                    50: '50',
                                    100: '100',
                                    200: '200',
                                    300: '300',
                                    500: '500'
                                },
                                IsBroker: {0: 'Carrier', 1: 'Brokerage'},
                                customers: {
                                    api: 'api/' + Resources.CustomersQuick,
                                    query: this.state.queryFilterFields.customers?.metadata?.query ?? DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        label: item.LegalName || item.Organization,
                                        value: item.CustomerID
                                    })
                                },
                                Tags: getLookup('TaskTagType')
                            }}

                            customHeaderHtml={(
                                <PopOver
                                    className="relative"
                                    btnClass="text-opacity-90 form-control p-0 w-auto text-tm-gray-700 flex items-center flex py-2 px-3"
                                    BtnIcon={CalendarIcon}
                                    chevronIcon={true}
                                    btnLabel={this.getDateFilterLabel()}
                                    btnIconClass={classNames("h-4 w-4 mr-1", this.state.queryFilterFields?.StartDate?.value && this.state.queryFilterFields?.EndDate?.value ? "hidden 2xl:block" : undefined)}
                                    chevronIconClass={
                                        classNames(
                                            "ml-2 h-4 w-4 text-tm-gray-700 group-hover:text-opacity-80 transition ease-in-out duration-150",
                                            this.state.queryFilterFields?.StartDate?.value && this.state.queryFilterFields?.EndDate?.value ? "hidden 2xl:block" : undefined
                                        )}
                                    widthClass="max-w-2xl"
                                    positionClass="absolute translate-x-0 left-0"
                                >
                                    <div className="bg-popup border border-tm-gray-300 rounded-lg">
                                        <PayDateFilter
                                            queryFields={this.state.queryFilterFields}
                                            translate={translate}
                                            updateQueryFields={(queryFieldsUpdate) => {
                                                this.setState({
                                                    queryFilterFields: queryFieldsUpdate,
                                                    offset: 0
                                                }, () => this.fetchData())
                                                this.saveFilters(queryFieldsUpdate)
                                            }}
                                            onQueryChange={this.handleFilterInputChange}
                                            isDateTypeClearable={false}
                                        />
                                    </div>
                                </PopOver>
                            )}
                            customHtml={
                                <PayDateFilter
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                    updateQueryFields={(queryFieldsUpdate) => {
                                        this.setState({
                                            queryFilterFields: queryFieldsUpdate,
                                            offset: 0
                                        }, () => this.fetchData())
                                        this.saveFilters(queryFieldsUpdate)
                                    }}
                                    onQueryChange={this.handleFilterInputChange}
                                    isDateTypeClearable={false}
                                />
                            }
                        >
                            <Tooltip content="Split view">
                                <button
                                    onClick={() => this.props.history.push("/loads-split")}
                                    className="btn btn-header"
                                >
                                    <div className="flex flex-col gap-0.5">
                                        <div className="rounded-sm h-2 w-4 border-2 border-tm-gray-500"/>
                                        <div className="rounded-sm h-2 w-4 border-2 border-tm-gray-500"/>
                                    </div>
                                </button>
                            </Tooltip>
                        </TableFilters>

                        <ResourceTable
                            tableID="loads"
                            key={this.pagePath}
                            tableKey={"LoadID"}
                            updatedRows={resource?.diffData ?? {}}
                            //fields={this.getFields()}
                            fields={this.state.fields}

                            data={data}
                            isLoading={isLoading}
                            limit={this.state.queryFilterFields.limit.value}
                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            handleColumnFilterInputChange={this.handleFilterChange}
                            filterSelects={this.querySelects}

                            onSecondSortChange={this.handleSecondSortChange}
                            secondSortBy={this.state.secondSortBy}
                            secondSort={this.state.secondSort}

                            onRowClick={checkPerm(Resources.LoadInfo, UPDATE_PERM) ? this.handleCreateUpdateResource : null}
                            onView={this.handleCreateUpdateResource}
                            onEdit={this.handleCreateUpdateResource}
                            onRestore={this.restoreFromArchive}

                            hasViewPerm={checkPerm(Resources.LoadInfo, UPDATE_PERM)}
                            hasEditPerm={checkPerm(Resources.LoadInfo, UPDATE_PERM)}

                            actions={[
                                {
                                    action: this.handleCopyLoad,
                                    icon: Square2StackIcon, // make this a function
                                    hasPerm: checkPerm(Resources.Load, CREATE_PERM),
                                    label: false, // make this a function
                                    title: translate("btn.duplicate_load"),
                                    order: 22,
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                },
                                {
                                    action: this.onUploadClick,
                                    icon: DocumentIcon, // make this a function
                                    hasPerm: checkPerm(Resources.LoadDocuments, CREATE_PERM),
                                    label: false, // make this a function
                                    title: translate("btn.upload_document"),
                                    order: 21,
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                },
                                {
                                    action: (it) => {
                                        (it.LoadStatusID < LOAD_STATUS_DISPATCHED) && this.archiveResource(it)
                                    },
                                    icon: ArchiveBoxIcon,
                                    visible: (it) => checkPerm(Resources.Load, DELETE_PERM) && !it.ArchivedDate,
                                    label: false,
                                    order: 30,
                                    disabled: false,
                                    title: (it) => {
                                        return (it.LoadStatusID < LOAD_STATUS_DISPATCHED) ? translate("btn.archive") : translate("Active asset can't be archived")
                                    },
                                    class: false,
                                    iconClass: (it) => {
                                        return (it.LoadStatusID < LOAD_STATUS_DISPATCHED) ? "w-5 h-5" : "w-5 h-5 opacity-30"
                                    }
                                }
                            ]}

                            onSelectRow={checkPerm(Resources.LoadInfo, UPDATE_PERM) && this.handleSelectRowClick}
                            selectedRows={checkPerm(Resources.LoadInfo, UPDATE_PERM) && this.state.selectedRows}
                            onSelectAllClick={checkPerm(Resources.LoadInfo, UPDATE_PERM) && this.handleSelectAllNewClick}

                            saveTableOptions={this.setOptions}

                            options={this.state.tableOptions}

                            verticalTableIsVisible={this.state.breakpoint.index <= 1}
                            translate={translate}
                        />


                        <TableBulkActions
                            selectedRows={this.state.selectedRows}
                            tableKey="LoadID"
                            fields={this.getFields()}
                            translate={translate}
                            options={this.state.tableOptions}
                            setSelectedRows={this.setSelectedRows}
                            onSelectAllClick={this.handleSelectAllNewClick}
                            filterSelects={this.querySelects}
                        >
                            <div className='flex items-center h-7'>
                                {/*<div className='flex gap-1 pr-2'>*/}
                                {/*    <Tippy content={translate('btn.set_as_favorite')}>*/}
                                {/*        <button*/}
                                {/*            onClick={() => this.handleSetFavouriteClick(true)}*/}
                                {/*            disabled={!areSomeItemsSelected}*/}
                                {/*            className='flex p-1 rounded-btn hover:bg-primary-shade'*/}
                                {/*        >*/}
                                {/*            <StarSolidIcon*/}
                                {/*                className="w-5 h-5 text-primary-contrast"/>*/}
                                {/*        </button>*/}
                                {/*    </Tippy>*/}

                                {/*    <Tippy content={translate('btn.remove_from_favorite')}>*/}
                                {/*        <button*/}
                                {/*            onClick={() => this.handleSetFavouriteClick(false)}*/}
                                {/*            disabled={!areSomeItemsSelected}*/}
                                {/*            className='flex p-1 rounded-btn hover:bg-primary-shade'*/}
                                {/*        >*/}
                                {/*            <StarIcon*/}
                                {/*                className='w-5 h-5 text-primary-contrast'/>*/}
                                {/*        </button>*/}
                                {/*    </Tippy>*/}
                                {/*</div>*/}

                                {/*<div className='w-0.5 h-5 bg-primary-shade'/>*/}

                                <div className='flex gap-1 pr-4'>
                                    <Tippy content={translate('btn.set_as_marked')}>
                                        <button
                                            onClick={() => this.handleSetMarkedClick(true)}
                                            disabled={!areSomeItemsSelected}
                                            className='flex p-1 rounded-btn hover:bg-primary-shade'
                                        >
                                            <FireSolidIcon
                                                className='w-5 h-5 text-primary-contrast'/>
                                        </button>
                                    </Tippy>

                                    <Tippy content={translate('btn.remove_from_marked')}>
                                        <button
                                            onClick={() => this.handleSetMarkedClick(false)}
                                            disabled={!areSomeItemsSelected}
                                            className='flex p-1 rounded-btn hover:bg-primary-shade'
                                        >
                                            <FireIcon
                                                className='w-5 h-5 text-primary-contrast'/>
                                        </button>
                                    </Tippy>
                                </div>

                                <div className='w-0.5 h-5 bg-primary-shade'/>

                                <div className="text-tm-gray-700 flex gap-1 pl-4">
                                    <TableTagManager
                                        isDiffResource={true}
                                        rowKey={"LoadID"}
                                        selectedRows={this.state.selectedRows}
                                        btnClass='icon-btn p-1 flex items-center text-primary-contrast hover:text-primary-contrast hover:bg-primary-shade'
                                        btnIconClass='w-5 h-5'
                                        customIcon={<ChevronUpSolidIcon className="w-5 h-5"/>}
                                        resourceName={Resources.LoadColor}
                                        piggyResourceName={Resources.Loads}
                                        query={this.getQuery()}
                                        onAfterTagRows={() => {
                                            this.setState({selectedRows: {}});
                                        }}
                                        translate={translate}
                                    />
                                </div>
                            </div>
                        </TableBulkActions>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(Resources.Loads, CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new load'}
                            btnLabel="Create load"
                            onBtnClick={() => {
                                this.handleLinkClick(`/loads/create?q=${this.state.queryFilterFields.query.value}`)
                            }}
                            excludeFields={['DateType', 'query', 'limit']}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={true}
                        >

                            <Pagination
                                hidePagination={!data.length && !isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                count={count}
                                isLoading={resource.isLoading}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                                htmlAfterRowsPerPage={
                                    <div className="ml-8">
                                        <TableFilterProfiles
                                            defaultStoragePath={this.props.location.pathname.substring(1)}
                                            isLoading={isLoading}
                                            onStoragePathChange={this.handleStoragePathChange}
                                            translate={translate}
                                        />
                                    </div>
                                }
                            />

                        </TableCardFooter>
                    </TableCard>

                </Page>

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmModalOpen}
                    text={this.state.confirmText}
                    onClose={() => this.setState({confirmModalOpen: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <TableOptionsDialog
                    enableMultiColumnsStack={true}
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    excludeFields={[
                        "IsTrackingEnabled",
                        "IsCustomerSyncEnabled",
                        "IsAutomaticCustomerShareEnabled",
                        "TrailerWasChanged",
                        "AssetWasChanged",
                        "AssetWillChange",
                        "TrailerWillChange"
                    ]}
                    setTableColumnOptions={this.setTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.toggleColumnSettingsDialog}
                    translate={translate}
                />

                <SlideOver
                    title={translate("dialog_heading.table_column_filters")}
                    open={this.state.isFilterSlideOverOpen}
                    setOpen={this.toggleColumnFilterSlideOver}
                    left={true}
                    widthClass="max-w-[15rem]"
                >
                    <TableColumnFilters
                        columnOptions={this.state.tableOptions.columns}
                        fields={excludeFields(this.state.fields, ['LoadID', 'CreateUpdateDate', 'PickupDate', 'DestinationDate', 'PickupEndDate', 'DestinationEndDate'])}
                        onFilterChange={this.handleFilterChange}
                        tableColumnFields={this.state.tableColumnFields}
                        onClearFiltersClick={() => this.handleFilterInputChange("ColumnFilters")}
                        selects={this.querySelects}
                        translate={translate}
                    />
                </SlideOver>

                <SlideOver
                    title={translate("dialog_heading.table_profiles")}
                    open={this.state.isTableProfilesSideoverOpen}
                    setOpen={this.toggleTableProfiles}
                    left={true}
                    widthClass="max-w-[14rem]"
                >
                    <TableProfilesDialog
                        isOpen={this.state.isTableProfilesSideoverOpen}
                        setOpen={this.toggleTableProfiles}
                        translate={translate}
                        storeKey={this.pagePath}
                        currentOptions={this.state.tableOptions}
                        setOptions={this.setOptions}
                    />
                </SlideOver>

                <Modal
                    show={this.state.isLoadPreviewDialogOpen}
                    widthClass={"max-w-7xl"}
                    onClose={this.toggleLoadPreviewDialog}
                >
                    <LoadPlaningModalBody
                        title={translate("dialog_heading.load_planing")}
                        dispatch={this.props.dispatch}
                        history={this.props.history}
                        previewData={this.state.loadPreviewData}
                        dialogResource={this.props.dialogResource}
                        isLoading={this.props.dialogResource.isLoading}
                        onSubmit={this.handlePreviewLoadClick}
                        onResetLoadReviewState={() => this.setState({loadPreviewData: {}})}


                        onClose={this.toggleLoadPreviewDialog}
                        translate={translate}
                    />
                </Modal>

                <PostLocationUpdate
                    show={this.state.isUpdateStopModalOpen}
                    onClose={this.toggleStopUpdateDialog}
                    onSubmit={(params) => {
                        this.props.dispatch(createResourceDiff({
                            user: LocalStorage.get('user'),
                            params: params,
                            resource: Resources.LoadStopUpdate,
                            piggyResource: Resources.Loads,
                            query: this.getQuery(),
                            successMessage: translate("text.loads_stop_updated", [this.state.selectedItem.LoadID]),
                            errorMessage: true
                        }));

                        this.toggleStopUpdateDialog();
                    }}
                    onPostponeUpdateSubmit={(snoozeFields) => {
                        this.props.dispatch(createResourceDiff({
                            user: LocalStorage.get('user'),
                            params: {
                                Snooze: snoozeFields.Snooze.value,
                                LoadID: this.state.selectedItem.LoadID
                            },
                            resource: Resources.LoadStopUpdate,
                            query: this.getQuery(),
                            piggyResource: Resources.Loads,
                            successMessage: this.props.translate("text.loads_stop_snooze_updated", [this.state.selectedItem.LoadID, snoozeFields.Snooze.value]),
                            errorMessage: true
                        }))

                        this.toggleStopUpdateDialog(this.state.selectedItem);
                    }}
                    selectedItem={this.state.selectedItem}
                    translate={translate}
                />

                <ModalDefault
                    show={this.state.uploadFilesModalOpen}
                    widthClass={'max-w-5xl'}
                    title={translate("modal_heading.upload_confirm")}
                    limitHeight={true}

                    close={this.handleCancelUpload}
                    closeButtonLabel={translate('btn.cancel')}
                    onClose={this.handleCancelUpload}

                    buttonDisabled={!this.state.canSubmit}
                    buttonLabel={translate('Upload')}
                    onButtonClick={this.handleUploadDocument}
                >
                    <div className="px-5 pt-5 pb-40">
                        {this.state.files.map((it, i) => {
                            const itemToHtml = fieldsToHtml(Object.values(Object.assign({}, it.fields)), translate, (name, value) => this.handleUploadInputChange(name, value, i), {
                                DocumentTypeID: getDocumentTypesFor("IsDispatchDocument")
                            })

                            return (
                                <div key={i}>
                                    {!!i && (
                                        <div className={'border-b border-tm-gray-300 mb-4 p-4 -ml-4 -mr-4'}/>
                                    )}

                                    <div className="flex items-center mb-4 justify-between">
                                        <div
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 break-all"
                                        >
                                            {this.state.files.length > 1 && (
                                                <div
                                                    className='w-10 h-10 mr-2 rounded-full flex items-center justify-center bg-tm-gray-50'>
                                                    <CardSubTitle
                                                        text={i + 1}
                                                    />
                                                </div>
                                            )}

                                            <label>{translate("text.desc_for_file")}: {this.state.files[i].name}</label>
                                        </div>
                                        <div className="text-right">
                                            <button
                                                className="btn btn-icon"
                                                title={translate('text.preview')}
                                                onClick={() => this.showPreviewModal(it)}
                                            >
                                                <EyeIcon className="h-4 w-4"/>
                                            </button>

                                            <button
                                                className="btn btn-icon"
                                                title={translate('text.delete')}
                                                onClick={() => this.handleRemoveDocumentFromUpload(i)}
                                            >
                                                <TrashIcon className="h-4 w-4"/>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="grid gap-4">
                                        {this.state.files.length > 1 && !i && (
                                            <label
                                                className="h-9 flex items-center justify-start px-2 -ml-2 w-full cursor-pointer hover:bg-tm-gray-50"
                                            >
                                                <FieldCheckbox
                                                    className={
                                                        classNames(
                                                            "checkbox",
                                                            this.state.willCopyValues
                                                                ? "border-primary"
                                                                : "border-tm-gray-400"
                                                        )
                                                    }
                                                    onChange={() => this.setState(
                                                        {willCopyValues: !this.state.willCopyValues}
                                                    )}
                                                    value={this.state.willCopyValues}
                                                />

                                                <div
                                                    className="ml-2 flex flex-col text-sm font-semibold select-none text-tm-gray-900"
                                                >
                                                    {translate("text.copy_to_other_fields")}
                                                </div>
                                            </label>
                                        )}

                                        {itemToHtml}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </ModalDefault>

                <ModalDefault
                    show={this.state.showPreviewModal}
                    widthClass={'max-w-7xl'}
                    title={translate("modal_heading.Preview")}
                    limitHeight={true}

                    close={() => this.setState({showPreviewModal: false, previewFile: null})}
                    closeButtonLabel={translate('btn.close')}
                    onClose={() => this.setState({showPreviewModal: false, previewFile: null})}
                    buttonLabel={translate('Download')}
                    onButtonClick={() => {
                        this.downloadPreview(URL.createObjectURL(this.state.previewFile), this.state.previewFile.name)
                    }}
                >
                    {!!this.state.previewFile && (
                        <FileViewer
                            filePath={URL.createObjectURL(this.state.previewFile)}
                            fileType={this.state.previewFile.name.split('.').pop()}
                            onError={(e) => {
                                console.log(e)
                            }}
                        />
                    )}
                </ModalDefault>

                <input type='file' id='file' multiple accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                       ref={this.inputFile} style={{display: 'none'}} onChange={this.onChangeFile}
                       onClick={event => event.target.value = null}/>
            </Layout>
        )
    }
}

export default connect(state => state)(Load)
