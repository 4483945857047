import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getResource} from '../../data/actions/resource'
import LocalStorage from '../../util/localStorage'
import Resources from '../../data/services/resources'
import ReactDOMServer from 'react-dom/server'
import L from 'leaflet'
import {LayersControl, Map, Marker, Popup, TileLayer} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster/src/react-leaflet-markercluster'
import Tippy from '@tippyjs/react'
import {getProp} from '../../common/util/util-helpers'
import {layers} from '../../util/util-constants'
import {ArrowTopRightOnSquareIcon, MapIcon} from "@heroicons/react/24/outline";
import ModalConfirm from "../../common/components/modal/modal-confirm";
import Card from "../../common/components/card";
import {Loader} from "../../common/components/loader";
import TableCard from "../../common/components/resource-table/table-components/table-card";
import FieldText from "../../common/components/fields/field-text";

const tileLayers = [
    {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors'
    },
    {
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    }
]

class MapView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            longitude: 0,
            latitude: 0,
            viewport: {
                center: [36, -96],
                zoom: 4,
                worldCopyJump: false
            },
            tileLayers: 0,
            pageHeading: LocalStorage.has('tempitem') ? 'truck' : 'vehicles',
            selectedItem: null,
            query: '',
            noLocationDialogOpen: false

        }
        this.mapRef = React.createRef()
        this.groupRef = React.createRef()

    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.Vehicles,
        }))
    }

    handleFitBounds = () => {
        let vehicles = getProp(this.props.resource, 'data.dashboard/vehicles', [])
        if (vehicles.length > 0) {
            const map = this.mapRef.current.leafletElement
            const group = this.groupRef.current.leafletElement
            map.fitBounds(group.getBounds())
        }
    }

    onViewportChanged = (viewport) => {
        if (viewport !== this.state.viewport) {
            this.setState({viewport})
        }
    }

    handleSearchChange = (name, value) => {
        this.setState({
            query: value
        })
    }

    showTruckInfo = (item, heading) => {
        this.setState({pageHeading: heading, selectedItem: item})
    }

    handleLinkClick = (e, newTab = false) => {
        if (newTab) {
            window.open(e, '_blank');
        } else {
            this.props.history.push(e)
        }
    }

    handleToggleNoLocationDialog = () => {
        this.setState({noLocationDialogOpen: !this.state.noLocationDialogOpen})
    }

    renderSelectedItem = (item = {}) => {
        return (
            <div>
                <span
                    className="text-tm-gray-500 text-small mt-3 p-0">{this.props.translate('text.TruckNumber').toUpperCase()}</span>
                <div className="mt-2 nav-tabs pb-2">
                    <div
                        onClick={() => item.TruckID && this.handleLinkClick(`/trucks/info/${item.TruckID}`, true)}
                        className="font-weight-bolder text-primary cursor-pointer font-semibold flex justify-between">
                        {item.Truck}
                        {item.TruckID && <ArrowTopRightOnSquareIcon className={'w-5 h-5'}/>}
                    </div>
                </div>

                <span
                    className="text-tm-gray-500 text-small mt-3 p-0">{this.props.translate('text.TrailerNumber').toUpperCase()}</span>
                <div className="mt-2 nav-tabs pb-2">
                    <div
                        onClick={() => item.TrailerID && this.handleLinkClick(`/trailers/info/${item.TrailerID}`, true)}
                        className="font-weight-bolder text-primary cursor-pointer font-semibold flex justify-between">
                        {item.TrailerNumber}
                        {item.TrailerID && <ArrowTopRightOnSquareIcon className={'w-5 h-5'}/>}
                    </div>
                </div>

                <span
                    className="text-tm-gray-500 text-small mt-3 p-0">{this.props.translate('text.DriverName').toUpperCase()}</span>
                <div className="mt-2 nav-tabs pb-2">
                    <div
                        onClick={() => item.DriverID && this.handleLinkClick(`/drivers/info/${item.DriverID}`, true)}
                        className="font-weight-bolder text-primary cursor-pointer font-semibold flex justify-between">
                        <span>{item.Driver ?? '/'}</span>
                        {item.DriverID && <ArrowTopRightOnSquareIcon className={'w-5 h-5'}/>}
                    </div>
                </div>

                <span
                    className="text-tm-gray-500 text-small mt-3 p-0">{this.props.translate('field.LoadID').toUpperCase()}</span>
                <div className="mt-2 nav-tabs pb-2">
                    <div
                        onClick={() => item.LoadNumber && this.handleLinkClick(`/loads/info/${item.LoadNumber}`, true)}
                        className="font-weight-bolder text-primary cursor-pointer font-semibold flex justify-between">
                        {item.LoadNumber ?? 'Not on Load'}
                        {item.LoadNumber && <ArrowTopRightOnSquareIcon className={'w-5 h-5'}/>}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const theme = localStorage.getItem('TYPE_OF_THEME')
        const {translate, resource} = this.props
        const informations = getProp(resource.data, 'dashboard/vehicles', [])

        const info = getProp(resource.data, 'dashboard/vehicles', [])
            .filter((item) => {
                const query = this.state.query.toLowerCase()
                if (!item?.Truck?.toLowerCase().includes(query) && !item?.Trailer?.toLowerCase().includes(query) && !item?.Driver?.toLowerCase().includes(query)) {
                    return false
                }
                if (isNaN(+item.Latitude) || isNaN(+item.Longitude)) {
                    return false
                }
                return true
            })
            .map((item, i) => {
                return (
                    <div key={i}
                         className="flex items-center justify-between hover:text-primary cursor-pointer"
                         onClick={() => {
                             if (item.Latitude && item.Longitude) {
                                 this.setState({
                                     viewport: {
                                         center: [item.Latitude, item.Longitude],
                                         zoom: 13,
                                         worldCopyJump: false
                                     }
                                 })
                                 this.showTruckInfo(item, 'truck')
                             } else {
                                 this.handleToggleNoLocationDialog()
                             }
                         }}>
                        <div>
                            <div className="">
                                <span className="font-bold">{item.Truck}</span>
                                {item.Driver && (
                                    <span
                                        className=""> - ({item.Driver})</span>
                                )}
                            </div>
                        </div>
                        <div className="h-14 flex items-center">
                            <Tippy content={<span>{translate('text.more_info')}</span>}>
                                <MapIcon className="w-5 h-5"/>
                            </Tippy>
                        </div>
                    </div>
                )
            })
        return (
            <React.Fragment>

                {this.state.pageHeading === 'truck' &&
                    <div className="mb-4">
                        <div className="flex flex-start">
                            <Tippy content={<span>{translate('text.GoBackToVehicles')}</span>}>
                                <div className="goBack " onClick={() => {
                                    this.setState({
                                        pageHeading: 'vehicles',
                                        items: null,
                                        viewport: {
                                            center: [0, 0],
                                            zoom: 4
                                        }

                                    })
                                    LocalStorage.remove('tempitem')
                                }
                                }>
                                    <i className="simple-icon-arrow-left"/>
                                </div>
                            </Tippy>
                            <span className="ml-3 fontsize-1-6rem">{translate('text.truck')} - <span
                                className="text-tm-gray-500 ">{this.state.selectedItem?.Truck ?? ''}</span></span>
                        </div>
                    </div>
                }
                {this.state.pageHeading === 'vehicles' &&
                    <div className="flex justify-between items-center mb-4">
                        <div className="flex space-x-4 items-center">
                            <h1 className="text-2xl">{translate(`text.${this.state.pageHeading}`)}</h1>
                            <div className="col-md-12 mt-3 mt-sm-0 mb-2 mb-sm-0">
                                <FieldText
                                    onChange={this.handleSearchChange}
                                    placeholder={translate('text.search')}
                                    addClass="form-control"
                                    name={'searchQuery'}/>
                            </div>
                        </div>
                        <div className="">
                            <button
                                onClick={this.handleFitBounds}
                                className="btn btn-primary">Fit bounds
                            </button>
                        </div>
                    </div>
                }

                <div className="grid grid-cols-12 gap-4">
                    {this.state.pageHeading === 'vehicles' ? (
                            <div className="col-span-full md:col-span-4">
                                <TableCard addClass="h-full max-h-[calc(100vh-18rem)] overflow-auto">
                                    <div className="divide-y divide-tm-gray-200 p-5">
                                        {resource.isLoading ?
                                            <Loader/>
                                            :
                                            info
                                        }
                                    </div>
                                </TableCard>
                            </div>
                        ) :
                        <div className="col-span-full md:col-span-4">
                            <Card>
                                {this.state.selectedItem && this.renderSelectedItem(this.state.selectedItem)}
                            </Card>
                        </div>
                    }

                    <div className="col-span-full md:col-span-8 z-10 h-full max-h-[calc(100vh-18rem)]">
                        <div
                            className="h-[calc(100vh-18rem)] w-full map-leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom">
                            <Map
                                onViewportChanged={this.onViewportChanged}
                                ref={this.mapRef}
                                viewport={this.state.viewport}>
                                <TileLayer
                                    url={tileLayers[this.state.tileLayers].url}
                                    attribution={tileLayers[this.state.tileLayers].attribution}
                                />
                                <LayersControl position="topright">
                                    {layers.map((layer, index) =>
                                        <LayersControl.BaseLayer
                                            key={index}
                                            checked={theme === 'dark' ? index === 1 : index === 0}
                                            name={layer.name}>
                                            <TileLayer
                                                url={layer.url}
                                                attribution={layer.attribution}
                                            />
                                        </LayersControl.BaseLayer>
                                    )}
                                    <MarkerClusterGroup ref={this.groupRef}>
                                        {informations.map((item, i) => {
                                            let meIcon = L.divIcon({
                                                className: 'custom icon',
                                                html: ReactDOMServer.renderToString(
                                                    <div
                                                        className={!item.LastLocation ? 'custom-markers-red' : 'custom-markers-green'}>
                                                        {!item.LastLocation ?
                                                            <div className="stop-icon"></div>
                                                            :
                                                            <i className="simple-icon-cursor font-weight-bolder"/>
                                                        }
                                                    </div>
                                                )
                                            })

                                            if (
                                                !isNaN(+item.Latitude)
                                                && !isNaN(+item.Longitude)
                                                && (!!item.Latitude)
                                                && (!!item.Longitude)
                                            ) {
                                                return (
                                                    <Marker
                                                        zoomAnimation={true}
                                                        markerZoomAnimation={true}
                                                        onClick={(e) => {
                                                            L.popup()
                                                                .setLatLng(e.latlng)
                                                        }}
                                                        key={i}
                                                        position={[+item.Latitude, +item.Longitude]}
                                                        icon={meIcon}>
                                                        <Popup
                                                            autoPan={false}>
                                                            <div className={'w-64 m-auto'}>
                                                                {this.renderSelectedItem(item)}
                                                            </div>
                                                        </Popup>
                                                    </Marker>
                                                )
                                            }
                                        })}

                                    </MarkerClusterGroup>
                                </LayersControl>
                            </Map>

                        </div>
                        <ModalConfirm
                            type={"info"}
                            show={this.state.noLocationDialogOpen}
                            widthClass="max-w-lg"
                            limitHeight={true}
                            onClose={() => this.handleToggleNoLocationDialog()}
                            closeButtonLabel={translate('btn.ok')}
                        >
                            <div className="grid justify-center text-md">
                                There are no locations in the system for the selected truck.
                            </div>
                        </ModalConfirm>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(state => state)(MapView)
