import React from "react";
import InfoBar from "../../../../../common/components/info-paragraph/info-bar";
import {STOP_TYPE_DELIVERY, STOP_TYPE_PICKUP, STOP_TYPE_PICKUP_AND_DELIVERY} from "../../../../../util/util-constants";

export default function StopPalletWarning({stops, commodityData, translate}) {
    return stops.map((stop, index) => {
        if (stop.IsStopCompleted.value === 1 && stop?.StopType?.value === STOP_TYPE_PICKUP) {

            const commodities = commodityData.filter(it => it?.LoadCommodityPickupID === stop?.LoadStopID?.value);

            const commodity = commodities.length === 1 ? commodities[0] : undefined;
            const CountPallets = Number(commodities.reduce((memo, it) => {
                memo += it.CountPallets ?? 0;
                return memo;
            }, 0));
            const PalletsLoaded = Number(stop.PalletsLoaded?.value ?? 0);
            const UnitType = commodity?.UnitType ? commodity.UnitType : translate("text.unit_s");

            return (!!CountPallets && !!PalletsLoaded && CountPallets !== PalletsLoaded)
                ? <InfoBar key={stop.LoadStopID} type="warning"
                           message={`The loaded ${UnitType} at stop #${index + 1}. do not match the planned quota.`}/>
                : undefined
        }

        if (stop.IsStopCompleted.value === 1 && stop?.StopType?.value === STOP_TYPE_DELIVERY) {
            const commodities = commodityData.filter(it => it?.LoadCommodityDestinationID === stop?.LoadStopID?.value);
            const commodity = commodities.length === 1 ? commodities[0] : undefined;
            const CountPallets = Number(commodities.reduce((memo, it) => {
                memo += it.CountPallets ?? 0;
                return memo;
            }, 0));
            const PalletsUnLoaded = Number(stop.PalletsUnLoaded?.value ?? 0);
            const UnitType = commodity?.UnitType ? commodity.UnitType : translate("text.unit_s");

            return (!!CountPallets && !!PalletsUnLoaded && CountPallets !== PalletsUnLoaded)
                ? <InfoBar key={stop.LoadStopID} type="warning"
                           message={`The unloaded ${UnitType} at stop #${index + 1}. do not match the planned quota.`}/>
                : undefined
        }

        if (stop.IsStopCompleted.value === 1 && stop?.StopType?.value === STOP_TYPE_PICKUP_AND_DELIVERY) {
            const commoditiesPickup = commodityData.filter(it => it?.LoadCommodityPickupID === stop?.LoadStopID?.value);
            const CountPalletsPickup = Number(commoditiesPickup.reduce((memo, it) => {
                memo += it.CountPallets ?? 0;
                return memo;
            }, 0));
            const PalletsLoaded = Number(stop.PalletsLoaded?.value ?? 0);

            const commoditiesDelivery = commodityData.filter(it => it?.LoadCommodityDestinationID === stop?.LoadStopID?.value);
            const CountPalletsDelivery = Number(commoditiesDelivery.reduce((memo, it) => {
                memo += it.CountPallets ?? 0;
                return memo;
            }, 0));
            const PalletsUnLoaded = Number(stop.PalletsUnLoaded?.value ?? 0);

            const isPickupNotMatched = !!CountPalletsPickup && !!PalletsLoaded && CountPalletsPickup !== PalletsLoaded;
            const isDeliveryNotMatched = !!CountPalletsDelivery && !!PalletsUnLoaded && CountPalletsDelivery !== PalletsUnLoaded;

            return (isPickupNotMatched || isDeliveryNotMatched)
                ? <InfoBar key={"pickupDelivery" + stop.LoadStopID} type="warning"
                           message={`The ${[isPickupNotMatched ? "loaded" : undefined, isDeliveryNotMatched ? "unloaded" : undefined].filter(it => !!it).join(" and ")} unit(s) at stop #${index + 1}. do not match the planned quota.`}/>
                : undefined
        }

        return null;
    })
}